import clsx from 'clsx';
import { isArray } from 'lodash';
import { nanoid } from 'nanoid';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FileUploadIcon from '../../../assets/icons/FileUploadIcon';
import FormEntryIcon from '../../../assets/icons/FormEntryIcon';
import PhotoUploadIcon from '../../../assets/icons/PhotoUploadIcon';
import {
  InsuranceCropOptions,
  InsuranceIrrigationPracticeOptions,
  InsuranceOptions,
  InsuranceOrganicPracticeOptions,
  InsurancePlanTypeOptions,
  TextractType,
} from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import { useUploadFile } from '../../../hooks/file/use-file-upload';
import { useCreateInsurance } from '../../../hooks/insurance/use-insurance';
import { useTextract } from '../../../hooks/textract/use-textract';
import Button from '../../commons/Button';
import CardHeader from '../../commons/CardHeader';
import ComboSelectInput from '../../commons/inputs/ComboSelectInput';
import RadioInput from '../../commons/inputs/RadioInput';
import Select from '../../commons/Select';
import { transformTextractInsuranceToOptions } from './helpers';

const SubmitOptions = [
  {
    value: 'formEntry',
    label: 'Form Entry',
    icon: FormEntryIcon,
  },
  {
    value: 'uploadFile',
    label: 'Upload File',
    icon: FileUploadIcon,
  },
  {
    value: 'uploadPhoto',
    label: 'Upload Photo',
    icon: PhotoUploadIcon,
  },
];

export default function AddCropInsuranceForm() {
  const user = useUser();
  const year = useYearFilter();
  const form = useForm({
    reValidateMode: 'onChange',
  });
  const [step, setStep] = useState('first');
  const [textTractOptions, setTextTractOptions] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const submitOption = form.watch('submitOption');

  const { uploadFile, isPending: isUploading } = useUploadFile();

  const totalPage = useMemo(() => {
    return textTractOptions?.field_legal?.[0]?.length // when has field_legal
      ? textTractOptions?.field_legal?.length // get the length of array of field_legal array
      : 1; // when no field_legal
  }, [textTractOptions]);

  const [remainPages, setRemainPages] = useState<number[]>(
    Array.from({ length: totalPage }, (_, i) => i + 1)
  );

  // Field options
  const { data: allFields, isFetching: isFetchingField } =
    useFieldPolygonsByYear(user?.id ?? '', year ?? '');
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: field.field,
    }));
  }, [allFields]);

  // Upload file
  const [fileUpload, setFileUpload] = useState<{
    fileName: string;
    fileType?: string;
  }>({
    fileName: '',
  });

  // Textract
  const { getTextract, data, isLoading: isLoadingTextract } = useTextract();
  const { data: textTractData, status: textractStatus } = data ?? {};
  useEffect(() => {
    // get splitted pdf file names
    if (fileUpload?.fileName) {
      (async () => {
        try {
          if (fileUpload.fileName && !isUploading) {
            getTextract({
              documentName: fileUpload.fileName,
              type: TextractType.INSURANCE,
              userId: user?.id ?? '',
            });
          }
        } catch (error) {
        } finally {
          setFileUpload({ fileName: '' });
        }
      })();
    }
  }, [fileUpload.fileName, isUploading, user?.id]);

  useEffect(() => {
    if (textTractData && textractStatus === 'completed') {
      const parsedTextractData = JSON.parse(textTractData);
      if (isArray(parsedTextractData)) {
        const options = transformTextractInsuranceToOptions(
          parsedTextractData.map((item) => {
            const parsedData = Object.keys(item).reduce(
              (result: Record<string, any>, key) => {
                result[key] = (item as Record<string, any>)[key]
                  ? JSON.parse((item as Record<string, any>)[key])
                  : (item as Record<string, any>)[key];
                return result;
              },
              {}
            );
            return parsedData;
          })
        );
        setTextTractOptions(options);
      } else {
        const parsedData = Object.keys(parsedTextractData).reduce(
          (result: Record<string, any>, key) => {
            result[key] = (parsedTextractData as Record<string, any>)[key]
              ? JSON.parse((parsedTextractData as Record<string, any>)[key])
              : (parsedTextractData as Record<string, any>)[key];
            return result;
          },
          {}
        );
        const options = transformTextractInsuranceToOptions([parsedData]);
        setTextTractOptions(options);
      }
      setStep('details');
    }
  }, [textTractData, textractStatus]);

  // Reference to the file input element
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const getResponse = (fileName: any) => {
      const fileType = e.target.files?.[0].type;
      if (fileName) {
        setFileUpload({ fileName });
      } else {
        setFileUpload({ fileName: '', fileType });
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };

    const file = e.target.files?.[0];
    if (file) {
      const type = file.type.split('/')[1];
      const timeStamp = Date.now();
      const defaultFileName = `${user?.id}-${nanoid()}-${timeStamp}.${type}`;
      uploadFile(file, defaultFileName, getResponse);
    }
  };

  // Handle form submission
  const { mutateAsync: createInsurance, isPending } = useCreateInsurance();

  const handleSubmitOne = async () => {
    if (!user || !year) return;

    const errors = form.formState.errors;
    if (errors[`page${currentPage}`]) {
      enqueueSnackbar(
        'Please complete required informations for this insurance',
        {
          variant: 'warning',
        }
      );
      return;
    }

    const currentPageValues = form.getValues()[`page${currentPage}`];
    try {
      await createInsurance({
        userId: user.id,
        year,
        data: [
          {
            ...currentPageValues,
            legalsJson: currentPageValues.legalsJson ?? '',
            insuranceOptions: currentPageValues.insuranceOptions.split(','),
          },
        ],
      });
      enqueueSnackbar('Insurance submitted successfully', {
        variant: 'success',
      });
      setRemainPages(remainPages.filter((page) => page !== currentPage));

      // Move to next page
      if (currentPage < totalPage) {
        setCurrentPage(currentPage + 1);
      } else if (remainPages.length === 1) {
        setCurrentPage(remainPages[0]);
      }
    } catch (error) {
      enqueueSnackbar('Failed to submit insurance', { variant: 'error' });
    }
  };

  // Submit all insurances and single insurance (if only one page)
  // Using the handleSubmit of react-hook-form
  // This only called when all pages are filled/form is valid
  const handleSubmit = async (values: any) => {
    if (!user || !year) return;

    const payload = remainPages.map((page) => {
      const pageValues = values[`page${page}`];
      return {
        ...pageValues,
        legalsJson: pageValues.legalsJson ?? '',
        insuranceOptions: pageValues.insuranceOptions.split(','),
      };
    });

    try {
      await createInsurance({
        userId: user.id,
        year,
        data: payload,
      });
      enqueueSnackbar('All insurances submitted successfully', {
        variant: 'success',
      });
      setFormKey(nanoid());
      form.reset()
      setRemainPages([]);
    } catch (error) {
      enqueueSnackbar('Failed to submit insurance', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (remainPages.length === 0) {
      setFormKey(nanoid());
      form.reset()
      setStep('first');
      setCurrentPage(1);
      setRemainPages(Array.from({ length: totalPage }, (_, i) => i + 1));
      setFileUpload({ fileName: '' });
    }
  }, [remainPages.length]);

  useEffect(() => {
    setRemainPages(Array.from({ length: totalPage }, (_, i) => i + 1));
  }, [totalPage]);

  // Manual set field legal
  const field = form.watch(`page${currentPage}.fieldName`);
  useEffect(() => {
    if (!field) {
      form.setValue(`page${currentPage}.fieldName`, fieldOptions?.[0]?.value);
    }
  }, [field]);

  useEffect(() => {
    if (field) {
      const fieldData = allFields?.find((f) => f.field === field);
      if (fieldData) {
        form.setValue(
          `page${currentPage}.fieldLegal`,
          fieldData.legal_description
        );
        form.setValue(
          `page${currentPage}.legalsJson`,
          fieldData?.legals_json ?? ''
        );
        form.setValue(`page${currentPage}.acres`, fieldData.area);
      }
    }
  }, [allFields, currentPage, field]);

  const isSubmitted = !remainPages.includes(currentPage);
  const uploading = isUploading || isLoadingTextract;
  const [formKey, setFormKey] = useState(nanoid());

  return (
    <div className="space-y-6">
      <CardHeader
        title="Add New Crop Insurance"
        subtitle={
          step === 'details' && totalPage > 1
            ? `Page ${currentPage} / ${totalPage}`
            : ''
        }
      />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} key={formKey}>
          {/* First Step */}
          <div className="space-y-5" hidden={step !== 'first'}>
            <p>How would you like to submit the insurance</p>
            {SubmitOptions.map(({ value, label, icon: Icon }) => (
              <RadioInput
                key={value}
                name="submitOption"
                value={value}
                label={label}
                icon={Icon}
                labelClassName="text-base-800"
              />
            ))}
            {submitOption === 'formEntry' && (
              <Button color="primary" onClick={() => setStep('details')}>
                Next
              </Button>
            )}
            {submitOption === 'uploadFile' && (
              <Button color="primary" loading={uploading} className="relative">
                <input
                  ref={fileInputRef}
                  type="file"
                  name="file"
                  className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer"
                  onChange={handleUploadFile}
                  disabled={uploading}
                />
                Upload
              </Button>
            )}
            {submitOption === 'uploadPhoto' && (
              <Button color="primary" loading={uploading} className="relative">
                <input
                  ref={fileInputRef}
                  type="file"
                  name="file"
                  accept=".png, .jpeg, .jpg, .tiff"
                  className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer"
                  onChange={handleUploadFile}
                  disabled={uploading}
                />
                Upload
              </Button>
            )}
          </div>

          {/* Details step */}
          <div
            className={clsx({
              hidden: step !== 'details',
            })}
          >
            {Array.from({ length: totalPage }).map((_, index) => (
              <fieldset
                key={`page${index + 1}`}
                className={clsx('space-y-5', {
                  hidden: currentPage !== index + 1,
                })}
              >
                <p className="text-secondary">* Indicates Required Field</p>
                <ComboSelectInput
                  name={`page${index + 1}.fieldName`}
                  label="Field Name"
                  required
                  options={fieldOptions || []}
                  disabled={isSubmitted}
                />
                <ComboSelectInput
                  name={`page${index + 1}.fieldLegal`}
                  label="Field Legal"
                  options={textTractOptions?.field_legal?.[index] || []}
                  disabled={isSubmitted}
                />
                <Select
                  name={`page${index + 1}.crop`}
                  label="Commodity"
                  options={InsuranceCropOptions}
                  required
                  disabled={isSubmitted}
                />
                <ComboSelectInput
                  name={`page${index + 1}.cropClass`}
                  label="Intended Use"
                  disabled={isSubmitted}
                  options={textTractOptions?.crop_classes?.[index] || []}
                  required
                />
                <Select
                  name={`page${index + 1}.irrigationPractice`}
                  label="Irrigation Practice"
                  disabled={isSubmitted}
                  options={InsuranceIrrigationPracticeOptions}
                  required
                />
                <Select
                  name={`page${index + 1}.organicPractice`}
                  label="Organic Practice"
                  disabled={isSubmitted}
                  options={InsuranceOrganicPracticeOptions}
                  required
                />
                <Select
                  name={`page${index + 1}.insurancePlanType`}
                  label="Insurance Plan Type"
                  disabled={isSubmitted}
                  options={InsurancePlanTypeOptions}
                  required
                />
                <Select
                  name={`page${index + 1}.insuranceOptions`}
                  label="Insurance Options"
                  disabled={isSubmitted}
                  options={InsuranceOptions}
                  multiple
                />
                <ComboSelectInput
                  name={`page${index + 1}.acres`}
                  label="Acres"
                  options={textTractOptions?.acres?.[index] || []}
                  required
                />
                <ComboSelectInput
                  name={`page${index + 1}.approvedYield`}
                  label="APH"
                  options={textTractOptions?.approved_yield?.[index] || []}
                  required
                  disabled={isSubmitted}
                  endAdornment={'.Bu'}
                />
                <ComboSelectInput
                  name={`page${index + 1}.basePrice`}
                  label="Base Price"
                  options={textTractOptions?.base_price?.[index] || []}
                  required
                  disabled={isSubmitted}
                />
                <ComboSelectInput
                  name={`page${index + 1}.percentCoverage`}
                  label="Coverage Percent"
                  options={textTractOptions?.percent_coverage?.[index] || []}
                  required
                  disabled={isSubmitted}
                  endAdornment={'%'}
                />
                <ComboSelectInput
                  name={`page${index + 1}.premium`}
                  label="Premium per Acre"
                  options={textTractOptions?.premium?.[index] || []}
                  required
                  disabled={isSubmitted}
                />
                <ComboSelectInput
                  name={`page${index + 1}.sharePercent`}
                  label="Share Percent"
                  options={textTractOptions?.share_percent?.[index] || []}
                  required
                  disabled={isSubmitted}
                  endAdornment={'%'}
                />
              </fieldset>
            ))}

            <div className="space-y-5 mt-5">
              {totalPage > 1 && (
                <div className="flex space-x-4">
                  <Button
                    type="button"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    Previous
                  </Button>
                  <div className="whitespace-nowrap rounded-lg bg-base-1000 flex justify-center items-center px-4 space-x-1">
                    <p>{currentPage} /</p>
                    <p className="text-secondary">{totalPage}</p>
                  </div>
                  <Button
                    type="button"
                    disabled={currentPage >= totalPage}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    Next
                  </Button>
                </div>
              )}

              <div className="flex space-x-4">
                {totalPage <= 1 && (
                  <Button
                    onClick={() => {
                      setFormKey(nanoid());
                      form.reset()
                      setStep('first');
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {totalPage <= 1 && (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      isSubmitted || isPending || !form.formState.isValid
                    }
                    loading={isPending}
                  >
                    Submit Insurance
                  </Button>
                )}
                {totalPage > 1 && (
                  <Button
                    color="primary"
                    disabled={isSubmitted || isPending}
                    loading={isPending}
                    onClick={handleSubmitOne}
                    type="button"
                  >
                    {remainPages.includes(currentPage)
                      ? 'Submit This'
                      : 'Submitted'}
                  </Button>
                )}
                {totalPage > 1 && (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      !remainPages.length ||
                      isPending ||
                      !form.formState.isValid
                    }
                    loading={isPending}
                  >
                    Submit All
                  </Button>
                )}
              </div>

              {/* {totalPage > 1 && (
                <Button onClick={() => setStep('details')}>
                  Save Insurance
                </Button>
              )}
              <p>
                You may save the crop insurance if you would prefer to enter
                them another time.
              </p> */}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
