import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { getTextractApi, getTextractResultApi } from '../../api/textract-api';
import { AWS_FILES_BUCKET } from '../../constants';

export const useTextractId = () => {
  const res = useMutation({
    mutationKey: ['textract'],
    mutationFn: ({
      documentName,
      type,
      userId,
    }: {
      documentName: string;
      type: string;
      userId?: string;
    }) =>
      getTextractApi({
        s3BucketName: AWS_FILES_BUCKET,
        documentName,
        type,
        userId,
      }),
    onSuccess: () => {
      enqueueSnackbar('Textract in progress', { variant: 'info' });
    },
    onError: () => {
      enqueueSnackbar('Failed to get textract', { variant: 'error' });
    },
  });

  return { uniqueid: res.data?.data?.id, ...res };
};

export const useTextractResult = (uniqueid: string) => {
  const [refetch, setRefetch] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const { data } = useQuery({
    queryKey: ['textract', uniqueid],
    queryFn: () => getTextractResultApi(uniqueid),
    enabled: !!uniqueid,
    refetchInterval: refetch ? 5000 : false,
  });

  useEffect(() => {
    if (data?.data?.status === 'started' || data?.data?.status === 'in progress') {
      setLoading(true);
    }
    if (data?.data?.status === 'completed') {
      enqueueSnackbar('Textract completed', { variant: 'success' });
      setRefetch(false);
      setLoading(false);
    } else if (data?.data?.status === 'failed') {
      enqueueSnackbar('Textract failed', { variant: 'error' });
      setRefetch(false);
      setLoading(false);
    }

    return () => {
      setLoading(false);
      setRefetch(true);
    };
  }, [data?.data.status]);

  return { data: data?.data, isLoading };
};

export const useTextract = () => {
  const { mutate: getTextractId, uniqueid } = useTextractId();
  const getTextract = ({
    userId,
    documentName,
    type,
  }: {
    userId: string;
    documentName: string;
    type: string;
  }) => {
    getTextractId({ documentName, type, userId });
  };
  const { data, isLoading } = useTextractResult(uniqueid);

  return { getTextract, data, isLoading };
};
