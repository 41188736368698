import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import {
  ChevronDownIcon,
  InboxIcon,
  InformationCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { get } from 'lodash';
import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { theme } from 'twin.macro';
import DocumentIcon from '../../../assets/icons/DocumentIcon';
import SpinningIcon from '../../../assets/icons/SpinningIcon';
import { convertToFloat, parseMoney } from '../../../utils';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Currency from '../Currency';
import Fluctuation from '../Fluctuation';
import IconButton from '../inputs/IconButton';
import TextInput from '../inputs/TextInput';
import NumberDisplay from '../NumberDisplay';
import Pagination, { usePagination } from '../Pagination';
import TableDownloadButton from './TableDownloadButton';
import TableFilter from './TableFilter';
import { useForm } from 'react-hook-form';
import { useMutationState } from '@tanstack/react-query';

export interface Highlight {
  rowKey: string;
  columnKey: string;
}

export interface TableConfigs {
  cols: {
    key: string;
    name?: string | ReactNode;
    type?: 'currency' | 'fluctuation' | 'number' | 'boolean' | 'element';
    unit?: string;
    numberStyle?: 'unit' | 'currency' | 'percent' | 'decimal';
    currency?: string;
    total?: boolean;
    element?: (item: any) => JSX.Element;
    merged?: boolean;
    className?: string;
    emptyValue?: string | number;
  }[];
}

export interface RowActions {
  edit?: {
    onSelect?: (item: any) => void;
    form?: (params: { item: any; onClose: () => void }) => JSX.Element;
  };
  delete?: (item: any) => void;
  note?: {
    title: string;
    onSelect?: (item: any) => void;
    onSave: (item: any, value: any, onClose: () => void) => void;
    onSaveAll?: (item: any, value: any, onClose: () => void) => void;
  };
}

export interface TableProps {
  configs: TableConfigs;
  data: Record<string, any>[];
  loading?: boolean;
  title?: {
    name: string;
    tooltip?: string;
  };
  noDataTitle?: string;
  hasTotalRow?: boolean;
  tableActions?: ReactNode;
  totalRowVariant?: 'primary' | 'secondary';
  extraActions?: (item: any) => JSX.Element;
  rowActions?: RowActions;
  pagination?: {
    size: number;
  };
  filter?: {
    handler: (data: Record<string, any>[]) => Record<string, any>[];
    element: JSX.Element;
    form: ReturnType<typeof useForm>;
  };
  highlights?: string[];
  highlightCells?: Highlight[];
  indexKey?: string;
  checkedRows?: string[];
  onRowCheck?: (item: any, checked: boolean) => void;
  onRowClick?: (item: any) => void;
  tableFixed?: boolean;
  header?: boolean;
  subRow?: (item: any) => JSX.Element | null;
  initNote?: string;
  noteTrigger?: number;
  download?: boolean;
}

const getTotal = (data: Record<string, any>[], key: string) => {
  return data?.reduce((acc, curr) => acc + Number(curr[key] ?? 0), 0);
};

function DeprecatedTable({
  configs,
  data = [],
  hasTotalRow,
  title,
  noDataTitle = 'No data',
  tableActions,
  totalRowVariant = 'primary',
  rowActions,
  extraActions,
  pagination,
  filter,
  loading,
  highlights,
  highlightCells,
  indexKey = 'id',
  checkedRows = [],
  onRowCheck,
  onRowClick,
  tableFixed = false,
  header = true,
  subRow,
  initNote,
  noteTrigger,
  download = false,
}: TableProps) {
  // Pagination
  const filteredData = filter ? filter.handler(data) : data;
  const { page, pageSize, skip, endIndex, setPage, onNextPage, onPrevPage } =
    usePagination({
      size: pagination?.size || 10,
      total: filteredData.length,
    });
  const totalPage = Math.ceil(filteredData?.length / pageSize);
  const tableData = pagination
    ? filteredData?.slice(skip, endIndex)
    : filteredData;

  const [editIndex, setEditIndex] = useState<number | undefined>();
  const [noteIndex, setNoteIndex] = useState<number | undefined>();
  const [note, setNote] = useState<string>();

  useEffect(() => {
    setNote(initNote);
  }, [initNote, noteTrigger]);

  // Find pages of the highlights
  const highlightPages = useMemo(() => {
    const highlightIndices = highlights?.map((highlight) =>
      filteredData.findIndex((item) => item[indexKey] === highlight)
    );
    return highlightIndices?.map((index) => Math.floor(index / pageSize) + 1);
  }, [filteredData, highlights, indexKey, pageSize]);

  useEffect(() => {
    if (highlightPages?.length) {
      const highlightPage = highlightPages[0];
      if (highlightPage) {
        setPage(highlightPage);
      }
    }
  }, [highlightPages]);

  const tdContent = (item: any, key: any, config: (typeof configs.cols)[0]) => {
    // Return custom element if type is element
    if (config.type === 'element') return config.element?.(item);

    const emptyValue = config.emptyValue ?? '-';
    let value = get(item, key);
    value =
      value === 'nan' || value === undefined ? emptyValue : get(item, key);

    if (value === emptyValue) return value;

    switch (config.type) {
      case 'currency':
        return (
          <Currency
            value={
              convertToFloat(parseMoney(value.toString())?.toString() ?? '0') ??
              0
            }
          />
        );
      case 'fluctuation':
        return (
          <Fluctuation
            value={convertToFloat(value) ?? 0}
            unit={config.unit}
            currency={config.currency}
          />
        );
      case 'number':
        const displayValue =
          config.numberStyle === 'percent' ? value / 100 : value;
        return (
          <NumberDisplay
            value={convertToFloat(displayValue) ?? 0}
            numberStyle={config.numberStyle}
            unit={config.unit}
          />
        );
      case 'boolean':
        return value ? 'Yes' : 'No';
      default:
        return `${value} ${config.unit ?? ''}`;
    }
  };

  const shouldHighlightCell = (rowKey: string, columnKey: string): boolean => {
    if (highlightCells) {
      return highlightCells.some(
        (highlight) =>
          highlight.rowKey === rowKey && highlight.columnKey === columnKey
      );
    } else if (highlights) {
      return highlights.includes(rowKey);
    }
    return false;
  };

  // Note
  const handleClickNote = (item: any, index: number) => {
    rowActions?.note?.onSelect?.(item);
    if (noteIndex === index) {
      setNoteIndex(undefined);
    } else {
      setNoteIndex(index);
    }
    setNote('');
  };

  const createNoteStatus = useMutationState({
    filters: {
      mutationKey: ['CREATE', 'note'],
    },
    select: ({ state }) => state.status,
  });
  const creatingNote = createNoteStatus.includes('pending');

  const handleSaveNote = (item: any) => {
    rowActions?.note?.onSave(item, note, () => {
      setNote('');
      setNoteIndex(undefined);
    });
  };

  const handleSaveNoteAll = (item: any) => {
    rowActions?.note?.onSaveAll?.(item, note, () => {
      setNote('');
      setNoteIndex(undefined);
    });
  };

  const handleClickEdit = (item: any, index: number) => {
    if (rowActions?.edit?.form) {
      if (editIndex === index) {
        setEditIndex(undefined);
      } else {
        setEditIndex(index);
      }
    }
    rowActions?.edit?.onSelect?.(item);
  };

  useEffect(() => {
    setEditIndex(undefined);
    setNoteIndex(undefined);
  }, [page]);

  return (
    <div>
      <div className="overflow-x-auto overflow-y-hidden relative">
        <table
          className={clsx('w-full text-center', {
            'table-fixed': tableFixed,
          })}
        >
          <thead>
            {filter && (
              <tr>
                <th
                  colSpan={
                    configs.cols.length +
                    (!!onRowCheck ? 1 : 0) +
                    (!!rowActions ? 1 : 0) +
                    (!!rowActions?.note ? 1 : 0)
                  }
                  className="p-4 text-left"
                >
                  <TableFilter form={filter.form}>{filter.element}</TableFilter>
                </th>
              </tr>
            )}
            <tr>
              <th
                colSpan={
                  configs.cols.length +
                  (!!onRowCheck ? 1 : 0) +
                  (!!rowActions ? 1 : 0) +
                  (!!rowActions?.note ? 1 : 0)
                }
                className="p-0"
              >
                {(title || tableActions || download) && (
                  <div className="text-xl-bold flex justify-between px-4 py-2 bg-base-800 tableTitle">
                    {title ? (
                      <span className="flex items-center space-x-2">
                        <h4>{title.name}</h4>
                        {title.tooltip && (
                          <InformationCircleIcon className="size-6 fill-yellow" />
                        )}
                      </span>
                    ) : (
                      <span></span>
                    )}
                    <span className="flex gap-2">
                      {download && <TableDownloadButton data={tableData} />}
                      {tableActions}
                    </span>
                  </div>
                )}
              </th>
            </tr>
            {header && (
              <tr className="bg-base-900 tableHead">
                {configs.cols.map((col) => (
                  <th
                    className={clsx(
                      'border-base-1000 border-b-base-900 p-2',
                      !col.merged && 'border-l',
                      'last:border-r'
                    )}
                    key={col.key}
                  >
                    {col.name}
                  </th>
                ))}
                {rowActions?.note && (
                  <th
                    className={clsx(
                      'border-base-1000 border-b-base-900 p-2',
                      'border-l',
                      'last:border-r'
                    )}
                  >
                    Note
                  </th>
                )}
                {(rowActions || extraActions) && (
                  <th
                    className={clsx(
                      'border-base-1000 p-2',
                      'border-l',
                      'last:border-r'
                    )}
                  >
                    Actions
                  </th>
                )}
                {onRowCheck && (
                  <th
                    className={clsx(
                      'border-base-1000 border-b-base-900 p-2',
                      'border-l',
                      'last:border-r'
                    )}
                  >
                    Select
                  </th>
                )}
              </tr>
            )}
          </thead>

          <tbody>
            {tableData?.map((item, index) => (
              <Fragment key={index}>
                <tr
                  key={index}
                  className={clsx(
                    'tableRow',
                    'odd:bg-base-1100',
                    'even:bg-base-1000'
                  )}
                  onClick={() => onRowClick?.(item)}
                >
                  {configs.cols.map((col) => (
                    <td
                      key={col.key}
                      className={clsx(
                        'border-b border-base-900 p-2',
                        'last:border-r',
                        !col.merged && 'border-l',
                        col.className
                      )}
                      style={{
                        ...(shouldHighlightCell(item[indexKey], col.key)
                          ? {
                              boxShadow:
                                '3px 3px 6px 0px #80BC0080 inset,-3px -3px 7px 0px #80BC0080 inset',
                            }
                          : {}),
                      }}
                    >
                      {tdContent(item, col.key, col)}
                    </td>
                  ))}

                  {rowActions?.note && (
                    <td className="border border-base-900 p-2 w-0 last:border-r">
                      <span className="flex items-center justify-center">
                        <button type='button' onClick={() => handleClickNote(item, index)}>
                          <DocumentIcon
                            {...(noteIndex === index
                              ? { fill: theme`colors.yellow` }
                              : { fill: theme`colors.primary` })}
                          />
                        </button>
                      </span>
                    </td>
                  )}
                  {(rowActions || extraActions) && (
                    <td className="border border-base-900 p-2 w-0 last:border-r">
                      <span className="flex space-x-1">
                        {extraActions?.(item)}
                        {rowActions?.edit && (
                          <IconButton
                            color="primary"
                            onClick={() => handleClickEdit(item, index)}
                          >
                            <PencilSquareIcon className="size-4" />
                          </IconButton>
                        )}

                        {rowActions?.delete && (
                          <IconButton
                            color="primary"
                            onClick={() => {
                              if (
                                confirm(
                                  'Are you sure you want to delete this item?'
                                )
                              ) {
                                rowActions.delete?.(item);
                                if (index === noteIndex) {
                                  setNoteIndex(undefined);
                                }
                                if (index === editIndex) {
                                  setEditIndex(undefined);
                                }
                              }
                            }}
                          >
                            <TrashIcon className="size-4" />
                          </IconButton>
                        )}
                      </span>
                    </td>
                  )}

                  {/* Select */}
                  {onRowCheck && (
                    <td className="border border-base-900 p-2 w-0 last:border-r">
                      <span className="flex justify-center">
                        <Checkbox
                          name="select"
                          onChange={(checked) => onRowCheck?.(item, checked)}
                          {...(checkedRows?.includes(item?.[indexKey])
                            ? { checked: true }
                            : {})}
                        />
                      </span>
                    </td>
                  )}
                </tr>
                {noteIndex === index && (
                  <tr key={`note-${index}`}>
                    <td
                      colSpan={configs.cols.length + 2}
                      className="border border-base-900 last:border-r"
                    >
                      <div className="p-4 pt-2 space-y-2 text-left">
                        <p className="text-sm-bold">
                          {rowActions?.note?.title}
                        </p>
                        <div className="bg-base-400 p-2 rounded-lg space-y-4">
                          <TextInput
                            name="note"
                            value={note}
                            className="!bg-base-000 !border-0 text-base-1100"
                            onChange={(e) => setNote(e.target.value)}
                          />
                          <div className="flex space-x-4 justify-end">
                            <Button
                              size="sm"
                              color="primary"
                              className="!w-fit"
                              onClick={() => handleSaveNote(item)}
                              loading={creatingNote}
                              disabled={creatingNote}
                            >
                              Save
                            </Button>
                            {rowActions?.note?.onSaveAll && (
                              <Button
                                size="sm"
                                color="primary"
                                className="!w-fit"
                                onClick={() => handleSaveNoteAll(item)}
                                loading={creatingNote}
                                disabled={!note || creatingNote}
                              >
                                Save and Apply to all zone
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {editIndex === index && (
                  <tr key={`edit-${index}`}>
                    <td
                      colSpan={configs.cols.length + 2}
                      className="border border-base-900 last:border-r"
                    >
                      <div className="p-4 pt-2 text-left">
                        {rowActions?.edit?.form?.({
                          item,
                          onClose: () => setEditIndex(undefined),
                        })}
                      </div>
                    </td>
                  </tr>
                )}
                {subRow?.(item) && (
                  <Disclosure as={'tr'} key={`sub-${index}`}>
                    <td
                      colSpan={configs.cols.length + 2}
                      className="border border-base-900 last:border-r"
                    >
                      <DisclosureButton className="w-full flex justify-center items-center p-1 text-sm">
                        <ChevronDownIcon className="size-4" />
                      </DisclosureButton>
                      <DisclosurePanel>
                        <div className={clsx('p-4 pt-2')}>{subRow(item)}</div>
                      </DisclosurePanel>
                    </td>
                  </Disclosure>
                )}
              </Fragment>
            ))}

            {/* Total row */}
            {tableData?.length > 0 &&
              hasTotalRow &&
              totalRowVariant === 'primary' && (
                <tr className="totalRow">
                  <td className="px-4 py-2 text-md-bold !rounded-lg">
                    <p>Totals</p>
                  </td>
                  {configs.cols.map(
                    (col, index) =>
                      // reduce a col give place for the total
                      index !== 0 && (
                        <td
                          key={col.key}
                          className={clsx('p-2', col.className)}
                        >
                          {col.total && (
                            <>
                              {col.type === 'currency' && (
                                <Currency
                                  className={clsx(
                                    getTotal(tableData, col.key) < 0
                                      ? 'text-red'
                                      : 'text-primary'
                                  )}
                                  value={getTotal(tableData, col.key) ?? 0}
                                  currency={col.currency}
                                />
                              )}
                              {col.type === 'fluctuation' && (
                                <Fluctuation
                                  value={getTotal(tableData, col.key) ?? 0}
                                  unit={col.unit}
                                  currency={col.currency}
                                />
                              )}
                              {col.type === 'number' && (
                                <NumberDisplay
                                  className={clsx(
                                    getTotal(tableData, col.key) < 0
                                      ? 'text-red'
                                      : 'text-primary',
                                    col.className
                                  )}
                                  value={getTotal(tableData, col.key) ?? 0}
                                  unit={col.unit}
                                  numberStyle={col.numberStyle}
                                />
                              )}
                            </>
                          )}
                        </td>
                      )
                  )}
                </tr>
              )}

            {tableData?.length > 0 &&
              hasTotalRow &&
              totalRowVariant === 'secondary' && (
                <tr className="bg-base-700 text-base-1100 text-md-bold">
                  <td className="px-4 py-2 text-md-bold">
                    <p>TOTALS</p>
                  </td>
                  {configs.cols.map(
                    (col, index) =>
                      // reduce a col give place for the total
                      index !== 0 && (
                        <td
                          key={col.key}
                          className="p-2 border border-base-900"
                        >
                          {col.total && (
                            <>
                              {col.type === 'currency' && (
                                <Currency
                                  value={getTotal(tableData, col.key) ?? 0}
                                  currency={col.currency}
                                />
                              )}
                              {col.type === 'fluctuation' && (
                                <Fluctuation
                                  value={getTotal(tableData, col.key) ?? 0}
                                  unit={col.unit}
                                  currency={col.currency}
                                />
                              )}
                              {col.type === 'number' && (
                                <NumberDisplay
                                  value={getTotal(tableData, col.key) ?? 0}
                                  unit={col.unit}
                                  numberStyle={col.numberStyle}
                                />
                              )}
                            </>
                          )}
                        </td>
                      )
                  )}
                </tr>
              )}
          </tbody>
        </table>
        {loading && (
          <div className="absolute w-full h-full top-0 right-0 bg-base-1000 opacity-50 flex items-center justify-center">
            <SpinningIcon />
          </div>
        )}
        {tableData?.length === 0 && (
          <div className="w-full flex flex-col items-center justify-center h-[300px] opacity-20 space-y-2">
            {!loading && <p className="display-xs-regular">{noDataTitle}</p>}
            <InboxIcon className="size-12" />
          </div>
        )}
      </div>
      {/* Footer */}
      <div
        className={clsx('relative tableFooter', onRowCheck && 'min-h-[40px]')}
      >
        <div className="px-3 absolute bottom-2">
          {checkedRows?.length > 0 && (
            <p className="whitespace-nowrap">{checkedRows.length} selected</p>
          )}
        </div>
        {pagination && totalPage > 0 && (
          <Pagination
            page={page}
            totalPage={totalPage}
            setPage={setPage}
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
          />
        )}
      </div>
    </div>
  );
}

export default DeprecatedTable;
