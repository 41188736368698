import { InformationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { getBasisOptions, useBasis } from '../../../hooks/basis/use-basis';
import { useUpdateUserRealTimeSettings } from '../../../hooks/user/use-user-real-time-settings';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import { convertToFloat } from '../../../utils';
import Currency from '../../commons/Currency';
import Fluctuation from '../../commons/Fluctuation';
import { AwaitSelect } from '../../commons/Select';
import Skeleton from '../../commons/Skeleton';

interface BasisItemProps {
  basisId: string;
  onChange: (value: string) => void;
  symbol: string;
  defaultBasisId?: string;
  cropName: string;
  size?: 'small' | 'large';
}

function BasisItem({
  basisId,
  onChange,
  symbol,
  defaultBasisId,
  cropName,
  size = 'small',
}: BasisItemProps) {
  const user = useUser();
  const year = useYearFilter();
  const { data: basisDataHook, isFetching } = useBasis({
    userId: user?.id || '',
    year: year || '',
    symbols: [symbol],
  });

  const msgBasis: any = useWebSocketDataV2('Basis2');

  const basisData: any = useMemo(() => {
    // if the updates basis data is not the selected item, pass
    if (msgBasis && msgBasis.data && msgBasis.data.basis_id !== basisId) {
      return basisDataHook;
    }
    // otherwise update hook
    const updatedBasisData = basisDataHook?.map((basis) => {
      if (
        msgBasis &&
        msgBasis.data &&
        msgBasis.data.basis_id === basis.basis_id
      ) {
        return msgBasis.data;
      }
      return basis;
    });
    return updatedBasisData;
  }, [msgBasis.data, basisDataHook]);

  const { mutate: updateSettings } = useUpdateUserRealTimeSettings();

  const data = useMemo(
    () => basisData?.find((item: any) => item.basis_id === basisId),
    [basisData, basisId]
  );
  const basisOptions = useMemo(() => getBasisOptions(basisData), [basisData]);

  useEffect(() => {
    if (defaultBasisId) {
      onChange(defaultBasisId);
    } else if (basisOptions.length > 0) {
      onChange(basisOptions[0]?.value);
    }
  }, [basisOptions, onChange, defaultBasisId]);

  const [selected, setSelected] = useState(defaultBasisId);
  const handleOnChange = (value: string) => {
    onChange(value);
    setSelected(value);
  };

  console.log({ selected, defaultBasisId, equal: user?.id && year && selected && selected !== defaultBasisId });

  useEffect(() => {
    if (user?.id && year && selected && selected !== defaultBasisId) {
      console.log('here')
      updateSettings({
        userId: user?.id,
        year: parseInt(year),
        crop: cropName,
        newSetting: {
          settingKey: 'basis',
          settingValue: selected,
        },
      });
    }
  }, [user?.id, year, selected, defaultBasisId, cropName]);

  return (
    <div className="space-y-2">
      <AwaitSelect
        size="sm"
        name="basis"
        className={clsx(size === 'large' && 'display-xs-regular px-6 py-3')}
        defaultValue={defaultBasisId}
        options={basisOptions}
        onChange={(e) => handleOnChange(e.target.value)}
        isLoading={isFetching}
      />
      {isFetching ? (
        <Skeleton />
      ) : (
        <span
          className={clsx(
            'flex items-center space-x-0.5',
            size === 'large' && 'justify-between'
          )}
        >
          <span className="flex space-x-2">
            <h4
              className={clsx(
                'display-md-bold mr-4',
                size !== 'large' && 'hidden'
              )}
            >
              Basis:
            </h4>
            <Currency
              value={convertToFloat(data?.basis)}
              className={clsx(
                size === 'large' && 'display-md-bold',
                'text-md-bold'
              )}
            />
            <InformationCircleIcon className="fill-yellow size-5" />
          </span>
          <Fluctuation
            value={convertToFloat(data?.basis_chg)}
            currency="USD"
            context="Prev. Settle"
            contextClassname={clsx(size === 'large' && 'text-xl-regular')}
          />
        </span>
      )}
    </div>
  );
}
export default BasisItem;
