import { groupBy } from 'lodash';
import { Link } from 'react-router-dom';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useControlledUser, useUser } from '../../../../contexts/auth-context';
import { useInsurances } from '../../../../hooks/insurance/use-insurance';
import Button from '../../../commons/Button';
import Table, { TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';

interface InsuranceListProps {}

const tableConfigs: TableConfigs = {
  cols: [
    {
      key: 'insurancePolicy',
      name: 'Insurance Policy',
    },
    {
      key: 'probalibility',
      name: 'Probability of Indemnity Payment',
      type: 'number',
      unit: '%',
      emptyValue: '',
    },
    {
      key: 'expectedIndemnity',
      name: 'Expected Indemnity',
      type: 'number',
      emptyValue: '',
    },
    {
      key: 'riskReduction',
      name: 'Risk Reduction',
      type: 'number',
      unit: '%',
      emptyValue: '',
    },
  ],
};

const Crops = {
  Corn: {
    name: 'Corn Insurance',
    tooltip: 'demo',
  },
  Soybeans: {
    name: 'Soybeans Insurance',
    tooltip: 'demo',
  },
  Wheat: {
    name: 'Wheat Insurance',
    tooltip: 'demo',
  },
};

export default function InsuranceList(props: InsuranceListProps) {
  const user = useUser();
  const controlledUser = useControlledUser();
  const year = useYearFilter();
  const { data, isFetching } = useInsurances({
    userId: user?.id ?? '',
    year: year ?? '',
  });

  const dataWithInsurancePolicy = data?.map((item) => ({
    ...item,
    insurancePolicy: `${item.insurance_plan} (${item.percent_coverage}%)`,
  }));

  const sumDataWithInsurancePolicy = dataWithInsurancePolicy?.reduce(
    (acc, item) => {
      if (acc[item.insurancePolicy]) {
        acc[item.insurancePolicy].probalibility += 0;
        acc[item.insurancePolicy].expectedIndemnity += 0;
        acc[item.insurancePolicy].riskReduction += 0;
      } else {
        acc[item.insurancePolicy] = {
          crop: item.crop,
          probalibility: 0,
          expectedIndemnity: 0,
          riskReduction: 0,
          insurancePolicy: item.insurancePolicy,
        };
      }
      return acc;
    },
    {} as Record<string, any>
  );

  const dataGroupByCrop = groupBy(sumDataWithInsurancePolicy, 'crop');

  return (
    <TableLayout
      title="Current Insurance Coverage"
      extraActions={
        <>
          <Link
            to={
              controlledUser?.id
                ? `/user/${controlledUser.id}/app/data-inputs/crop-insurance`
                : '/app/data-inputs/crop-insurance'
            }
          >
            <Button>Go to Crop Insurance Page</Button>
          </Link>
        </>
      }
    >
      {Object.entries(Crops).map(([crop, { name, tooltip }]) => (
        <Table
          key={crop}
          configs={tableConfigs}
          data={dataGroupByCrop[crop]}
          title={{
            name: name,
            tooltip: tooltip,
          }}
          loading={isFetching}
        />
      ))}
    </TableLayout>
  );
}
