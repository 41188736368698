import AccessControl from '../../components/routes/AccessControl';
import Weather from '../../components/weather';
import { PERMISSIONS } from '../../constants';

const weatherRoutes = [
  {
    path: 'weather',
    element: (
      <AccessControl permissions={[PERMISSIONS.WEATHER_ACCESS]} />
    ),
    children: [
      {
        index: true,
        element: <Weather />,
      },
    ],
  },
];

export default weatherRoutes;
