import moment from 'moment';
import { useForm } from 'react-hook-form';
import { CropSelectOptions } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  useDeleteExpense,
  useExpenses,
} from '../../../hooks/expense/use-expense';
import { ExpenseItem } from '../../../types';
import DateRangePicker from '../../commons/inputs/DateRangePicker';
import Select from '../../commons/Select';
import Table, { TableConfigs } from '../../commons/table/Table';
import TableDownloadButton from '../../commons/table/TableDownloadButton';
import TableLayout from '../../dashboard/commons/TableLayout';
import EditFarmExpenseForm from './EditFarmExpenseForm';

const tableConfigs: TableConfigs = {
  cols: [
    {
      key: 'vendor',
      name: 'Vendor',
    },
    {
      key: 'vendor_address',
      name: 'Vendor Address',
    },
    {
      key: 'category',
      name: 'Category',
    },
    {
      key: 'description',
      name: 'Description',
    },
    {
      key: 'expense_type',
      name: 'Type',
    },
    {
      key: 'expense_applied_to',
      name: 'Applied To',
    },
    {
      key: 'purchase_date',
      name: 'Expense Date',
    },
    {
      key: 'entry_date',
      name: 'Entry Date',
    },
    {
      key: 'expense_amount',
      name: 'Amount',
      type: 'currency',
    },
  ],
};

export default function FarmExpenseList() {
  const user = useUser();
  const year = useYearFilter();

  const { data, isFetching } = useExpenses(user?.id ?? '', year ?? '');
  const { mutate: deleteExpense } = useDeleteExpense();

  const rowActions = {
    edit: {
      onSelect: (item: any) => {},
      form: ({ item, onClose }: { item: any; onClose: () => void }) => {
        return <EditFarmExpenseForm data={item} onClose={onClose} />;
      },
    },
    delete: (item: any) => {
      if (confirm('Are you sure you want to delete this item?')) {
        deleteExpense({
          id: item.id,
        });
      }
    },
    note: {
      onSave: (item: any, value: any) => {},
      title: 'Add Expense Note',
    },
  };

  const filterInputs = (
    <>
      <DateRangePicker className="" name={'invoicedate'} label="Invoice Date" />
      <Select
        defaultValue="all"
        name="crop"
        label="Crop"
        options={CropSelectOptions}
      />
    </>
  );

  const filterForm = useForm();
  const filterValues = filterForm.watch();
  const handleFilter = (data: ExpenseItem[]) => {
    return (
      data
        ?.filter((item) => {
          if (!filterValues?.invoicedate) return true;
          return (
            moment(item.purchase_date).isSameOrAfter(
              moment(filterValues?.invoicedate?.start?.toString())
            ) &&
            moment(item.purchase_date).isSameOrBefore(
              moment(filterValues?.invoicedate?.end?.toString())
            )
          );
        })
        .filter((item) => {
          if (filterValues?.crop === 'all') return true;
          if (filterValues?.crop === 'Other') {
            return (
              !item?.expense_applied_to ||
              !['corn', 'hrw wheat', 'swr wheat', 'soybean'].includes(
                item?.expense_applied_to.toLowerCase()
              )
            );
          }
          return (
            item?.expense_applied_to.toLowerCase() ===
            filterValues?.crop?.toLowerCase()
          );
        }) ?? []
    );
  };

  const extraActions = (item: any) => (
    <div className="flex space-x-1">
      <TableDownloadButton data={[item]} title='invoice-expenses' />
    </div>
  );

  return (
    <TableLayout title="Farm Expenses" tooltip="demo">
      <Table
        downloadTitle='invoice-expenses'
        configs={tableConfigs}
        data={data}
        pagination={{
          size: 10,
        }}
        filter={{
          element: filterInputs,
          handler: handleFilter,
          form: filterForm,
        }}
        download
        rowActions={rowActions}
        loading={isFetching}
        extraActions={extraActions}
      />
    </TableLayout>
  );
}
