import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createExpenseApi,
  createExpenseLogApi,
  deleteExpenseApi,
  getExpenseLogApi,
  getExpensesApi,
  updateExpenseApi,
} from '../../api/expense-api';
import {
  CreateExpensePayload,
  ExpenseItem,
  UpdateExpensePayload,
} from '../../types';
import { enqueueSnackbar } from 'notistack';

export const useExpenses = (userId: string, year: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['expense', userId, year],
    queryFn: () => getExpensesApi(userId, year),
    enabled: !!userId && !!year,
  });

  return {
    data: data?.data as ExpenseItem[],
    ...rest,
  };
};

export const useCreateExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CreateExpensePayload[]) => createExpenseApi(payload),
    mutationKey: ['CREATE', 'expense'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['expense'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to create expense', { variant: 'error' });
    },
  });
};

export const useUpdateExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UpdateExpensePayload) => updateExpenseApi(payload),
    onSuccess: () => {
      enqueueSnackbar('Expense updated successfully', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['expense'] });
    },
    onError: (e) => {
      console.log('error: ', e)
      enqueueSnackbar('Failed to update expense', { variant: 'error' });
    },
  });
};

export const useDeleteExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: string }) => deleteExpenseApi({ id }),
    onSuccess: () => {
      enqueueSnackbar('Expense deleted successfully', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['expense'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete expense', { variant: 'error' });
    },
  });
};

export const useExpenseLog = (userId: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['expense-log', userId],
    queryFn: () => getExpenseLogApi(userId),
    enabled: !!userId,
  });

  return {
    data: data?.data,
    ...rest,
  };
};

export const useCreateExpenseLog = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: any) => createExpenseLogApi(payload),
    mutationKey: ['CREATE', 'expense'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['expense-log'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to create expense', { variant: 'error' });
    },
  });
};