import AccessControl from '../../components/routes/AccessControl';
import { PERMISSIONS } from '../../constants';
import RiskManagement from '../../pages/dashboard/risk-management';

const riskManagementRoutes = [
  {
    path: 'risk-management',
    element: (
      <AccessControl
        permissions={[PERMISSIONS.RISK_MANAGEMENT_ACCESS]}
      />
    ),
    children: [
      {
        index: true,
        element: <RiskManagement />,
      },
    ],
  },
];

export default riskManagementRoutes;
