import { useEffect, useMemo, useState } from 'react';
import NumberDisplay from '../../commons/NumberDisplay';
import Table, { TableConfigs } from '../../commons/table/Table';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
} from '../../../types';
import { usePrescriptionProduct } from '../../../hooks/field/use-field-prescription';
import { getZonesWithProducts } from './helpers';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { convertToFloat } from '../../../utils';

const CoverageBar = ({
  value,
  percentCoverage,
}: {
  value: number;
  need: number;
  percentCoverage: number;
}) => {
  return (
    <div>
      <div className="flex items-center space-x-4 justify-center px-4 pt-5">
        <div className="relative h-6 bg-yellow rounded-lg w-full shadow-md">
          {value > 0 && (
            <>
              <span
                className="absolute left-0 -top-full flex justify-end"
                style={{
                  width: `${(percentCoverage > 1 ? 1 : percentCoverage) * 100}%`,
                }}
              >
                <NumberDisplay
                  value={value}
                  numberStyle="unit"
                  unit="pound"
                  className="translate-x-1/2 whitespace-nowrap"
                />
              </span>
              <span
                className="absolute left-0 -top-[3px] flex justify-end z-[1]"
                style={{
                  width: `${(percentCoverage > 1 ? 1 : percentCoverage) * 100}%`,
                }}
              >
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="translate-x-1/2"
                >
                  <path
                    d="M4.29378 5.70625C4.68441 6.09688 5.31878 6.09688 5.70941 5.70625L9.70941 1.70625C9.99691 1.41875 10.0813 0.990626 9.92503 0.615626C9.76878 0.240626 9.40628 -0.00312424 9.00003 -0.00312424L1.00003 9.53674e-07C0.596906 9.53674e-07 0.231281 0.243751 0.0750307 0.618751C-0.0812193 0.993751 0.00628069 1.42188 0.290656 1.70938L4.29066 5.70938L4.29378 5.70625Z"
                    fill="black"
                  />
                </svg>
              </span>
            </>
          )}

          <div
            className="absolute h-6 bg-gradient-to-r from-[#09370D] to-green rounded"
            style={{
              width: `${(percentCoverage > 1 ? 1 : percentCoverage) * 100}%`,
            }}
          ></div>
        </div>
        <span className="min-w-8">
          <NumberDisplay numberStyle="percent" value={percentCoverage} />
        </span>
      </div>
    </div>
  );
};

export default function NutrientNeed({
  fertilizer,
  inputType,
  crop,
  presctiptions,
}: {
  fertilizer: {
    id: string;
    name: string;
  };
  inputType: 'fertilizer' | 'micronutrients';
  crop: string;
  presctiptions: FieldPrescriptionRes[];
}) {
  const user = useUser();
  const year = useYearFilter();

  const { data: products, isFetching } = usePrescriptionProduct({
    type: inputType,
    year: year ?? '',
    crop,
    userId: user?.id || '',
  });
  const [list, setList] = useState<FieldPrescriptionWithProducts[]>([]);

  const fertilizerName = fertilizer.name.toLowerCase();

  useEffect(() => {
    setList(
      getZonesWithProducts(presctiptions, products, inputType, fertilizerName)
    );
  }, [presctiptions, products, inputType, fertilizerName]);

  const data = useMemo(() => {
    const zones = list.map((item) => {
      const productsCount = item.products.length;
      const value = item.products.reduce((acc, item) => {
        acc +=
          (convertToFloat(item?.[`${fertilizerName}`]) *
            convertToFloat(item?.[`percent_of_${fertilizerName}_need`])) /
          100;
        return acc;
      }, 0);
      const need =
        convertToFloat(item[`${fertilizer.id.toLowerCase()}_rate`]) *
        productsCount;

      const percentCoverage = !need ? 0 : value / need; // prevent divide by zero
      return {
        zone: `Zone ${item.agrivar_zone1}`,
        value,
        need,
        percentCoverage,
        area: convertToFloat(item.area),
      };
    });

    const allZone = zones.reduce(
      (acc, item) => {
        acc.value += item.value;
        acc.need += item.need;
        acc.area += item.area;
        return acc;
      },
      { value: 0, need: 0, area: 0 }
    );

    const allZonePercentCoverage =
      allZone.area > 0
        ? zones.reduce((acc, item) => {
            return acc + (item.percentCoverage * item.area) / allZone.area;
          }, 0)
        : 0;

    return [
      {
        zone: 'All Zones',
        ...allZone,
        percentCoverage: allZonePercentCoverage,
      },
      ...zones,
    ];
  }, [fertilizerName, list]);

  const tableConfigs: TableConfigs = {
    cols: [
      {
        name: '',
        key: 'zone',
        className: 'w-0 whitespace-nowrap',
      },
      {
        name: '',
        key: 'need',
        type: 'element',
        element: (item) => (
          <CoverageBar
            value={item.value}
            need={item.need}
            percentCoverage={item.percentCoverage}
          />
        ),
      },
    ],
  };
  return (
    <div className="card overflow-hidden !rounded-lg pb-4">
      <h4 className="text-center p-2 border-b border-base-1000">{`${fertilizer.name} Need Coverage`}</h4>
      <Table
        configs={tableConfigs}
        data={data}
        header={false}
        loading={isFetching}
      />
    </div>
  );
}
