import StyledCard from '../../../components/main/StyledCard';
import GoldPhoto from '../../../assets/images/photo-gold.png';
import PlatinumPhoto from '../../../assets/images/photo-platnim.png';
import Button from '../../../components/commons/Button';
import { UseFormReturn } from 'react-hook-form';
import { CompleteRegisterValueTypes } from '.';
import { SignUpSections } from '../../../constants';

export default function PlanSelection({
  onNext,
  form,
}: {
  onNext: (nextSection: string) => void;
  form: UseFormReturn<CompleteRegisterValueTypes, any, undefined>;
}) {
  return (
    <div className="max-w-screen-lg mx-auto">
      <StyledCard title="Select the Plan Option You Wish to Choose">
        <div className="grid grid-cols-2 gap-14">
          <div className="flex flex-col justify-between">
            <div className="bg-[#222C33] rounded-2xl p-4 flex-1">
              <div className="flex flex-col items-center gap-4">
                <svg
                  width="44"
                  height="42"
                  viewBox="0 0 44 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 42L3 32H17L20 42H0ZM24 42L27 32H41L44 42H24ZM10 28L13 18H27L30 28H10ZM44 10.1L36.28 12.28L34.1 20L31.92 12.28L24.2 10.1L31.92 7.92L34.1 0.200001L36.28 7.92L44 10.1Z"
                    fill="url(#paint0_linear_12_32)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_12_32"
                      x1="22"
                      y1="0.200001"
                      x2="22"
                      y2="42"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FAB401" />
                      <stop offset="1" stop-color="#D79B00" />
                    </linearGradient>
                  </defs>
                </svg>
                <h3 className="display-sm-bold">Gold Level</h3>

                <img src={GoldPhoto} alt="gold package" />

                <section className="space-y-4">
                  <article>
                    <h4 className="text-lg-bold">
                      Control Your Finances and Gain Real-Time Access to CME and
                      Local Cash Prices
                    </h4>

                    <ul className="list-disc pl-8">
                      <li>
                        Real-time estimates of net income, expected revenue,
                        yields and risk.
                      </li>
                      <li>Enable multi-year crop risk management.</li>
                      <li>
                        Gain insight into how your risk management strategies
                        affect your risk in real-time.
                      </li>
                    </ul>
                  </article>

                  <article>
                    <h4 className="text-lg-bold">
                      Make-Real-Time Application and Management Decisions with
                      Confidence
                    </h4>

                    <ul className="list-disc pl-8">
                      <li>In season yield and harvestable acre predictions.</li>
                      <li>
                        Visual intel down to every 10 sq meters of your fields.
                      </li>
                    </ul>
                  </article>

                  <article>
                    <h4 className="text-lg-bold">
                      Improve ROI for Future Management Decisions
                    </h4>

                    <ul className="list-disc pl-8">
                      <li>
                        With yield performance analytics, identify seed
                        varieties, seeding rates, and applications with the
                        highest ROI.
                      </li>
                      <li>
                        Instant custom studies to support informed decisions.{' '}
                      </li>
                    </ul>
                  </article>

                  <article>
                    <h4 className="text-lg-bold">
                      Receive Tailored Seeding and Fertilizer Prescriptions for
                      Maximized Profitability
                    </h4>

                    <ul className="list-disc pl-8">
                      <li>
                        AgriVaR's advanced AI analyzes a multitude of factors to
                        develop custom field zones that accurately reflect the
                        unique conditions of your farm.
                      </li>
                    </ul>
                  </article>
                </section>
              </div>
            </div>

            <Button
              className="!w-fit mx-auto mt-6 bg-gradient-to-b from-[#FAB401] to-[#C89208]"
              size="lg"
              onClick={() => {
                form.setValue('plan_option', '0');
                onNext(SignUpSections.DataSharingPermissions);
              }}
            >
              Sign Up for the Gold Level
            </Button>
          </div>
          {/* Platinum */}
          <div className="flex flex-col justify-between">
            <div className="bg-[#222C33] rounded-2xl p-4 flex-1">
              <div className="flex flex-col items-center gap-4">
                <svg
                  width="44"
                  height="42"
                  viewBox="0 0 44 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M44 13L31.06 11.92L26 0L20.94 11.92L8 13L17.8 21.54L14.88 34.2L26 27.48L37.12 34.2L34.18 21.54L44 13ZM30.28 22.46L31.08 25.88L28.06 24.06L26 22.82L23.94 24.06L20.92 25.88L21.7 22.46L22.26 20.1L20.44 18.52L17.76 16.2L21.28 15.9L23.68 15.7L24.62 13.48L26 10.24L27.38 13.48L28.32 15.7L30.72 15.9L34.22 16.2L31.56 18.52L29.72 20.1L30.28 22.46ZM0.320001 22C-0.277999 21 -0.0219986 19.78 0.900001 19.18L6.36 15.58L9.5 18.3L3.1 22.52C2.76 22.74 2.38 22.86 2 22.86C1.36 22.86 0.720001 22.54 0.320001 22ZM0.900001 38.32L12.62 30.62L12 33.52L11.32 36.26L3.1 41.68C2.76 41.9 2.38 42 2 42C1.36 42 0.720001 41.68 0.320001 41.1C-0.277999 40.18 -0.0219986 38.94 0.900001 38.32ZM12.64 21L14.48 22.62L13.94 25L3.1 32.1C2.76 32.32 2.38 32.42 2 32.42C1.36 32.42 0.720001 32.12 0.320001 31.52C-0.277999 30.6 -0.0219986 29.36 0.900001 28.76L12.64 21Z"
                    fill="url(#paint0_linear_12_50)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_12_50"
                      x1="21.9987"
                      y1="0"
                      x2="21.9987"
                      y2="42"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#E7E7E7" />
                      <stop offset="1" stop-color="#787878" />
                    </linearGradient>
                  </defs>
                </svg>
                <h3 className="display-sm-bold">Platinum Level</h3>

                <img src={PlatinumPhoto} alt="platinum package" />

                <section className="space-y-4">
                  <article>
                    <h4 className="text-lg-bold">
                      Everything included in the Gold option, as well as:
                    </h4>

                    <ul className="list-disc pl-8 space-y-4">
                      <li>
                        Unlimited custom alert notifications via SMS or e-mail.
                        Never miss out on an opportunity again! Example value
                        notifications include:
                      </li>
                      <li className="list-none">
                        <ul className="pl-4 list-disc space-y-4">
                          <li>
                            Get notified when a weather event passes over one of
                            your fields so you can identify potential crop
                            insurance payout conditions.
                          </li>
                          <li>
                            Be notified when a particular grain contract hits a
                            certain price level so you can place yourself on the
                            same level as professional grain traders and gain a
                            fairer advantage in the market.
                          </li>
                          <li>
                            Receive an alert when a particular basis location
                            hits a set basis level to help identify potential
                            sale opportunities in real-time.
                          </li>
                          <li>And much more!</li>
                        </ul>
                      </li>
                    </ul>
                  </article>
                </section>
              </div>
            </div>

            <Button
              className="!w-fit mx-auto mt-6 bg-gradient-to-b from-[#D9D9D9] to-[#A6A6A6] self-end"
              size="lg"
              onClick={() => {
                form.setValue('plan_option', '1');
                onNext(SignUpSections.DataSharingPermissions);
              }}
            >
              Sign Up for the Platinum Level
            </Button>
          </div>
        </div>
      </StyledCard>
    </div>
  );
}
