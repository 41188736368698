import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useState, useEffect } from 'react';

export const useShouldFetch = (deps: any[], enabled = true) => {
  const [prevDeps, setPrevDeps] = useState(deps);
  const [shouldFetch, setShouldFetch] = useState(enabled);

  useEffect(() => {
    const currentDeps = [...deps];
    if (!deps.length) {
      console.warn('Do you forget to add deps?');
    }
    if (!isEqual(currentDeps, prevDeps)) {
      setPrevDeps(currentDeps);
      setShouldFetch(true);
    } else {
      setShouldFetch(false);
    }
  }, [deps, prevDeps]);

  return shouldFetch;
};

export const useDepsQuery = ({
  deps,
  enabled,
  ...options
}: UndefinedInitialDataOptions<any> & {
  deps: any[];
  enabled?: boolean;
}) => {
  const shouldFetch = useShouldFetch(deps, enabled);
  return useQuery({
    ...options,
    enabled: shouldFetch,
  });
};
