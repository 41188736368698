import { getFieldsRevenueApi } from '../../api/field-api';
import { FieldRevenueRes } from '../../types';
import { useDepsQuery } from '../use-deps-query';

export const useFieldRevenue = (userId: string, year: string) => {
  const { data, ...rest } = useDepsQuery({
    queryKey: ['field/revenue/all', userId, year],
    queryFn: () => getFieldsRevenueApi(userId, year),
    refetchOnWindowFocus: false,
    enabled: !!userId && !!year,
    deps: [userId, year],
  });

  return {
    data: data?.data as FieldRevenueRes[] | [],
    ...rest,
  };
};
