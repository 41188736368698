import apiClient from "./api-client";



export const createBudgetItemApi = async (payload: any) => {
    const response = await apiClient.post(`/budget`, payload);
    return response.data;
  };


export const getBudgetApi = async (userId: string, year: string) => {
    const response = await apiClient.get(`/budget?userId=${userId}&year=${year}`);
    return response.data;
  }