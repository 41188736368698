import { useMemo } from 'react';
import Table, { TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';

interface ProductListProps {
  data: any[];
  loading: boolean;
}

export default function ProductListTable({ data, loading }: ProductListProps) {
  const tableConfigs: TableConfigs = useMemo(() => {
    return {
      cols: [
        {
          name: 'Zone',
          key: 'zone',
        },
        {
          name: 'Acres',
          key: 'acres',
          type: 'number',
          subTotal: true,
        },
        {
          name: 'Prescription',
          key: 'prescription',
        },
        {
          name: 'Prescription Quantity',
          key: 'prescriptionQuantity',
          type: 'number',
          subTotal: true,
        },
        {
          name: 'Prescription Cost',
          key: 'prescriptionCost',
          type: 'currency',
          subTotal: true,
        },
        {
          name: 'Cost Per Acre',
          key: 'costPerAcre',
          type: 'currency',
          subTotal: true,
        },
      ],
    };
  }, []);

  return (
    <TableLayout title={`Product List`}>
      <Table
        configs={tableConfigs || {}}
        data={data}
        subTotalRowVariant="secondary"
        loading={loading}
      />
    </TableLayout>
  );
}
