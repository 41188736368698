import {
  ArrowsPointingOutIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import { useEffect, useMemo } from 'react';
import Table, { TableConfigs } from '../../../components/commons/table/Table';
import TableLayout from '../../../components/dashboard/commons/TableLayout';
import { historicalInsightsTableDataInfo } from '../../../constants';
import { useUser } from '../../../contexts/auth-context';
import {
  useHistoricalInsights,
  useTableData,
} from '../../../hooks/historical-insights/use-historical-insights';
import { capitalizeAll, capitalizeFirst } from '../../../utils';
import { mapLabelMap } from '../../../constants/selectCommonConfigs';

function TableData() {
  const user = useUser();
  const {
    year,
    relationship,
    crop,
    setPage,
    setNewCenter,
    pageSize,
    tableChartRef,
    setxbin,
    setTableDataContext,
    xbin,
  } = useHistoricalInsights();
  const tableData = useTableData(user?.id || '', crop, relationship, year);


  useEffect(() => {
    setTableDataContext(tableData?.data);
  }, [setTableDataContext, tableData?.data]);

  const formattedTableData = useMemo(() => {
    return (tableData?.data ?? [])
      .map((data: any) => {
        return {
          ...data,
          area: `${parseFloat(data.area).toFixed(2)}`,
          color: { r: data.x_r, g: data.x_g, b: data.x_b },
          yield: parseFloat(data.yield).toFixed(2),
          yield_performance: parseFloat(data.yield_performance).toFixed(2),
        };
      })
      .sort((a: any, b: any) => {
        return (
          a.xbin - b.xbin || parseFloat(b.yield) - parseFloat(a.yield)
          // parseFloat(b.yield_performance) - parseFloat(a.yield_performance)
        );
      });
  }, [tableData?.data]);

  const historicalInsightsYieldConfigs = (
    relationship: string
  ): TableConfigs => {
    return {
      cols: [
        {
          key: 'x_bin',
          name: (
            <div className="flex min-w-[100px] overflow-visible relative group gap-2 items-center justify-center">
              <p>{mapLabelMap[relationship as keyof typeof mapLabelMap]}</p>
              <InformationCircleIcon className="w-8 h-8 text-yellow cursor-pointer" />
              <div className="absolute z-[999] mt-[160px] max-w-[300px] p-2 text-sm bg-gray-800 text-white rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                {
                  historicalInsightsTableDataInfo[
                    relationship as keyof typeof historicalInsightsTableDataInfo
                  ]
                }
              </div>
            </div>
          ),
          type: 'element',
          element: (item) => (
            <div
              onClick={() => {
                setNewCenter([parseFloat(item.lon), parseFloat(item.lat)]);
                if (relationship === 'variety') {
                  setxbin([Number(item.x_index)]);
                } else {
                  setxbin([
                    Number(item.x_bin.split('-')[0]),
                    Number(item.x_bin.split('-')[1]),
                  ]);
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              {item.x_bin}
            </div>
          ),
        },
        {
          key: 'color',
          name: 'Map Key',
          type: 'element',
          element: (item) => (
            <div
              onClick={() => {
                setNewCenter([parseFloat(item.lon), parseFloat(item.lat)]);
                if (relationship === 'variety') {
                  setxbin([Number(item.x_index)]);
                } else {
                  setxbin([
                    Number(item.x_bin.split('-')[0]),
                    Number(item.x_bin.split('-')[1]),
                  ]);
                }
              }}
              style={{
                backgroundColor: `rgb(${item.x_r}, ${item.x_g}, ${item.x_b})`,
                cursor: 'pointer',
              }}
              className={`w-full h-4`}
            />
          ),
        },
        {
          key: 'yield',
          name: 'Yield',
          type: 'element',
          element: (item) => (
            <div
              onClick={() => {
                setNewCenter([parseFloat(item.lon), parseFloat(item.lat)]);
                if (relationship === 'variety') {
                  setxbin([Number(item.x_index)]);
                } else {
                  setxbin([
                    Number(item.x_bin.split('-')[0]),
                    Number(item.x_bin.split('-')[1]),
                  ]);
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              {item.yield}
            </div>
          ),
        },
        {
          key: 'yield_performance',
          name: 'Yield Performance',
          type: 'element',
          element: (item) => (
            <div
              onClick={() => {
                setNewCenter([parseFloat(item.lon), parseFloat(item.lat)]);
                if (relationship === 'variety') {
                  setxbin([Number(item.x_index)]);
                } else {
                  setxbin([
                    Number(item.x_bin.split('-')[0]),
                    Number(item.x_bin.split('-')[1]),
                  ]);
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              {item.yield_performance}
            </div>
          ),
        },
        {
          key: 'area',
          name: 'Acres',
        }
      ],
    };
  };

  return (
    <div ref={tableChartRef} className="card overflow-hidden">
      <TableLayout
        title={
          <div className="flex items-center justify-between w-full">
            <p className="display-sm-bold cardHeader">
              All {capitalizeFirst(crop)} Fields
            </p>
          </div>
        }
      >
        {xbin[0] !== -1 && xbin[1] !== -1 && (
          <div
            className="card ml-auto mr-auto cursor-pointer max-w-max flex gap-2 px-4 py-2 rounded-md"
            onClick={() => setxbin([-1, -1])}
          >
            <ArrowsPointingOutIcon className="w-6 h-6 cursor-pointer" />
            <p>Show All</p>
          </div>
        )}

        <Table
          configs={historicalInsightsYieldConfigs(relationship)}
          loading={false}
          data={formattedTableData ? formattedTableData : []}
          pagination={{ size: pageSize }}
          onPageChange={(page) => setPage(page)}
        />
      </TableLayout>
    </div>
  );
}

export default TableData;
