import { useEffect, useState } from 'react';
import { getTimeSet } from '../../utils';
import { StateTimeZones } from '../../constants';

export const useTimeSet = (s3Keys: string[], type: string, state: string) => {
  const [timeSet, setTimeSet] = useState<any[]>([]);

  function convertTimeToStateTimeZone(time24: string) {
    if (!time24) {
      return '';
    }
    const timeZone = StateTimeZones[state as keyof typeof StateTimeZones]
  
    if (!timeZone) {
      throw new Error(`Time zone not found for state: ${state}`);
    }
  
    // Parse the input 24-hour time (e.g., '14:30')
    const [hours, minutes] = time24?.split(':').map(Number);
  
    // Create a UTC Date object with the provided time
    const utcDate = new Date();
    utcDate.setUTCHours(hours, minutes, 0, 0);
  
    // Format the UTC Date into the desired state's local time
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    
    const formattedDate = formatter.format(utcDate)
    return formattedDate;
  }
  

  useEffect(() => {
    if (type !== '24hrPrecipitation') {
      
      const result = getTimeSet(s3Keys);
      if (result.length > 0) {
        setTimeSet(result.map(item => convertTimeToStateTimeZone(item)));
      } else {
        // create a timeset of 24 hours in format 00:00, 09:13, 15:55, etc
        const times = Array.from({ length: 24 }, (_, i) => {
          const date = new Date();
          date.setHours(i);
          return convertTimeToStateTimeZone(date.getHours() < 10 ? `0${date.getHours()}:00` : `${date.getHours()}:00`);
        });
        setTimeSet(times);
      }
    } else {
      // parse dates
      const dates = s3Keys.map((key) => {
        const date = key.split('_')[1];
        return date;
      });
      if (dates.length > 0) {
        setTimeSet(dates);
      } else {
        // create a timeset of 24 hours in format 00:00, 09:13, 15:55, etc
        const times = Array.from({ length: 24 }, (_, i) => {
          const date = new Date();
          date.setHours(i);
          return date.getHours() < 10 ? `0${date.getHours()}:00` : `${date.getHours()}:00`;
        });
        setTimeSet(times);
      }
    }
  }, [s3Keys, type, state]);

  return {
    timeSet,
  };
};
