import { CreateExpensePayload, UpdateExpensePayload } from '../types';
import apiClient from './api-client';

export const getExpensesApi = (userId: string, year: string) => {
  return apiClient.get(`/expense?userId=${userId}&year=${year}`);
};

export const createExpenseApi = (payload: CreateExpensePayload[]) => {
  return apiClient.post('/expense', payload);
};

export const updateExpenseApi = (payload: UpdateExpensePayload) => {
  return apiClient.post(`expense/update`, payload);
};

export const deleteExpenseApi = ({ id }: { id: string }) => {
  return apiClient.delete(`expense?id=${id}`);
};


export const getExpenseLogApi = (userId: string) => {
  return apiClient.get(`/expense/log?user_id=${userId}`);
}

export const createExpenseLogApi = (payload: any) => {
  return apiClient.post('/expense/log', payload);
}