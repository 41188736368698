import AccessControl from '../../components/routes/AccessControl';
import { PERMISSIONS } from '../../constants';
import Dashboard from '../../pages/dashboard';
import ExpectedAreaAndYieldPage from '../../pages/dashboard/expected-area-and-yield';
import ExpectedRevenuePage from '../../pages/dashboard/expected-revenue';
import FieldPerformancePage from '../../pages/dashboard/field-performance';
import ForecastsPage from '../../pages/dashboard/forecasts';
import PricePage from '../../pages/dashboard/price';
import ValueAtRiskPage from '../../pages/dashboard/value-at-risk';

const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AccessControl permissions={[PERMISSIONS.DASHBOARD_ACCESS]} />
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'expected-revenue',
        element: <ExpectedRevenuePage />,
      },
      {
        path: 'value-at-risk',
        element: <ValueAtRiskPage />,
      },
      {
        path: 'expected-area-and-yield',
        element: <ExpectedAreaAndYieldPage />,
      },
      {
        path: 'price',
        element: <PricePage />,
      },
      {
        path: 'field-performance',
        element: <FieldPerformancePage />,
      },
      {
        path: 'forecasts',
        element: <ForecastsPage />,
      },
    ],
  },
];

export default dashboardRoutes;
