import { CloudArrowDownIcon } from '@heroicons/react/24/solid';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CompleteRegisterValueTypes } from '.';
import Button from '../../../components/commons/Button';
import Checkbox from '../../../components/commons/Checkbox';
import StyledCard from '../../../components/main/StyledCard';
import { SignUpSections } from '../../../constants';

export default function SMSMessagingTermsConditions({
  onNext,
  form,
}: {
  onNext: (nextSection: string) => void;
  form: UseFormReturn<CompleteRegisterValueTypes, any, undefined>;
}) {
  const navigate = useNavigate();
  return (
    <div className="max-w-screen-lg mx-auto">
      <StyledCard
        title="SMS Messaging Terms and Conditions"
        className="relative"
      >
        <div className="space-y-4 max-h-[500px] overflow-y-auto custom-scrollbar p-4 bg-base-1000 rounded-lg">
          <p>
            Please read these SMS/Text Messaging Terms &amp; Conditions
            carefully. By completing the SMS/Text Messaging Opt-In Form, you
            expressly consent to receive non-marketing and marketing text
            messages from AgriVaR LLC &quot;AgriVaR,&quot; including text
            messages made with an Auto dialer, at the mobile phone number(s) you
            provide.
          </p>

          <p>
            You may opt-out of these communications at any time by replying STOP
            to any text message from AgriVaR or by otherwise contacting AgriVaR
            as indicated below. You can receive help at any time by replying
            HELP to any text message from AgriVaR or by otherwise contacting
            AgriVaR as indicated below. Consent to receive non-marketing or
            marketing text messages is not required to purchase any products or
            services from AgriVaR. Messaging and data rates may apply. By
            completing the SMS text Messaging Opt-In Form, you also accept and
            agree to be bound by these SMS Text Messaging Terms &amp; Conditions
            and any other applicable terms and agreements related to your use of
            AgriVaR services.
          </p>

          <p>1. Program Description</p>

          <p>
            AgriVaR offers its text messaging program whereby AgriVaR, and any
            applicable service providers, will text message you to provide you
            with information concerning your account activity with AgriVaR and
            other AgriVaR products, services, and promotions (all of the
            foregoing the &quot; AgriVaR Program&quot;). AgriVaR and its service
            providers may, from time to time, use standard or an automatic
            telephone dialing system (&quot;Auto dialer&quot;) to deliver text
            messages to you under the AgriVaR Program. You agree that you will
            not use the AgriVaR Program for any illegal or unlawful purposes. No
            coverage may be bound or amended via text message or the AgriVaR
            Program.
          </p>

          <p>2. Message Frequency</p>

          <p>
            Under the AgriVaR Program, the number of AgriVaR text messages that
            you receive may vary depending upon your account activity and your
            communication with AgriVaR.
          </p>

          <p>3. Cost</p>

          <p>
            AgriVaR does not impose a separate fee for sending AgriVaR text
            messages under the AgriVaR Program. However, standard message and
            data rates may apply to each text message sent or received in
            connection with the AgriVaR Program, as provided in your mobile
            telephone service rate plan. Please contact your mobile telephone
            carrier for pricing plans and information.
          </p>

          <p>4. Supported Carriers; Interruption</p>

          <p>
            The AgriVaR Program should be available through your equipment or
            mobile device when the equipment or device is within the operating
            range of your wireless service provider. The AgriVaR Program may not
            be available on all equipment/mobile devices, or through all
            wireless carriers, and not all functionalities of the AgriVaR
            Program are available on all equipment/mobile devices, or through
            all wireless carriers. AgriVaR may, from time to time, in its
            discretion and without notice to you, limit the carriers that
            support the AgriVaR Program. Certain other carriers may not support
            the AgriVaR Program.
          </p>

          <p>
            Delivery of information and content to your equipment/mobile device
            may fail due to a variety of circumstances or conditions. The
            AgriVaR Program is subject to transmission limitation or
            interruption. You understand and acknowledge that mobile network
            services are outside of AgriVaR 's control, and AgriVaR is not
            responsible or liable for issues arising therefrom, or the failure
            thereof, including, without limitation, technical, hardware,
            software, electronic, network, telephone or other communications
            malfunctions, errors or failures of any kind, errors in
            transmission, traffic congestion, lost or unavailable network
            connections, telephone connections, wireless phone connections,
            website, Internet, or ISP availability, unauthorized human
            intervention, traffic congestion, incomplete or inaccurate capture
            of entry information (regardless of cause) or failed, incomplete,
            garbled, jumbled or delayed transmissions which may limit or
            restrict your ability to receive or send a message, including any
            injury or damage to your or any other person's equipment/wireless
            device relating to or resulting from participating in or using the
            AgriVaR Program. If the AgriVaR Program is not available within your
            intended location, you agree that your sole remedy is to cease using
            the AgriVaR Program.
          </p>

          <p>5. How to Opt-In</p>

          <p>
            To opt-in to receive text messages from AgriVaR under the AgriVaR
            Program, complete the SMS/Text Messaging Opt-In Form and submit it
            to AgriVaR. AgriVaR will then send you a text message asking you to
            confirm your enrollment in the AgriVaR Program.
          </p>

          <p>5. How to Opt-Out</p>

          <p>
            To STOP receiving text messages from AgriVaR, text STOP to the
            ten-digit long code from which the text messages are being sent. You
            will then receive confirmation of your opt-out of the AgriVaR
            text-messaging program. You may also opt out by providing written
            notice to the AgriVaR at 120 22nd Avenue South, Suite 132,
            Brookings, South Dakota 57006 or by emailing AgriVaR LLC at
            support@agrivar.ag, or calling AgriVaR at (605)-679-7177.
          </p>

          <p>6. Your Mobile Telephone Number</p>

          <p>
            You represent that you are the account holder for the mobile
            telephone number(s) that you provide or that you have the account
            holder's permission to enter the mobile telephone number(s) that you
            provide and that you will not initiate messages to the mobile phone
            of any other person or entity. You agree to maintain accurate,
            complete, and up-to date information with AgriVaR regarding your use
            of the AgriVaR Program, including, without limitation, notifying
            AgriVaR in writing immediately if you change, or cease being the
            regular user of, your mobile telephone number. You agree to
            indemnify AgriVaR in full for all claims, expenses, damages, and
            costs, including reasonable attorneys' fees, related to or caused in
            whole or in part by your failure to notify AgriVaR if you change
            your telephone number, or cease being the regular user of,
            including, but not limited to, all claims, expenses, damages, and
            costs related to or arising under the Telephone Consumer Protection
            Act, 47 U.S.C. &sect; 227 et seq.
          </p>

          <p>7. Privacy</p>

          <p>
            We value your privacy and the information you consent to share in
            relation to our SMS marketing service. We use this information to
            send you text notifications (for your order, including abandoned
            checkout reminders), text marketing offers, and transactional texts,
            including requests for reviews from us.
          </p>

          <p>
            Our website uses cookies to keep track of items you put into your
            shopping cart, including when you have abandoned your checkout. This
            information is used to determine when to send cart reminder messages
            via SMS.
          </p>

          <p>
            AgriVaR takes precautions to ensure the security of your Personal
            Information, including ensuring that our third party service
            providers protect the security of your Personal Information.
            However, we cannot guarantee that hackers or unauthorized personnel
            will not gain access to your Personal Information despite our
            efforts. You should note that in using the Service, your information
            will travel through third party infrastructures which are not under
            our control (such as a third party SMS delivery platform or your
            carrier network).
          </p>

          <p>
            We cannot protect, nor does this Policy apply to, any information
            that you transmit to other users. You should never transmit personal
            or identifying information to other users.
          </p>

          <p>8. Support/Help</p>

          <p>
            To request more information, text HELP to the ten-digit long code
            from which the text messages are being sent. You may also receive
            assistance by providing written notice to AgriVaR at 120 22nd Avenue
            South, Suite 132, Brookings, South Dakota 57006 or by emailing
            AgriVaR LLC at support@agrivar.ag, or calling AgriVaR at
            (605)-679-7177.
          </p>

          <p>9. Eligibility</p>

          <p>
            To receive AgriVaR text messages or enroll in the AgriVaR Program,
            you must be a resident of the United States, eighteen (18) years of
            age or older, authorized to enroll the mobile phone number in the
            AgriVaR Program, and authorized to incur any mobile message or data
            charges incurred by participating. AgriVaR reserves the right to
            require you to prove the foregoing to participate in the AgriVaR
            Program. &#8232;&#8232; Changes to this SMS/Text Messaging Terms
            &amp; Conditions
          </p>

          <p>
            AgriVaR may revise, modify, or amend these AgriVaR LLC SMS/Text
            Messaging Terms &amp; Conditions at any time. Any such revision,
            modification, or amendment shall take effect when it is posted to
            AgriVaR website. You agree to review these AgriVaR SMS/Text
            Messaging Terms &amp; Conditions periodically to ensure that you are
            aware of any changes. Your continued consent to receive AgriVaR LLC
            text messages will indicate your acceptance of those changes.
          </p>

          <p>10. Amendment; Termination of Text Messaging</p>

          <p>
            AgriVaR LLC may suspend or terminate your receipt of AgriiVar LLC
            text messages if AgriVaR LLC believes you are in breach of these
            AgriVaR LLC SMS/Text Messaging Terms &amp; Conditions. Your receipt
            of AgriVaR LLC text messages is also subject to termination in the
            event that your mobile telephone service terminates or lapses.
            AgriVaR LLC reserves the right to modify or discontinue, temporarily
            or permanently, all or any part of AgriVaR LLC text messages and the
            AgriVaR LLC Program, for any reason, with or without notice to you.
          </p>

          <p>11. Release: No Warranties; Limitation of Liability</p>

          <p>
            By participating in the AgriVaR Program, you agree to release and
            hold harmless AgriVaR and its representatives, agents, successors,
            assigns, employees, officers and directors, from any and all
            liability, for loss, harm, damage, injury, attorneys' fees, cost or
            expense whatsoever including without limitation, property damage,
            personal injury and death that may occur in connection with the
            AgriVaR, or your use thereof, and for any claims, matters, or
            disputes based on violation of law or infringement or violation of
            any rights of any person or entity, including, without limitation,
            violation of publicity rights, defamation, or invasion of privacy.
          </p>

          <p>
            THE AGRIVAR PROGRAM IS PROVIDED AS IS, WHERE IS AND WITH ALL FAULTS
            AND WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED, WRITTEN, ORAL OR
            ARISING UNDER CUSTOM OR TRADE. AGRIVAR DISCLAIMS ALL WARRANTIES,
            INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSES AND
            NON-INFRINGEMENT.
          </p>

          <p>
            In no event shall AgriVaR be liable to you, or to any third party,
            for any losses or damages whatsoever arising out of or resulting
            from the AgriVaR Program, or your use thereof, regardless of the
            theory of recovery, including, without limitation, direct, indirect,
            consequential, incidental or special damages, lost profits, punitive
            damages, attorney's fees or any damages arising out of or resulting
            from a statutory or regulatory violation, negligence, gross
            negligence, strict liability, tortious conduct, willful misconduct
            or fraud, breach of contract, or breach of warranty. To the extent
            this damage waiver is contrary to applicable law, your or any third
            party's maximum recovery for any losses or damages whatsoever
            arising out of or resulting from the AgriVaR Program, or your use
            thereof, regardless of the theory of recovery, is $1.00.
          </p>

          <p>12. Arbitration/Class Action Waiver</p>

          <p>
            ALL DISPUTES, MATTERS, OR CLAIMS BETWEEN YOU AND AGRIVAR ARISING OUT
            OF OR RELATING IN ANY WAY TO THESE SMS/TEXT MESSAGING TERMS &amp;
            CONDITIONS, ANY TEXT MESSAGES BETWEEN YOU AND AGRIVAR OR ANYONE
            ACTING ON YOUR OR AGRIVAR'S BEHALF, OR THE AGRIVAR PROGRAM,
            REGARDLESS OF THE THEORY OF LIABILITY OR RECOVERY, MUST BE RESOLVED
            SOLELY BY ARBITRATION CONDUCTED EXPEDITIOUSLY IN ACCORDANCE WITH THE
            AMERICAN ARBITRATION ASSOCIATION CONSUMER ARBITRATION RULES
            (&quot;AAA RULES&quot;) BY A SOLE ARBITRATOR SELECTED BY THE PARTIES
            TO THE DISPUTE FROM THE NATIONAL OR STATE OF SOUTH DAKOTA PANEL OF
            ARBITRATORS. ALL DISPUTES, MATTERS, OR CLAIMS BETWEEN YOU AND
            AGRIVAR INCLUDES, WITHOUT LIMITATION, ANY ACTUAL OR ALLEGED
            STATUTORY OR REGULATORY VIOLATION, EQUITABLE CLAIM, NEGLIGENCE,
            GROSS NEGLIGENCE, STRICT LIABILITY, TORTIOUS CONDUCT, WILLFUL
            MISCONDUCT OR FRAUD, BREACH OF CONTRACT, BREACH OF WARRANTY, AS WELL
            AS ALL DISPUTES, MATTERS, OR CLAIMS ARISING OUT OF OR RELATING IN
            ANY WAY TO THE ARBITRATION/CLASS ACTION WAIVER PROVISION OR THE
            RELEASE: NO WARRANTIES; LIMITATION OF LIABILITY PROVISION.
          </p>

          <p>
            This agreement to arbitrate is intended to be broadly interpreted,
            and expressly includes disputes, matters, or claims brought under
            the Telephone Consumer Protection Act, 47 U.S.C. &sect; 227 et seq.,
            or any other statute, regulation, or legal or equitable
            theory.&#160;
          </p>

          <p>
            THIS PROVISION MUST CONSTRUED IN ACCORDANCE WITH, AND THE
            ARBITRATION MUST BE GOVERNED BY, THE FEDERAL ARBITRATION ACT (9
            U.S.C. &sect; One et seq.). ANY JUDGMENT UPON THE DECISION RENDERED
            BY THE ANY COURT HAVING JURISDICTION MAY ENTER ARBITRATOR. UNLESS
            OTHERWISE REQUIRED BY LAW, THE ARBITRATOR IS PROHIBITED FROM
            AWARDING DAMAGES IN EXCESS OF THE LIMITATIONS SET FORTH IN THESE
            SMS/TEXT MESSAGING TERMS &amp; CONDITIONS.
          </p>

          <p>
            EITHER PARTY TO THIS AGREEMENT MAY COMMENCE ARBITRATION PROCEEDINGS
            BY DELIVERING TO THE OTHER PARTY A WRITTEN NOTICE IN ACCORDANCE WITH
            THE AAA RULES. ANY ARBITRATION HEARING SHALL BE HELD IN THE STATE OF
            IOWA, UNLESS THE PARTIES TO THE DISPUTE AGREE OTHERWISE. ANY
            JUDGMENT RENDERED BY ARBITRATION SHALL BE FINAL AND BINDING ON THE
            PARTIES, AND MAY BE ENTERED IN ANY COURT OF COMPETENT JURISDICTION.
            NOTWITHSTANDING ANY AAA RULES TO THE CONTRARY, THE AWARD OF THE
            ARBITRATOR MUST BE MADE NO LATER THAN THREE (3) MONTHS FOLLOWING THE
            DATE ON WHICH THE ARBITRATOR IS APPOINTED, UNLESS THE ISSUE IS THE
            SUBJECT OF LITIGATION BROUGHT BY A THIRD PARTY AND THE ARBITRATOR
            DEEMS IT APPROPRIATE TO DEFER ITS AWARD UNTIL THE LITIGATION IS
            RESOLVED.
          </p>

          <p>
            The resolution of any claims, matters, or disputes must proceed
            solely on an individual basis without the right for any claims,
            matters, or disputes to proceed on a class action or collective
            basis or on bases involving claims, matters, or disputes brought in
            a purported representative capacity on behalf of others. The
            arbitrator's authority to resolve and make written awards is limited
            to claims, matters, and disputes between you and AgriVaR alone.
            Claims, matters, or disputes may not be joined or consolidated
            unless agreed to in writing by all parties. No arbitration award or
            decision will have any preclusive effect as to issues, claims,
            matters, or disputes with anyone who is not a named party to the
            arbitration.
          </p>

          <p>13. Attorney Fees</p>

          <p>
            In the event of any claim, matter, or dispute between you and
            AgriVaR arising out of or relating in any way to these SMS/Text
            Messaging Terms &amp; Conditions, any text messages between you and
            AgriVaR or anyone acting on your or AgriVaR 's behalf, or the
            AgriVaR Program, AgriVaR, if it prevails, must be awarded all costs
            of bringing or defending the claim, matter, or dispute, including,
            without limitation, reasonable attorneys' fees and the ordinary
            costs and expenses incurred in resolving the claim, matter, or
            dispute, including, without limitation, all arbitration related fees
            and expenses.
          </p>

          <p>14. Applicable Law</p>

          <p>
            The law of the State of South Dakota including its statutes of
            limitations and without reference to any conflict of laws
            principles, shall be applied to any claim, matter, or dispute
            between the parties or when governing, constraining, or enforcing
            these SMS/Text Messaging Terms &amp; Conditions, the AgriVaR
            Program, or any text messages between you and AgriVaR or anyone
            acting on your or AgriVaR 's behalf, or any of the rights, duties,
            or obligations of the parties arising from or relating in any way to
            the subject matter of these Terms &amp; Conditions.
          </p>

          <p>15. Contact Us</p>

          <p>
            If you have questions regarding these SMS/Text Messaging Terms &amp;
            Conditions, you may reach us by calling (605)-679-7177, by mail at
            120 22nd Avenue South, Suite 132, Brookings, South Dakota 57006 or
            by emailing AgriVaR LLC at support@agrivar.ag.
          </p>
        </div>
        <Checkbox
          name="sms_terms_conditions"
          rounded
          label="I have read, understand, and agree to the Terms of Service"
          className="mt-5"
        />
        <Button
          color="primary"
          size="lg"
          className="!rounded-md !font-normal !w-fit mt-5 mx-auto"
          disabled={!form.watch('sms_terms_conditions')}
          onClick={() => {
            if (!form.watch('is_advisor_client')) {
              onNext(SignUpSections.PlanSelection);
            }
          }}
          type={form.watch('is_advisor_client') ? 'submit' : 'button'}
        >
          {form.watch('is_advisor_client') ? 'Submit' : 'Continue'}
        </Button>
        <button className="flex items-center space-x-2 text-[#343E44] mx-auto mt-4">
          <CloudArrowDownIcon className="size-6" />
          <span>Download Terms</span>
        </button>
        <p className="text-right absolute right-6 bottom-4">3/3</p>
      </StyledCard>
    </div>
  );
}
