import {
  FarmBudgetIcon,
  FarmIcon,
  FieldIcon,
  FieldPerformanceIcon,
  ScenarioAnalysisIcon,
  WarningIcon,
  ZoneIcon,
} from '../../../assets/icons';
import ChartIcon from '../../../assets/icons/ChartIcon';
import DieIcon from '../../../assets/icons/DieIcon';
import DollarIcon from '../../../assets/icons/DollarIcon';
import MoneyBagIcon from '../../../assets/icons/MoneyBagIcon';
import RiskIcon from '../../../assets/icons/RiskIcon';
import SatelliteIcon from '../../../assets/icons/SatelliteIcon';
import SatelliteIcon2 from '../../../assets/icons/SatelliteIcon2';
import SatelliteIcon3 from '../../../assets/icons/SatelliteIcon3';
import { mapTooltipStyles } from '../../../constants';
import { IconProps } from '../../../types';

export const tabs: {
  [key: string]: { label: string; icon: ({ fill }: IconProps) => JSX.Element };
} = {
  revenue: {
    label: 'Revenue',
    icon: DollarIcon,
  },
  net_income: {
    label: 'Net Income',
    icon: MoneyBagIcon,
  },
  yield: {
    label: 'Yield',
    icon: ChartIcon,
  },
  yield_performance: {
    label: 'Yield Performance',
    icon: FieldPerformanceIcon,
  },
  yield_probability: {
    label: 'Yield Probability',
    icon: DieIcon,
  },
  yield_error: {
    label: 'Yield Error',
    icon: WarningIcon,
  },
  sigma: {
    label: 'Risk',
    icon: RiskIcon,
  },
  ndvi_s2: {
    label: 'Satellite',
    icon: SatelliteIcon2,
  },
  ndvi_deviation: {
    label: 'Satellite Deviation',
    icon: SatelliteIcon,
  },
  // hiResolutionSatellite: {
  //   label: 'Hi-Resolution Satellite',
  //   icon: SatelliteIcon3,
  // },
};

export const getTooltip = (info: any) => {
  if (info.index === -1) return null;
  const fieldArray = new Uint8Array(info.layer.props.data.field?.[info.index]);
  const field = new TextDecoder().decode(fieldArray);
  const cropArray = new Uint8Array(info.layer.props.data.crop?.[info.index]);
  const crop = new TextDecoder().decode(cropArray);
  const revenue = parseFloat(info.layer.props.data.value?.[info.index]).toFixed(
    2
  );
  const type = info.layer.props.data.type;
  return {
    html: `
      <div>
        <p>
          <b>Field:</b> ${field}
        </p>
        <p>
          <b>Crop:</b> ${crop}
        </p>
        <p>
          <b>${tabs[type]?.label}:</b> ${revenue}
        </p>
      </div>
    `,
    style: mapTooltipStyles,
  };
};
