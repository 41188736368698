export const optionTypeOptions = [
  { label: 'Call', value: 'call' },
  { label: 'Put', value: 'put' },
];

export const watchTypeOptions = [
  { value: 'cash', label: 'Cash' },
  { value: 'futures', label: 'Futures' },
  { value: 'options', label: 'Options' },
  { value: 'basis', label: 'Basis' },
  { value: 'spread', label: 'Spread' },
];

export const areaSelectionOptions = [
  { label: 'All Corn Fields', value: 'Corn' },
  // { label: 'All HRW Wheat Fields', value: 'Wheat' },
  // { label: 'All SRW Wheat Fields', value: 'Wheat' },
  { label: 'All Soybean Fields', value: 'Soybeans' },
];

export const mapRelationshipOptions = [
  { label: 'Variety', value: 'variety' },

  { label: 'Field', value: 'field_name' },
  { label: 'Agrivar Zone', value: 'agrivar_zone' },
  { label: 'Yield Goal', value: 'zone_yield'},
  { label: 'Elevation', value: 'elev' },
  { label: 'Slope', value: 'slope' },
  { label: 'Flow', value: 'flow' },
  { label: 'OM (%)', value: 'OM' },
  { label: 'PH', value: 'pH' },
  { label: 'Sand (%)', value: 'sand' },
  { label: 'Silt (%)', value: 'silt' },
  { label: 'Clay (%)', value: 'clay' },
  { label: 'CEC (Milliequivalents)', value: 'CEC_meq' },
  {label: 'Mg (ppm)', value: 'Mg_ppm'},
  {label: 'P (ppm)', value: 'P_ppm'},
  {label: 'Na (ppm)', value: 'Na_ppm'},
  {label: 'K (ppm)', value: 'K_ppm'},
  {label: 'Ca (ppm)', value: 'Ca_ppm'},
  {label: 'Cu (ppm)', value: 'Cu_ppm'},
  {label: 'Zn (ppm)', value: 'Zn_ppm'},
  {label: 'BS K (%)', value: 'BS_K_pct'},
  {label: 'BS Na (%)', value: 'BS_Na_pct'},
  {label: 'BS Ca (%)', value: 'BS_Ca_pct'},
  {label: 'BS Mg (%)', value: 'BS_Mg_pct'},


  {label: 'Harvest Moisture', value: 'moisture'},
  {label: 'Harvest Date', value: 'harvest_date'},
  {label: 'Harvest Speed', value: 'harvest_speed'},
  { label: 'Seeding Rate (Seeds per Acre)', value: 'seeding_rate' },
  { label: 'Seed Yield', value: 'seed_yield' },
  {label: 'Plant Week', value: 'plant_week'},
  {label: 'Plant Date', value: 'plant_date'},
  {label: 'Plant Speed', value: 'plant_speed'},
  {label: 'Previous Crop', value: 'previous_crop'},
  


];

export const categoryOptions = [
  {
    label: 'Depreciation', value: 'depreciation',
  },
  {
    label: 'Fertilizer', value: 'fertilizer'
  },
  {
    label: 'Interest', value: 'interest'
  },
  {
    label: 'Irrigation', value: 'irrigation'
  },
  {
    label: 'Labor', value: 'labor'
  },
  {
    label: 'Management', value: 'management'
  },
  {
    label: 'Miscellaneous', value: 'miscellaneous'
  },
  {
    label: 'Pesitcide', value: 'pesticid'
  },
  {
    label: 'Repair', value: 'repair'
  },
  {
    label: 'Seed', value: 'seed'
  },
  {
    label: 'Other', value: 'other'
  }
]

export const budgetItemOptions = [
  {
    label: 'Total', value: 'total',
  },
  {
    label: 'Acre', value: 'acre'
  }
]

export const mapLabelMap = {
  variety: "Variety",
  field_name: "Field",
  agrivar_zone: "Agrivar Zone",
  zone_yield: "Yield Goal",
  elev: "Elevation",
  slope: "Slope",
  flow: "Flow",
  OM: "OM",
  pH: "PH",
  sand: "Sand",
  silt: "Silt",
  clay: "Clay",
  CEC_meq: "CEC",
  Mg_ppm: "Mg",
  P_ppm: "P",
  Na_ppm: "Na",
  K_ppm: "K",
  Ca_ppm: "Ca",
  Cu_ppm: "Cu",
  Zn_ppm: "Zn",
  BS_K_pct: "BS K",
  BS_Na_pct: "BS Na",
  BS_Ca_pct: "BS Ca",
  BS_Mg_pct: "BS Mg",
  moisture: "Harvest Moisture",
  harvest_date: "Harvest Date",
  harvest_speed: "Harvest Speed",
  seeding_rate: "Seeding Rate",
  seed_yield: "Seed Yield",
  plant_week: "Plant Week",
  plant_date: "Plant Date",
  plant_speed: "Plant Speed",
  previous_crop: "Previous Crop"
}


export const mapTabOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Revenue',
    value: 'revenue',
  },
  {
    label: 'Net Income',
    value: 'net_income',
  },
  {
    label: 'Yield',
    value: 'yield',
  },
  {
    label: 'Yield Performance',
    value: 'yield_performance',
  },
  {
    label: 'Yield Probability',
    value: 'yield_probability',
  },
  {
    label: 'Yield Error',
    value: 'yield_error',
  },
  {
    label: 'Risk',
    value: 'sigma',
  },
  {
    label: 'Satellite',
    value: 'ndvi_s2',
  },
  {
    label: 'Satellite Deviation',
    value: 'ndvi_pct',
  },
  {
    label: 'Hi-Resolution Satellite',
    value: 'hiResolutionSatellite',
  },
];

export const optionFilters = [
  {
    label: `All Options`,
    value: 'all',
  },
  {
    label: 'Call',
    value: 'call',
  },
  {
    label: 'Put',
    value: 'put',
  },
  {
    label: 'In the Money',
    value: 'ITM',
  },
  {
    label: 'Out of the Money',
    value: 'OTM',
  },
  {
    label: 'Near the Money',
    value: 'NTM',
  },
];
