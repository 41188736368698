import React, { useMemo } from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useUser } from '../../../contexts/auth-context';
import {
  useHarvestData,
  useHistoricalInsights,
} from '../../../hooks/historical-insights/use-historical-insights';
import LineChart from '../../../components/commons/charts/LineChart';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { capitalizeAll } from '../../../utils';
import TableLayout from '../../../components/dashboard/commons/TableLayout';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Scatter } from 'react-chartjs-2';
import { mapLabelMap } from '../../../constants/selectCommonConfigs';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

function HarvestChart() {
  const user = useUser();
  const { year, crop, relationship, tableDataContext } =
    useHistoricalInsights();

  const bins = useMemo(() => {
    return JSON.stringify(tableDataContext?.map((item: any) => item?.x_bin));
  }, [tableDataContext]);

  const yearFilter = useYearFilter();
  const data = useHarvestData(
    user?.id + '_' + yearFilter,
    crop,
    year,
    relationship,
    bins
  );

  const chartData = useMemo(() => {
    if (relationship === 'variety' || relationship === 'field_name') {
      const ret: { [key: string]: { x: string; y: string }[] } = {};
      Object.keys(data?.data || {})?.map((variety: string) => {
        const records: any[] = data.data[variety] || [];
        for (const record of records) {
          if (!ret[variety]) {
            ret[variety] = [];
          }
          const item = {
            x: parseFloat(
              record?.values?.toLowerCase() === 'nan'
                ? 0
                : record?.values === 'True' || record?.values === 'False'
                  ? record?.values === 'True'
                    ? 1
                    : 0
                  : record?.values
            ).toFixed(2),
            y: parseFloat(record?.average_effect).toFixed(7),
          };
          ret[variety].push(item);
        }
      });
      return ret;
    }
    return data?.data?.map((item: any) => {
      return {
        x: parseFloat(
          item?.values?.toLowerCase() === 'nan' ? 0 : item?.values
        ).toFixed(2),
        y: parseFloat(item?.average_effect).toFixed(2),
      };
    });
  }, [data?.data]);


  const varietyData = useMemo(() => {
    if (relationship === 'agrivar_zone') {
      return {
        labels: Object.keys(data?.data || {}),
        datasets: [
          {
            data: data?.data?.map((record: any) => {
              return parseFloat(record?.average_effect).toFixed(2) || 1;
            }),
            label: 'Harvest Data',
            backgroundColor: data?.data?.map((record: any) => `green`),
            borderColor: Object.keys(data?.data || {})?.map((key) => `green`),
            borderWidth: 1,
            borderRadius: 5,
          },
        ],
      };
    }
    return {
      labels: Object.keys(data?.data || {}),
      datasets: [
        {
          data: Object.keys(data?.data || {})?.map((variety: string) => {
            return (
              parseFloat(data?.data[variety][0]?.average_effect).toFixed(4) || 1
            );
          }),
          label: 'Harvest Data',
          backgroundColor: Object.keys(data?.data || {})?.map(
            (key) =>
              `rgb(${tableDataContext?.find((item: any) => item.x_bin === key)?.x_r}, ${tableDataContext?.find((item: any) => item.x_bin === key)?.x_g}, ${tableDataContext?.find((item: any) => item.x_bin === key)?.x_b})`
          ),
          borderColor: Object.keys(data?.data || {})?.map(
            (key) =>
              `rgb(${tableDataContext?.find((item: any) => item.x_bin === key)?.x_r}, ${tableDataContext?.find((item: any) => item.x_bin === key)?.x_g}, ${tableDataContext?.find((item: any) => item.x_bin === key)?.x_b})`
          ),
          borderWidth: 1,
          borderRadius: 5,
        },
      ],
    };
  }, [data.data]);

  console.log('variety data', varietyData);

  const datasets = useMemo(() => {
    if (relationship === 'variety') {
      return Object.keys(chartData || {})?.map((key: string) => {
        return {
          label: key,
          data: chartData[key],
          backgroundColor: 'primary',
          borderColor: `rgb(${tableDataContext.find((item: any) => item.x_bin === key)?.x_r}, ${tableDataContext.find((item: any) => item.x_bin === key)?.x_g}, ${tableDataContext.find((item: any) => item.x_bin === key)?.x_b})`,
          pointBackgroundColor: `rgb(${tableDataContext.find((item: any) => item.x_bin === key)?.x_r}, ${tableDataContext.find((item: any) => item.x_bin === key)?.x_g}, ${tableDataContext.find((item: any) => item.x_bin === key)?.x_b})`,
          fill: false,
          borderWidth: 1,
          borderRadius: 5, // Rounded bars
          barThickness: 25, // Thickness of the bars
        };
      });
    }

    return [
      {
        label: 'Estimated',
        data: chartData,
        backgroundColor: 'primary',
        borderColor: 'rgba(255,183,27,1)',
        pointBackgroundColor: 'rgba(255,183,27,1)',
        fill: false,
      },
    ];
  }, [chartData]);

  // Define chart options
  const options: any = useMemo(
    () => ({
      responsive: true,
      layout: {
        padding: {
          left: 0,
          right: 80,
          top: 0,
          bottom: 0,
        },
      },
      indexAxis: 'y',
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false, // Hide the grid lines
          },
          title: {
            display: true,
            text:
              relationship === 'variety' ||
              relationship === 'field_name' ||
              relationship === 'agrivar_zone'
                ? 'Difference in Bushels'
                : mapLabelMap[relationship as keyof typeof mapLabelMap],
            font: { size: 14 },
          },
        },
        y: {
          title: {
            display: true,
            text:
              relationship === 'variety' ||
              relationship === 'field_name' ||
              relationship === 'agrivar_zone'
                ? mapLabelMap[relationship as keyof typeof mapLabelMap]
                : 'Difference in Bushels',
          },
          beginAtZero: true, // Start y-axis at zero
          grid: {
            display: false, // Hide the grid lines
          },
        },
      },
      plugins: {
        datalabels: {
          anchor: (context: any) => {
            return parseFloat(context.dataset.data[context.dataIndex]) >= 0
              ? 'end'
              : 'start'; // Anchor right for positive, left for negative
          },
          align: (context: any) =>
            parseFloat(context.dataset.data[context.dataIndex]) >= 0
              ? 'end'
              : 'start', // Anchor right for positive, left for negative
          formatter: (value: any) => `${parseFloat(value).toFixed(4)} Bu/Ac`,
          color: (context: any) => {
            const value = parseFloat(context.dataset.data[context.dataIndex]);
            if (value < 0) return 'red'; // Red for negative values
            if (value > 0) return 'green'; // Green for positive values
            return 'white'; // White for zero
          },
          font: {
            size: 12,
            weight: 'bold',
          },
        },
        legend: {
          labels: {
            color: 'white',
            font: {
              family: 'RobotoCondensed',
              size: 16,
            },
          },
        },
        // tooltip: {
        //   enabled: true,
        //   callbacks: {
        //     label: (tooltipItem: any) => `${capitalizeAll(relationship.split('_').join(' '))}: ${tooltipItem.raw.y}, Difference in Bushels: ${tooltipItem.raw.x}`,
        //   },
        // },
      },
    }),
    [relationship, datasets]
  );

  const harvestTabLabels = {
    variety:
      'Harvest effect uses a machine learning model to determine if the variety had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    field_name:
      'Harvest effect uses a machine learning model to determine if the field had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    agrivar_zone:
      'Harvest effect uses a machine learning model to determine if the zone number had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    zone_yield:
      'Harvest effect uses a machine learning model to determine if the zone number had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    elev: 'Harvest effect uses a machine learning model to determine if the elevation index had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    slope:
      'Harvest effect uses a machine learning model to determine if the slope percent had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    flow: 'Harvest effect uses a machine learning model to determine if the degree of water flow had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    OM: 'Harvest effect uses a machine learning model to determine if the OM percent had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    pH: 'Harvest effect uses a machine learning model to determine if the pH had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    sand: 'Harvest effect uses a machine learning model to determine if the percent sand had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    silt: 'Harvest effect uses a machine learning model to determine if the percent silt a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    clay: 'Harvest effect uses a machine learning model to determine if the percent clay had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    CEC_meq:
      'Harvest effect uses a machine learning model to determine if the CEC had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    Mg_ppm:
      'Harvest effect uses a machine learning model to determine if the Mg had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    P_ppm:
      'Harvest effect uses a machine learning model to determine if the P had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    Na_ppm:
      'Harvest effect uses a machine learning model to determine if the Na had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    K_ppm:
      'Harvest effect uses a machine learning model to determine if the K had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    Ca_ppm:
      'Harvest effect uses a machine learning model to determine if the Ca had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    Cu_ppm:
      'Harvest effect uses a machine learning model to determine if the Cu had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    Zn_ppm:
      'Harvest effect uses a machine learning model to determine if the Zn had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    BS_K_pct:
      'Harvest effect uses a machine learning model to determine if the percent of K had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    BS_Na_pct:
      'Harvest effect uses a machine learning model to determine if the percent of Na had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    BS_Ca_pct:
      'Harvest effect uses a machine learning model to determine if the percent of Ca had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    BS_Mg_pct:
      'Harvest effect uses a machine learning model to determine if the percent of Mg had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    moisture:
      'Harvest effect uses a machine learning model to determine if the moisture had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    harvest_date:
      'Harvest effect uses a machine learning model to determine if the harvest date had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    harvest_speed:
      'Harvest effect uses a machine learning model to determine if the harvest speed had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    seeding_rate:
      'Harvest effect uses a machine learning model to determine if the seeding rate had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    seed_yield:
      'Harvest effect uses a machine learning model to determine if the seed yield had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    plant_week:
      'Harvest effect uses a machine learning model to determine if the plant week had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    plant_date:
      'Harvest effect uses a machine learning model to determine if the plant date had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    plant_speed:
      'Harvest effect uses a machine learning model to determine if the plant speed had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
    previous_crop:
      'Harvest effect uses a machine learning model to determine if the previous crop had a unique effect in increasing or decreasing harvest yields when controlling for other factors',
  };

  if (chartData?.length > 0 || Object.keys(chartData || {}).length > 0) {
    return (
      <div className="card">
        <TableLayout
          title={
            <div className="flex min-w-[100px] overflow-visible relative group gap-2 items-center justify-center">
              <p className="text-2xl font-bold">
                {mapLabelMap[relationship as keyof typeof mapLabelMap]} Harvest
                Effect
              </p>

              <InformationCircleIcon className="w-8 h-8 text-yellow cursor-pointer" />
              <div className="absolute z-[999] mt-[160px] max-w-[300px] p-2 text-sm bg-gray-800 text-white rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                {
                  harvestTabLabels[
                    relationship as keyof typeof harvestTabLabels
                  ]
                }
              </div>
            </div>
          }
        />
        <div
          className="flex flex-col items-center px-4 py-8 justify-center"
          style={{
            width: '100%',
            height:
              relationship === 'variety' ||
              relationship === 'field_name' ||
              relationship === 'agrivar_zone'
                ? varietyData.labels?.length *
                  (varietyData.labels?.length > 10 ? 40 : 60)
                : '500px',
          }}
        >
          {/*  */}
          {relationship === 'variety' ||
          relationship === 'field_name' ||
          relationship === 'agrivar_zone' ? (
            <Bar
              data={varietyData}
              options={options}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Scatter data={{ datasets: datasets }} options={options} />
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default HarvestChart;
