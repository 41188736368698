import React from 'react';
import TextInput from '../../commons/inputs/TextInput';
import { Form } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../../commons/Button';
import { useUpdateInvoiceDetails } from '../../../hooks/invoice/use-invoice';
import { useUser } from '../../../contexts/auth-context';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';

function EditInvoiceDetails({ invoice }: any) {

  const form = useForm({
    defaultValues: {
      itemlocdesc: invoice?.itemlocdesc,
      partner: invoice?.partner,
      categorytypedesc: invoice?.categorytypedesc,
    },
  });
  const { handleSubmit } = form;

  const queryClient = useQueryClient();

  const onSubmit = (data: any) => {
    updateInvoiceDetails({...data, index: invoice?.index, uniqueid: invoice?.uniqueid}, {
        onSuccess: () => {
            alert('Invoice details updated successfully');
            queryClient.invalidateQueries([
                'invoices',
                user?.id ?? '',
                user?.network_partner ?? 'New Vision',
              ] as InvalidateQueryFilters);
        }, 
        onError: (error) => {
            alert('Error updating invoice details');
        }
    });
  }

  const user = useUser();
  const { mutate: updateInvoiceDetails } = useUpdateInvoiceDetails(user?.id ?? '',
  user?.network_partner ?? 'New Vision');

  return (
    <div>
      <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} >
        <TextInput
          name="itemlocdesc"
          label="Invoice Location"
          onChange={(e) => {}}
        />

        <TextInput
          name="partner"
          label="Invoice Partner"
          onChange={(e) => {}}
        />

        <TextInput
          name="categorytypedesc"
          label="Category Type"
          onChange={(e) => {}}
        />
        {/* submit changes */}
        <Button type="submit" className='max-w-max mt-4 mr-auto ml-auto' color='primary'>Submit</Button>
        </form>
      </FormProvider>
    </div>
  );
}

export default EditInvoiceDetails;
