import { useMutation, useQuery } from "@tanstack/react-query";
import { createBudgetItemApi, getBudgetApi } from "../../api/budget-api";



export const useCreateBudgetItem = () => {
    const mutation = useMutation({
      mutationKey: ['budget', 'CREATE', 'item'],
      mutationFn: (payload: any) => createBudgetItemApi(payload),
    });
  
    return mutation;
  };

export const useBudgetItems = (userId: string, year: string) => {
    const res = useQuery({
      queryKey: ['budget', userId, year],
      queryFn: () => getBudgetApi(userId, year),
      enabled: !!userId,
    });
  
    return { data: res.data };
  }