import { InformationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useState } from 'react';
import './index.css';

interface TooltipProps {
  children?: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  content: React.ReactNode;
  icon?: React.ReactNode;
  standalone?: boolean;
}

export default function Tooltip({
  children,
  content,
  position = 'top',
  standalone = false,
  icon = <InformationCircleIcon className="size-5 text-yellow" />,
}: TooltipProps) {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  if (standalone) {
    return (
      <div
        onMouseEnter={toggleVisibility}
        onMouseLeave={toggleVisibility}
        className="tooltip"
      >
        {icon}
        {!!content && isVisible && (
          <div
            className={clsx(
              'tooltip-content whitespace-nowrap text-sm-regular text-base-1100',
              position
            )}
          >
            {content}
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div
        onMouseEnter={toggleVisibility}
        onMouseLeave={toggleVisibility}
        className="tooltip"
      >
        {children}
        {!!content && isVisible && (
          <div
            className={clsx(
              'tooltip-content whitespace-nowrap text-sm-regular text-base-1100',
              position
            )}
          >
            {content}
          </div>
        )}
      </div>
    </>
  );
}
