import { Pie } from 'react-chartjs-2';
import { convertToFloat } from '../../../../utils';
import CardHeader from '../../../commons/CardHeader';
import { Legend } from '../../../commons/inputs/SliderInput';

export default function ProductListInfo({ data }: { data: any }) {
  const chartLegend = [
    { key: 'seedVarieties', label: 'Seeding Totals', color: '#d5dce2' },
    {
      key: 'fertilizers',
      label: 'Fertilizer Totals',
      color: '#5c7284',
    },
    {
      key: 'chemicals',
      label: 'Chemical Totals',
      color: '#a3b4c2',
    },
    {
      key: 'micronutrients',
      label: 'Micronutrient Totals',
      color: '#323e48',
    },
  ];

  const parsedData = [
    data?.seedVarieties?.reduce(
      (acc: number, item: any) => acc + convertToFloat(item.total_cost),
      0
    ),
    data?.fertilizers?.reduce(
      (acc: number, item: any) => acc + convertToFloat(item.total_cost),
      0
    ),
    data?.chemicals?.reduce(
      (acc: number, item: any) => acc + convertToFloat(item.total_cost),
      0
    ),
    data?.micronutrients?.reduce(
      (acc: number, item: any) => acc + convertToFloat(item.total_cost),
      0
    ),
  ];

  const chartData = {
    labels: chartLegend.map((legend) => legend.label),
    datasets: [
      {
        label: 'Total cost',
        data: parsedData,
        backgroundColor: chartLegend.map((legend) => legend.color),
        borderWidth: 0,
        radius: '90%',
        hoverBackgroundColor: chartLegend.map((legend) => legend.color),
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="space-y-8">
      <CardHeader title="Product List" tooltip="tooltip" />
      <div>
        <h4 className="text-md-bold">Prescription Breakdown</h4>
        <p className="text-sm-regular text-base-500">
          Your prescription details.
        </p>
        <hr className="mt-5" />
      </div>

      <Pie data={chartData} options={chartOptions} />

      <div className="flex flex-col items-center space-y-5">
        <div className="grid grid-cols-4 gap-4">
          {chartLegend.map((legend, index) => (
            <span className="col-span-2">
              <Legend key={index} color={legend.color} label={legend.label} />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
