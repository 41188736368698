import { useQuery } from '@tanstack/react-query';
import { getFuturesContractsApi } from '../../api/contracts-api';
import { ContractRes } from '../../types';
import { futuresContracts } from '../../constants';
import moment from 'moment';

export const useFuturesContracts = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['contracts/futures'],
    queryFn: getFuturesContractsApi,
  });

  return { data: data?.data as ContractRes[] | [], ...rest };
};

// helpers
export const getPriceOptions = (cropName: string, year: string | undefined) => {
  if (!cropName || !year) return [];

  const contracts = (futuresContracts as any)[cropName]?.[year] || [];

  return contracts.filter((contract: { endDate?: string }) =>
    contract?.endDate ? moment().isBefore(moment(contract.endDate)) : false
  );
};
