import { useId, useMemo } from 'react';
import { getFarmRevenuesApi } from '../../api/farm-api';
import { NETWORK_PARTNER } from '../../constants';
import {
  useIncomeViewFilter,
  useYearFilter,
} from '../../contexts/app-filter-context';
import { useControlledUser, useUser } from '../../contexts/auth-context';
import { FarmRevenueRes } from '../../types';
import { convertToFloat } from '../../utils';
import { useDepsQuery } from '../use-deps-query';
import { useWebSocketDataV2 } from '../websocket/use-websocket-data';

export const useFarmRevenues = (userId: string, year: string) => {
  const { data, ...rest } = useDepsQuery({
    queryKey: ['farm/revenues', userId, year],
    queryFn: () => getFarmRevenuesApi(userId, year),
    enabled: !!userId && !!year,
    deps: [useId, year],
  });

  return {
    data: data?.data as FarmRevenueRes[] | [],
    ...rest,
  };
};

// helpers
export const getTotalRevenue = (data?: FarmRevenueRes[]) => {
  const totalRevenue: { total: number; change: number } = data?.reduce(
    (acc, revenue: FarmRevenueRes) => {
      return {
        total: acc.total + convertToFloat(revenue.revenue),
        change: acc.change + convertToFloat(revenue.revenue_chg),
      };
    },
    { total: 0, change: 0 }
  ) ?? {
    total: 0,
    change: 0,
  };

  return totalRevenue;
};

export const getTotalNetIncome = (data?: FarmRevenueRes[]) => {
  const totalNetIncome: { total: number; change: number } = data?.reduce(
    (acc, revenue: FarmRevenueRes) => {
      return {
        total: acc.total + convertToFloat(revenue.net_income),
        change: acc.change + convertToFloat(revenue.net_income_chg),
      };
    },
    { total: 0, change: 0 }
  ) ?? {
    total: 0,
    change: 0,
  };

  return totalNetIncome;
};

export const getTotalRevenueByCrop = (data?: FarmRevenueRes[]) => {
  const totalRevenue =
    data?.reduce(
      (acc, revenue: FarmRevenueRes) => {
        const cropName = revenue?.user_id_crop.split('-')[1];
        if (!acc[cropName]) {
          acc[cropName] = {
            total: convertToFloat(revenue.revenue),
            change: convertToFloat(revenue.revenue_chg),
            totalPerAcre: convertToFloat(revenue.revenue_per_acre),
            changePerAcre: convertToFloat(revenue.revenue_per_acre_chg),
          };
        } else {
          acc[cropName].total += convertToFloat(revenue.revenue);
          acc[cropName].change += convertToFloat(revenue.revenue_chg);
          acc[cropName].totalPerAcre = convertToFloat(revenue.revenue_per_acre);
          acc[cropName].changePerAcre = convertToFloat(
            revenue.revenue_per_acre_chg
          );
        }
        return acc;
      },
      {} as Record<
        string,
        {
          total: number;
          change: number;
          totalPerAcre: number;
          changePerAcre: number;
        }
      >
    ) ?? {};

  return totalRevenue;
};

export const getTotalNetIncomeByCrop = (data?: FarmRevenueRes[]) => {
  const totalNetIncome =
    data?.reduce(
      (acc, revenue: FarmRevenueRes) => {
        const cropName = revenue?.user_id_crop.split('-')[1];
        if (!acc[cropName]) {
          acc[cropName] = {
            total: convertToFloat(revenue.net_income),
            change: convertToFloat(revenue.net_income_chg),
            totalPerAcre: convertToFloat(revenue.net_income_per_acre),
            changePerAcre: convertToFloat(revenue.net_income_per_acre_chg),
          };
        }
        acc[cropName].total += convertToFloat(revenue.net_income);
        acc[cropName].change += convertToFloat(revenue.net_income_chg);
        acc[cropName].totalPerAcre = convertToFloat(
          revenue.net_income_per_acre
        );
        acc[cropName].changePerAcre = convertToFloat(
          revenue.net_income_per_acre_chg
        );
        return acc;
      },
      {} as Record<
        string,
        {
          total: number;
          change: number;
          totalPerAcre: number;
          changePerAcre: number;
        }
      >
    ) ?? {};

  return totalNetIncome;
};

export const getHVAR = (data?: FarmRevenueRes[]) => {
  const hvar =
    data?.reduce(
      (acc, revenue: FarmRevenueRes) => {
        return {
          total: acc.total + convertToFloat(revenue.hvar),
          change: acc.change + convertToFloat(revenue.hvar_chg_1day),
        };
      },
      {
        total: 0,
        change: 0,
      }
    ) ?? ({} as { total: number; change: number });

  return hvar;
};

export const getNHVAR = (data?: FarmRevenueRes[]) => {
  const netHVAR =
    data?.reduce(
      (acc, revenue: FarmRevenueRes) => {
        return {
          total: acc.total + convertToFloat(revenue.nhvar),
          change: acc.change + convertToFloat(revenue.nhvar_chg_1day),
        };
      },
      {
        total: 0,
        change: 0,
      }
    ) ?? ({} as { total: number; change: number });

  return netHVAR;
};

export const getVAR = (data?: FarmRevenueRes[]) => {
  const vAR =
    data?.reduce(
      (acc, revenue: FarmRevenueRes) => {
        return {
          total: acc.total + convertToFloat(revenue.var),
          change: acc.change + convertToFloat(revenue.var_chg_1day),
        };
      },
      {
        total: 0,
        change: 0,
      }
    ) ?? ({} as { total: number; change: number });

  return vAR;
};

export const getNRVAR = (data?: FarmRevenueRes[]) => {
  const nRVAR =
    data?.reduce((acc, revenue: FarmRevenueRes) => {
      return acc + convertToFloat(revenue.nrvar);
    }, 0) ?? 0;

  return nRVAR;
};

export const getHedgedFuturePriceByCrop = (farmRevenue: FarmRevenueRes[]) => {
  if (!farmRevenue) return {};
  return farmRevenue?.reduce((acc: any, revenue: FarmRevenueRes) => {
    const crop = revenue?.user_id_crop.split('-')[1];
    if (acc[crop]) {
      acc[crop].total += convertToFloat(revenue.hedged_price);
    } else {
      acc[crop] = {
        total: convertToFloat(revenue.hedged_price),
        percent: convertToFloat(revenue.percent_hedged),
      };
    }
    return acc;
  }, {});
};

export const useVARChartData = () => {
  const user = useUser();
  const controlledUser = useControlledUser();
  const year = useYearFilter();
  const incomeView = useIncomeViewFilter();
  const { data } = useFarmRevenues(user?.id || '', year || '');

  const chartColors = useMemo(() => {
    console.log(controlledUser?.network_partner);

    switch (controlledUser?.network_partner) {
      case NETWORK_PARTNER.heartland:
        return {
          needleColor: 'rgba(155, 0, 43, 1)',
          centerColor: '#fafafa',
          stepColor: '#838386',
          firstColor: '#d0d0d1',
          secondColor: 'rgba(0, 129, 93, 1)',
          thirdColor: 'rgba(155, 0, 43, 1)',
          fourthColor: 'rgba(108, 109, 111, 1)',
          dotColor1: 'rgba(211, 211, 213, 1)',
          dotColor2: 'rgba(133, 134, 138, 1)',
          dotBorderColor1: 'rgba(159, 160, 163, 1)',
          dotBorderColor2: 'rgba(133, 134, 138, 1)',
        };
      default:
        return {
          needleColor: 'rgba(255, 183, 27, 1)',
          centerColor: '#1b2227',
          stepColor: '#fff',
          firstColor: 'rgba(255, 183, 27, 1)',
          secondColor: 'rgba(128, 188, 0, 1)',
          thirdColor: 'rgba(244, 39, 39, 1)',
          fourthColor: 'rgba(92, 114, 132, 1)',
          dotColor1: 'rgba(255, 183, 27, 1)',
          dotColor2: 'rgba(255, 153, 0, 1)',
          dotBorderColor1: 'rgba(216, 120, 7, 1)',
          dotBorderColor2: 'rgba(241, 160, 19, 1)',
        };
    }
  }, [controlledUser?.network_partner]);

  const msgRevenue: any = useWebSocketDataV2('Farm_Revenue');

  const farmRevenueData = useMemo(() => {
    if (!data) return [];
    return data.map((revenue) => {
      if (
        msgRevenue &&
        msgRevenue.data?.user_id_crop === revenue?.user_id_crop
      ) {
        return {
          ...revenue,
          var: msgRevenue.data.var || revenue.var,
          var_chg_1day: msgRevenue.data.var_chg_1day || revenue.var_chg_1day,
          hvar: msgRevenue.data.hvar || revenue.hvar,
          hvar_chg_1day: msgRevenue.data.hvar_chg_1day || revenue.hvar_chg_1day,
          nhvar: msgRevenue.data.nhvar || revenue.nhvar,
          nhvar_chg_1day:
            msgRevenue.data.nhvar_chg_1day || revenue.nhvar_chg_1day,
          nrvar: msgRevenue.data.nrvar || revenue.nrvar,
        };
      }
      return revenue;
    });
  }, [msgRevenue, data]);

  const vAR = useMemo(() => getVAR(farmRevenueData), [farmRevenueData]);
  const hVAR = useMemo(() => getHVAR(farmRevenueData), [farmRevenueData]);
  const nHVAR = useMemo(() => getNHVAR(farmRevenueData), [farmRevenueData]);
  const nRVAR = useMemo(() => getNRVAR(farmRevenueData), [farmRevenueData]);

  // @TODO Paul you need to check this, i'm not sure if this is correct
  const varToShow = useMemo(
    () => (incomeView === 'revenue' ? hVAR : nHVAR),
    [incomeView, hVAR, nHVAR]
  );
  const hVARToShow = useMemo(
    () => (incomeView === 'revenue' ? hVAR.total : nHVAR.total),
    [incomeView, hVAR, nHVAR]
  );
  const riskReductionHedges = useMemo(
    () => hVAR.total - vAR.total,
    [hVAR, vAR]
  );
  const riskReductionInsurance = useMemo(() => 0, []);
  const priceRisk = useMemo(() => vAR.total - nRVAR, [vAR, nRVAR]);

  return {
    vAR,
    hVAR,
    nHVAR,
    nRVAR,
    varToShow,
    hVARToShow,
    riskReductionHedges,
    riskReductionInsurance,
    priceRisk,
    chartColors,
  };
};
