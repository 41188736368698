import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import SpinningIcon from '../../../../assets/icons/SpinningIcon';
import NumberDisplay from '../../../commons/NumberDisplay';
import IconButton from '../../../commons/inputs/IconButton';
import TextInput from '../../../commons/inputs/TextInput';
import { FormProvider, useForm } from 'react-hook-form';

interface InplaceEditNumberProps {
  name: string;
  onSave?: (value: number) => void;
  onCancel?: () => void;
  onChange?: (value: number) => void;
  defaultValue?: number;
  loading: boolean;
  min?: number;
  max?: number;
  className?: string;
  isEditing?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
}

export default function InplaceEditNumber({
  name,
  onSave,
  defaultValue = 0,
  className,
  loading,
  onChange,
  min,
  max,
  isEditing: isEditingProp,
  setIsEditing: setIsEditingProp,
}: InplaceEditNumberProps) {
  const [value, setValue] = useState<number>(defaultValue ?? 0);
  const [isEditing, setIsEditing] = useState(false);

  const form = useForm({
    defaultValues: {
      [name]: value,
    },
  });

  useEffect(() => {
    if (isEditingProp !== undefined) {
      setIsEditing(isEditingProp);
    }
  }, [isEditingProp]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (min || max) {
      const num = Number(e.target.value);
      if (Number(min) <= num && num <= Number(max)) {
        setValue(Number(e.target.value));
        onChange?.(Number(e.target.value));
      } else if (num < Number(min)) {
        setValue(Number(min));
        onChange?.(Number(min));
      } else if (num > Number(max)) {
        setValue(Number(max));
        onChange?.(Number(max));
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsEditingProp?.(false);
    setValue(defaultValue ?? '');
  };

  const handleSave = () => {
    onSave?.(value);
    setIsEditing(false);
  };

  const handleOpenEdit = () => {
    if (loading) return;
    setIsEditing(true);
  };

  return (
    <>
      {isEditing ? (
        <FormProvider {...form}>
          <form>
            <div className="flex w-full space-x-2 items-center">
              <TextInput
                name={name}
                type={'number'}
                onChange={handleOnChange}
                value={value}
                autoFocus
              />
              <div className="flex space-x-1">
                <IconButton onClick={handleCancel}>
                  <XMarkIcon className="size-3" />
                </IconButton>
                <IconButton onClick={handleSave}>
                  <CheckIcon className="size-3" />
                </IconButton>
              </div>
            </div>
          </form>
        </FormProvider>
      ) : (
        <div
          className={clsx('flex items-center relative')}
          onClick={handleOpenEdit}
          aria-disabled={loading}
        >
          <NumberDisplay
            value={Number(value)}
            className={clsx(className, { 'animate-pulse': loading })}
          />
          <span className="mr-1"></span>
        </div>
      )}
    </>
  );
}
