import { Navigate } from 'react-router-dom';
import AccessControl from '../../components/routes/AccessControl';
import { PERMISSIONS } from '../../constants';
import Map from '../../pages/dashboard/map';

const mapRoutes = [
  {
    path: 'map',
    element: (
      <AccessControl permissions={[PERMISSIONS.MAPS_ACCESS]} />
    ),
    children: [
      {
        // default route
        index: true,
        element: <Navigate to="revenue" />,
      },
      { path: ':type', element: <Map /> },
    ],
  },
];

export default mapRoutes;
