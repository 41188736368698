import { GridCellLayer } from 'deck.gl';
import { useEffect, useState } from 'react';
import * as turf from '@turf/turf';

export const useLayers = ({ wData, type, enabled }: { wData: any; type: string, enabled: boolean }) => {
  const [layers, setLayers] = useState<any[]>([]);
  const [hoverInfo, setHoverInfo] = useState<any>();

  useEffect(() => {
    if (wData) {
      const dataChunks = wData.map((data: any) => {
        const length = data['lon'] ? data['lon'].length : 0;
        if (length === 0) return {};

        const formattedChunk = {
          value: data['value'],
          lons: data['lon'],
          lats: data['lat'],
          reds: data['r'],
          blues: data['b'],
          greens: data['g'],
          length: length,
          type,
        };

        return formattedChunk;
      });
      const layers = dataChunks.map(
        (chunk: any, chunkIndex: any) =>
          new GridCellLayer({
            id: `weather-${type}_tab-${chunkIndex}`,
            data: chunk,
            cellSize: 1000,
            extruded: false,
            // @ts-ignore
            getPosition: (
              object,
              {
                index,
                data,
                target,
              }: { index: number; data: any; target: number[] }
            ) => {
              target[0] = data.lons[index];
              target[1] = data.lats[index];
              //   target[2] = 1 // 0 elev
              return target;
            },
            // @ts-ignore
            getFillColor: (object, { index, data, target } : { index: number; data: any; target: number[] }) => {
              // @ts-ignore
              target[0] = data.reds[index];
              // @ts-ignore
              target[1] = data.greens[index];
              // @ts-ignore
              target[2] = data.blues[index];

              if (!enabled || data.value[index] < 0) {
                target[3] = 0;
              }
              return target;
            },

            pickable: true,
            onHover: (info) => setHoverInfo(info),
            opacity: 0.8,
          })
      );
      setLayers(layers);
    }
  }, [wData, type, enabled]);

  return {
    layers,
    hoverInfo,
  };
};
