import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  exportPrescriptionApi,
  exportPrescriptionApiV2,
} from '../../../api/export-api';
import { SpinningIcon } from '../../../assets/icons';
import {
  useMainUser,
  useUser,
} from '../../../contexts/auth-context';
import Button from '../../commons/Button';
import ComboSelectInput from '../../commons/inputs/ComboSelectInput';
import Menu from '../../commons/menu/Menu';
import Modal from '../../commons/modal';
import Select from '../../commons/Select';

const handleDownload = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function ExportButton({
  userIdYear,
  crop,
  fieldIndex,
}: {
  crop: string;
  fieldIndex: string;
  userIdYear: string;
}) {
  const user = useUser();
  const [mainOption, setMainOption] = useState<string | undefined>();
  const [subOptions, setSubOptions] = useState<
    | {
        value: string;
        label: string;
        onClick?: () => void;
      }[]
    | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [notiModal, setNotiModal] = useState(false);

  // Form
  const mainUser = useMainUser();
  const controlledUser = useUser();
  const validation = yup.object().shape({
    email: yup.string().email().required().label('Email'),
    subOption: yup.string().required().label('Sub Option'),
  });
  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validation),
  });

  const isEmailExport = mainOption?.includes('Email');
  const isAdminAdvisor = mainUser?.is_admin || mainUser?.is_advisor_client;

  const handleExport = async ({
    fileType,
    convertFunction,
    download = false,
    partner = null,
  }: {
    fileType: string;
    convertFunction?: string;
    download?: boolean;
    partner?: string | null;
  }) => {
    try {
      setLoading(true);
      const { data: presignedUrl } = await exportPrescriptionApi({
        fileType: fileType,
        userIdYear: userIdYear,
        crop: crop,
        fieldIndex: fieldIndex,
        userEmail: form.watch('email') ?? user?.email ?? '',
        convertFunction,
        partner,
      });
      enqueueSnackbar(`Prescription has been exported successfully`, {
        variant: 'success',
      });
      download && handleDownload(presignedUrl);
      setSubOptions(undefined);
      setMainOption(undefined);
      return presignedUrl;
    } catch {
      enqueueSnackbar('Failed to export prescription', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleExportV2 = async ({
    fileType,
    partner,
    url,
  }: {
    fileType: string;
    partner: string;
    url: string;
  }) => {
    try {
      setLoading(true);
      const { data: presignedUrl } = await exportPrescriptionApiV2({
        fileType: fileType,
        partner: partner,
        userIdYear: userIdYear,
        crop: crop,
        fieldIndex: fieldIndex,
        userEmail: user?.email ?? '',
        url: url,
      });
      enqueueSnackbar(
        `${mainOption} ${fileType} Prescription has been exported successfully`,
        {
          variant: 'success',
        }
      );
      setSubOptions(undefined);
      // handleDownload(presignedUrl);
    } catch {
      enqueueSnackbar('Failed to export prescription', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const getPartnerExportSeedingOptions = (partner: string) => {
    return [
      {
        value: 'seeds per acre',
        label: 'Seeds per Acre',
        onClick: async () => {
          const presigned_url = await handleExport({
            fileType: 'Seeding',
            convertFunction: 'seeds per acre',
            partner,
          });
          await handleExportV2({
            fileType: 'Seeding',
            partner,
            url: presigned_url,
          });
        },
      },
      {
        value: 'lbs per acre',
        label: 'Pounds per Acre',
        onClick: async () => {
          const presigned_url = await handleExport({
            fileType: 'Seeding',
            convertFunction: 'lbs per acre',
            partner,
          });
          await handleExportV2({
            fileType: 'Seeding',
            partner,
            url: presigned_url,
          });
        },
      },
    ];
  };

  const mainOptions = [
    {
      value: 'Seeding',
      label: 'Seeding',
      onClick: () => {
        setMainOption('Seeding');
        setSubOptions([
          {
            value: 'seeds per acre',
            label: 'Seeds per Acre',
            onClick: async () => {
              await handleExport({
                fileType: 'Seeding',
                convertFunction: 'seeds per acre',
                download: true,
              });
            },
          },
          {
            value: 'lbs per acre',
            label: 'Pounds per Acre',
            onClick: async () => {
              await handleExport({
                fileType: 'Seeding',
                convertFunction: 'lbs per acre',
                download: true,
              });
            },
          },
        ]);
      },
    },
    {
      value: 'Fertilizer',
      label: 'Fertilizer',
      onClick: async () => {
        setMainOption('Fertilizer');
        await handleExport({
          fileType: 'Fertilizer',
          download: true,
        });
      },
    },
    {
      value: 'Email Seeding',
      label: 'Email me a link to my seeding prescription file',
      onClick: async () => {
        setMainOption('Email Seeding');
        setSubOptions([
          {
            value: 'seeds per acre',
            label: 'Seeds per Acre',
            onClick: async () => {
              await handleExport({
                fileType: 'Email Seeding',
                convertFunction: 'seeds per acre',
              });
            },
          },
          {
            value: 'lbs per acre',
            label: 'Pounds per Acre',
            onClick: async () => {
              await handleExport({
                fileType: 'Email Seeding',
                convertFunction: 'lbs per acre',
              });
            },
          },
        ]);
      },
    },
    {
      value: 'Email Fertilizer',
      label: 'Email me a link to my fertilizer prescription file',
      onClick: async () => {
        setMainOption('Email Fertilizer');
        if (isAdminAdvisor) {
          setSubOptions([
            {
              value: 'Email Fertilizer',
              label: 'Email Fertilizer',
              onClick: async () => {
                await handleExport({
                  fileType: 'Email Fertilizer',
                  download: false,
                });
              },
            },
          ]);
        } else {
          await handleExport({
            fileType: 'Fertilizer',
            download: false,
          });
        }
      },
    },
    {
      value: 'JD',
      label: 'JD Operations Center',
      onClick: () => {
        setMainOption('JD');
        setSubOptions([
          {
            value: 'jd_seeding',
            label: 'Seeding',
          },
          {
            value: 'jd_fertilizer',
            label: 'Fertilizer',
            onClick: async () => {
              const presigned_url = await handleExport({
                fileType: 'Fertilizer',
                download: false,
              });
              await handleExportV2({
                fileType: 'Fertilizer',
                partner: 'jd-operations',
                url: presigned_url,
              });
            },
          },
        ]);
      },
    },
    {
      value: 'Climate',
      label: 'Climate',
      onClick: () => {
        setMainOption('Climate');
        setSubOptions([
          {
            value: 'climate_seeding',
            label: 'Seeding',
          },
          {
            value: 'climate_fertilizer',
            label: 'Fertilizer',
            onClick: async () => {
              const presigned_url = await handleExport({
                fileType: 'Fertilizer',
                partner: 'Climate',
              });
              await handleExportV2({
                fileType: 'Fertilizer',
                partner: 'climate',
                url: presigned_url,
              });
            },
          },
        ]);
      },
    },
  ];

  const subOption = form.watch('subOption');
  const partner = mainOption === 'JD' ? 'jd-operations' : 'climate';

  return (
    <div className="card">
      <Menu
        items={mainOptions}
        buttonClassName="text-xl-bold px-5 py-3 flex justify-center items-center gap-2"
        disabled={loading || !user || !userIdYear || !crop || !fieldIndex}
      >
        {loading && <SpinningIcon />}
        <p>Export Prescription</p>
      </Menu>

      {/* Export options Modal */}
      <Modal
        size="sm"
        onClose={{
          handler: () => {
            setSubOptions(undefined);
          },
          btnText: 'Cancel',
        }}
        title={`Export ${mainOption} Prescription`}
        open={!!subOptions}
      >
        {isEmailExport && isAdminAdvisor && (
          <FormProvider {...form}>
            <form>
              <ComboSelectInput
                label="Email"
                name="email"
                options={[
                  {
                    value: mainUser?.email ?? '',
                    label: mainUser?.email ?? '',
                  },
                  {
                    value: controlledUser?.email ?? '',
                    label: controlledUser?.email ?? '',
                  },
                ]}
              />
            </form>
          </FormProvider>
        )}
        {subOptions?.length === 1 && (
          <Button
            onClick={subOptions[0].onClick}
            type="button"
            loading={loading}
            className="mt-2"
            disabled={
              loading ||
              (isEmailExport && isAdminAdvisor && !form.formState.isValid)
            }
          >
            {subOptions[0].label}
          </Button>
        )}
        {(subOptions ?? []).length > 1 && (
          <>
            {mainOption && ['JD', 'Climate'].includes(mainOption) ? (
              <>
                <FormProvider {...form}>
                  <form>
                    <Select
                      label=""
                      name="subOption"
                      options={
                        subOptions?.map((option) => ({
                          value: option.value,
                          label: option.label,
                        })) || []
                      }
                    />
                  </form>
                </FormProvider>
                {['jd_seeding', 'climate_seeding'].includes(
                  form.watch('subOption')
                ) ? (
                  <Menu
                    items={getPartnerExportSeedingOptions(partner)}
                    anchor="bottom end"
                    buttonClassName="p-2 border border-base-900 rounded flex justify-center items-center gap-2 mt-2"
                    disabled={
                      loading ||
                      (isEmailExport &&
                        isAdminAdvisor &&
                        !form.formState.isValid)
                    }
                  >
                    {loading && <SpinningIcon />}
                    <p>Select export option</p>
                  </Menu>
                ) : (
                  <Button
                    onClick={() => {
                      subOptions
                        ?.find((option) => option.value === subOption)
                        ?.onClick?.();
                    }}
                    type="button"
                    loading={loading}
                    className="mt-2"
                    disabled={
                      loading ||
                      (isEmailExport &&
                        isAdminAdvisor &&
                        !form.formState.isValid)
                    }
                  >
                    Export Fertilizer
                  </Button>
                )}
              </>
            ) : (
              <Menu
                items={subOptions ?? []}
                anchor="bottom end"
                buttonClassName="p-2 border border-base-900 rounded flex justify-center items-center gap-2 mt-2"
                disabled={
                  loading ||
                  (isEmailExport && isAdminAdvisor && !form.formState.isValid)
                }
              >
                {loading && <SpinningIcon />}
                <p>Select export option</p>
              </Menu>
            )}
          </>
        )}
      </Modal>

      {/* Noti modal */}
      <Modal
        size="sm"
        onClose={{
          handler: () => {
            setNotiModal(false);
          },
          btnText: 'Close',
        }}
        title={`Export ${mainOption} Prescription`}
        open={notiModal}
      >
        <p>
          An email containing the link to the prescription file has been sent to
          you.
        </p>
      </Modal>
    </div>
  );
}
