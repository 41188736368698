// import Button from '../../../components/commons/Button';
// import CardHeader from '../../../components/commons/CardHeader';
import { DisclosureButton } from '@headlessui/react';
import Disclosure from '../../../components/commons/Disclosure';
import AddFarmExpenseForm from '../../../components/data-inputs/farm-expenses/AddFarmExpenseForm';
import FarmExpenseList from '../../../components/data-inputs/farm-expenses/FarmExpenseList';
import { ChevronDownIcon, ChevronUpIcon, ClipboardIcon } from '@heroicons/react/24/solid';
import Table, { TableConfigs } from '../../../components/commons/table/Table';
import { useUser } from '../../../contexts/auth-context';
import { useExpenseLog } from '../../../hooks/expense/use-expense';

export default function FarmExpenses() {


  const logConfigs: TableConfigs = {
    cols: [
      {
        key: 'user',
        name: 'user_id',
      },
      {
        key: 'expense',
        name: 'expense',
      },
      {
        key: 'date',
        name: 'date',
      },
    ],
  }

  const user = useUser();

  const {data: tableData} = useExpenseLog(user?.id ?? '');  

  console.log('expense log', tableData);


  const expenseLogTableConfig : TableConfigs = {
    cols: [
      {
        key: 'username',
        name: 'User',
      },
      {
        key: 'id',
        name: 'Expense',
      },
      {
        key: 'updated',
        name: 'Updated Category',
      },
      {
        key: 'date',
        name: 'Date',
        type: 'element',
        element: (item) => <p>{item.date.split(' ')[0]}</p>
      },
    ]
  }

  return (
    <div className="sm:grid sm:grid-cols-12 gap-8">
      <div className="col-span-3 space-y-8">
        <div className="card !rounded-lg p-4">
          <AddFarmExpenseForm />
        </div>

        {/* <div className="card !rounded-lg p-4 space-y-6">
          <CardHeader title="View Saved Farm Expense Items" />
          <p>
            Return to previously saved farm expense items that have not yet been
            submitted.
          </p>
          <Button color="primary">View Saved Farm Expense Items</Button>
        </div> */}
      </div>

      <div className="card !rounded-lg col-span-9 overflow-hidden h-fit">
        <FarmExpenseList />
      </div>
      <div className="w-full col-span-12 justify-end mt-8 card p-0">
          <Table
            title={{name: 'Expense Log'}}
            data={tableData}
            configs={expenseLogTableConfig}
          />
          </div>
    </div>
  );
}
