import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { uploadFileApi } from '../../api/file-upload-api';

export const useUploadPresignedUrl = () => {
  const { data, ...rest } = useMutation({
    mutationKey: ['uploadFile'],
    mutationFn: (fileName: string) => uploadFileApi(fileName),
  });
  return {
    presignUrl: data?.data?.presigned_url,
    ...rest,
  };
};

export const useUploadFile = () => {
  const {
    mutate: getPresignedUrl,
    isPending: pendingGetPresignedUrl,
    ...rest
  } = useUploadPresignedUrl();
  const [uploading, setUploading] = useState(false);

  const uploadFile = async (
    file: File,
    fileName: string,
    callback: (fileName: string) => void
  ) => {
    getPresignedUrl(fileName, {
      onSuccess: async (data) => {
        setUploading(true);
        const presignedUrl = data?.data?.presigned_url;
        const response = await fetch(presignedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });
        if (response.ok) {
          callback(fileName);
          enqueueSnackbar('File uploaded successfully', { variant: 'success' });
        } else {
          enqueueSnackbar('File upload failed', { variant: 'error' });
        }
        setUploading(false);
      },
    });
  };
  return {
    uploadFile,
    ...rest,
    isPending: pendingGetPresignedUrl || uploading,
  };
};
