/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Button from '../../../components/commons/Button';
import Checkbox from '../../../components/commons/Checkbox';
import StyledCard from '../../../components/main/StyledCard';
import { PERMISSIONS } from '../../../constants';

const permissions = [
  {
    key: 'full',
    title: 'Full Access (all pages)',
  },
  {
    key: 'dashboard',
    title: 'Dashboard',
  },
  {
    key: 'maps',
    title: 'Maps Page',
  },
  {
    key: 'risk_management',
    title: 'Risk Management Page',
  },
  {
    key: 'weather',
    title: 'Weather Page',
  },
  {
    key: 'prescriptions',
    title: 'Prescriptions Page',
  },
  {
    key: 'historical_insights',
    title: 'Historical Insights Page',
  },
  {
    key: 'markets',
    title: 'Markets Page',
  },
  {
    key: 'invoice',
    title: 'Invoice Page',
  },
  { key: 'inbound_grain_tickets', title: 'Inbound Grain Tickets Page' },
  {
    key: 'data_input',
    title: 'Data Inputs Portal',
    children: [
      {
        key: 'full',
        title: 'Full Access (all pages)',
      },
      {
        key: 'overview',
        title: 'Overview',
      },
      {
        key: 'farm_expenses',
        title: 'Farm Expenses',
      },
      {
        key: 'crop_insurance',
        title: 'Crop Insurances',
      },
      {
        key: 'budgets',
        title: 'Budgets',
      },
      {
        key: 'soil_samples',
        title: 'Soil Samples',
      },
      {
        key: 'aph_histories',
        title: 'APH Histories',
      },
      {
        key: 'partner_connections',
        title: 'Partner Connections',
      },
      {
        key: 'field_boundaries',
        title: 'Field Boundaries and Info',
      },
    ],
  },
];
export function PermissionsTable({
  form,
}: {
  form: UseFormReturn<any, any, undefined>;
}) {
  const fullAccess = form.watch(PERMISSIONS.FULL_ACCESS);
  const fullDataAggregation = form.watch(PERMISSIONS.FULL_DATA_AGGREGATION);
  const dataInputFullAccess = form.watch(PERMISSIONS.DATA_INPUT_FULL_ACCESS);
  const dataInputFullDataAggregation = form.watch(
    PERMISSIONS.DATA_INPUT_FULL_DATA_AGGREGATION
  );

  useEffect(() => {
    if (fullAccess === true) {
      form.setValue(PERMISSIONS.DASHBOARD_ACCESS, true);
      form.setValue(PERMISSIONS.MAPS_ACCESS, true);
      form.setValue(PERMISSIONS.RISK_MANAGEMENT_ACCESS, true);
      form.setValue(PERMISSIONS.WEATHER_ACCESS, true);
      form.setValue(PERMISSIONS.PRESCRIPTIONS_ACCESS, true);
      form.setValue(PERMISSIONS.HISTORICAL_INSIGHTS_ACCESS, true);
      form.setValue(PERMISSIONS.MARKETS_ACCESS, true);
    }
  }, [fullAccess]);

  // if any of access is unchecked, uncheck full access
  useEffect(() => {
    if (
      !form.watch(PERMISSIONS.DASHBOARD_ACCESS) ||
      !form.watch(PERMISSIONS.MAPS_ACCESS) ||
      !form.watch(PERMISSIONS.RISK_MANAGEMENT_ACCESS) ||
      !form.watch(PERMISSIONS.WEATHER_ACCESS) ||
      !form.watch(PERMISSIONS.PRESCRIPTIONS_ACCESS) ||
      !form.watch(PERMISSIONS.HISTORICAL_INSIGHTS_ACCESS) ||
      !form.watch(PERMISSIONS.MARKETS_ACCESS)
    ) {
      form.setValue(PERMISSIONS.FULL_ACCESS, false);
    }
  }, [
    form.watch(PERMISSIONS.DASHBOARD_ACCESS),
    form.watch(PERMISSIONS.MAPS_ACCESS),
    form.watch(PERMISSIONS.RISK_MANAGEMENT_ACCESS),
    form.watch(PERMISSIONS.WEATHER_ACCESS),
    form.watch(PERMISSIONS.PRESCRIPTIONS_ACCESS),
    form.watch(PERMISSIONS.HISTORICAL_INSIGHTS_ACCESS),
    form.watch(PERMISSIONS.MARKETS_ACCESS),
  ]);

  useEffect(() => {
    if (fullDataAggregation === true) {
      form.setValue(PERMISSIONS.DASHBOARD_DATA_AGGREGATION, true);
      form.setValue(PERMISSIONS.MAPS_DATA_AGGREGATION, true);
      form.setValue(PERMISSIONS.RISK_MANAGEMENT_DATA_AGGREGATION, true);
      form.setValue(PERMISSIONS.WEATHER_DATA_AGGREGATION, true);
      form.setValue(PERMISSIONS.PRESCRIPTIONS_DATA_AGGREGATION, true);
      form.setValue(PERMISSIONS.HISTORICAL_INSIGHTS_DATA_AGGREGATION, true);
      form.setValue(PERMISSIONS.MARKETS_DATA_AGGREGATION, true);
    }
  }, [fullDataAggregation]);

  // if any of data aggregation is unchecked, uncheck full data aggregation
  useEffect(() => {
    if (
      !form.watch(PERMISSIONS.DASHBOARD_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.MAPS_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.RISK_MANAGEMENT_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.WEATHER_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.PRESCRIPTIONS_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.HISTORICAL_INSIGHTS_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.MARKETS_DATA_AGGREGATION)
    ) {
      form.setValue(PERMISSIONS.FULL_DATA_AGGREGATION, false);
    }
  }, [
    form.watch(PERMISSIONS.DASHBOARD_DATA_AGGREGATION),
    form.watch(PERMISSIONS.MAPS_DATA_AGGREGATION),
    form.watch(PERMISSIONS.RISK_MANAGEMENT_DATA_AGGREGATION),
    form.watch(PERMISSIONS.WEATHER_DATA_AGGREGATION),
    form.watch(PERMISSIONS.PRESCRIPTIONS_DATA_AGGREGATION),
    form.watch(PERMISSIONS.HISTORICAL_INSIGHTS_DATA_AGGREGATION),
    form.watch(PERMISSIONS.MARKETS_DATA_AGGREGATION),
  ]);

  useEffect(() => {
    if (dataInputFullAccess === true) {
      form.setValue(PERMISSIONS.DATA_INPUT_OVERVIEW_ACCESS, true);
      form.setValue(PERMISSIONS.DATA_INPUT_FARM_EXPENSES_ACCESS, true);
      form.setValue(PERMISSIONS.DATA_INPUT_CROP_INSURANCE_ACCESS, true);
      form.setValue(PERMISSIONS.DATA_INPUT_BUDGETS_ACCESS, true);
      form.setValue(PERMISSIONS.DATA_INPUT_SOIL_SAMPLES_ACCESS, true);
      form.setValue(PERMISSIONS.DATA_INPUT_APH_HISTORIES_ACCESS, true);
      form.setValue(PERMISSIONS.DATA_INPUT_PARTNER_CONNECTIONS_ACCESS, true);
      form.setValue(PERMISSIONS.DATA_INPUT_FIELD_BOUNDARIES_ACCESS, true);
    }
  }, [dataInputFullAccess]);

  // if any of data input access is unchecked, uncheck full data input access
  useEffect(() => {
    if (
      !form.watch(PERMISSIONS.DATA_INPUT_OVERVIEW_ACCESS) ||
      !form.watch(PERMISSIONS.DATA_INPUT_FARM_EXPENSES_ACCESS) ||
      !form.watch(PERMISSIONS.DATA_INPUT_CROP_INSURANCE_ACCESS) ||
      !form.watch(PERMISSIONS.DATA_INPUT_BUDGETS_ACCESS) ||
      !form.watch(PERMISSIONS.DATA_INPUT_SOIL_SAMPLES_ACCESS) ||
      !form.watch(PERMISSIONS.DATA_INPUT_APH_HISTORIES_ACCESS) ||
      !form.watch(PERMISSIONS.DATA_INPUT_PARTNER_CONNECTIONS_ACCESS) ||
      !form.watch(PERMISSIONS.DATA_INPUT_FIELD_BOUNDARIES_ACCESS)
    ) {
      form.setValue(PERMISSIONS.DATA_INPUT_FULL_ACCESS, false);
    }
  }, [
    form.watch(PERMISSIONS.DATA_INPUT_OVERVIEW_ACCESS),
    form.watch(PERMISSIONS.DATA_INPUT_FARM_EXPENSES_ACCESS),
    form.watch(PERMISSIONS.DATA_INPUT_CROP_INSURANCE_ACCESS),
    form.watch(PERMISSIONS.DATA_INPUT_BUDGETS_ACCESS),
    form.watch(PERMISSIONS.DATA_INPUT_SOIL_SAMPLES_ACCESS),
    form.watch(PERMISSIONS.DATA_INPUT_APH_HISTORIES_ACCESS),
    form.watch(PERMISSIONS.DATA_INPUT_PARTNER_CONNECTIONS_ACCESS),
    form.watch(PERMISSIONS.DATA_INPUT_FIELD_BOUNDARIES_ACCESS),
  ]);

  useEffect(() => {
    if (dataInputFullDataAggregation === true) {
      form.setValue(PERMISSIONS.DATA_INPUT_OVERVIEW_DATA_AGGREGATION, true);
      form.setValue(
        PERMISSIONS.DATA_INPUT_FARM_EXPENSES_DATA_AGGREGATION,
        true
      );
      form.setValue(
        PERMISSIONS.DATA_INPUT_CROP_INSURANCE_DATA_AGGREGATION,
        true
      );
      form.setValue(PERMISSIONS.DATA_INPUT_BUDGETS_DATA_AGGREGATION, true);
      form.setValue(PERMISSIONS.DATA_INPUT_SOIL_SAMPLES_DATA_AGGREGATION, true);
      form.setValue(
        PERMISSIONS.DATA_INPUT_APH_HISTORIES_DATA_AGGREGATION,
        true
      );
      form.setValue(
        PERMISSIONS.DATA_INPUT_PARTNER_CONNECTIONS_DATA_AGGREGATION,
        true
      );
      form.setValue(
        PERMISSIONS.DATA_INPUT_FIELD_BOUNDARIES_DATA_AGGREGATION,
        true
      );
    }
  }, [dataInputFullDataAggregation]);

  // if any of data input data aggregation is unchecked, uncheck full data input data aggregation
  useEffect(() => {
    if (
      !form.watch(PERMISSIONS.DATA_INPUT_OVERVIEW_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.DATA_INPUT_FARM_EXPENSES_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.DATA_INPUT_CROP_INSURANCE_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.DATA_INPUT_BUDGETS_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.DATA_INPUT_SOIL_SAMPLES_DATA_AGGREGATION) ||
      !form.watch(PERMISSIONS.DATA_INPUT_APH_HISTORIES_DATA_AGGREGATION) ||
      !form.watch(
        PERMISSIONS.DATA_INPUT_PARTNER_CONNECTIONS_DATA_AGGREGATION
      ) ||
      !form.watch(PERMISSIONS.DATA_INPUT_FIELD_BOUNDARIES_DATA_AGGREGATION)
    ) {
      form.setValue(PERMISSIONS.DATA_INPUT_FULL_DATA_AGGREGATION, false);
    }
  }, [
    form.watch(PERMISSIONS.DATA_INPUT_OVERVIEW_DATA_AGGREGATION),
    form.watch(PERMISSIONS.DATA_INPUT_FARM_EXPENSES_DATA_AGGREGATION),
    form.watch(PERMISSIONS.DATA_INPUT_CROP_INSURANCE_DATA_AGGREGATION),
    form.watch(PERMISSIONS.DATA_INPUT_BUDGETS_DATA_AGGREGATION),
    form.watch(PERMISSIONS.DATA_INPUT_SOIL_SAMPLES_DATA_AGGREGATION),
    form.watch(PERMISSIONS.DATA_INPUT_APH_HISTORIES_DATA_AGGREGATION),
    form.watch(PERMISSIONS.DATA_INPUT_PARTNER_CONNECTIONS_DATA_AGGREGATION),
    form.watch(PERMISSIONS.DATA_INPUT_FIELD_BOUNDARIES_DATA_AGGREGATION),
  ]);

  return (
    <table className="w-full mb-6">
      <thead>
        <tr className="bg-base-1100">
          <th className="rounded-tl-[10px] py-2"></th>
          <th className="py-2">Allow Advisor Access</th>
          <th className="rounded-tr-[10px] py-2">
            Allow Advisor Data Aggregation
          </th>
        </tr>
      </thead>
      <tbody>
        {permissions.map((permission, index) => (
          <tr key={index}>
            <td
              className={clsx('pl-8 py-2', {
                'pt-4': index === 0,
              })}
            >
              {permission.title}
            </td>
            {!permission.children && (
              <>
                <td
                  className={clsx('py-2', {
                    'pt-4': index === 0,
                  })}
                >
                  <span className="flex justify-center">
                    <Checkbox
                      rounded
                      // @ts-ignore
                      name={`${PERMISSIONS[permission.key.toUpperCase() + '_ACCESS']}`}
                    />
                  </span>
                </td>
                <td
                  className={clsx('py-2', {
                    'pt-4': index === 0,
                  })}
                >
                  <span className="flex justify-center">
                    <Checkbox
                      rounded
                      // @ts-ignore
                      name={`${PERMISSIONS[permission.key.toUpperCase() + '_DATA_AGGREGATION']}`}
                    />
                  </span>
                </td>
              </>
            )}
          </tr>
        ))}
        {permissions
          .filter((p) => p.children)
          .map((permission) =>
            permission.children?.map((child, index) => (
              <tr key={index}>
                <td className={clsx('pl-16 py-2')}>{child.title}</td>
                <td>
                  <span className="flex justify-center">
                    <Checkbox
                      rounded
                      // @ts-ignore
                      name={`${PERMISSIONS[permission.key.toUpperCase() + '_' + child.key.toUpperCase() + '_ACCESS']}`}
                    />
                  </span>
                </td>
                <td className="flex justify-center">
                  <span className="flex justify-center">
                    <Checkbox
                      rounded
                      // @ts-ignore
                      name={`${PERMISSIONS[permission.key.toUpperCase() + '_' + child.key.toUpperCase() + '_DATA_AGGREGATION']}`}
                    />
                  </span>
                </td>
              </tr>
            ))
          )}
      </tbody>
    </table>
  );
}

export default function DataSharingPermissions({
  form,
}: {
  form: UseFormReturn<any, any, undefined>;
}) {
  return (
    <div className="max-w-screen-lg mx-auto">
      <StyledCard
        title="Set Your Data Sharing Permissions"
        className="relative"
      >
        <div>
          <p className="text-center text-xl-regular mb-6">
            These permissions can always be changed later on at any point in the
            settings
          </p>

          <div className="card shadow-lg !rounded-[10px]">
            <PermissionsTable form={form} />
          </div>
        </div>
        <Button
          color="primary"
          size="lg"
          className="!rounded-md !font-normal !w-fit mx-auto mt-5"
          type="submit"
          loading={form.formState.isSubmitting}
        >
          Save
        </Button>
      </StyledCard>
    </div>
  );
}
