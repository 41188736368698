import { getFarmYieldApi } from '../../api/farm-api';
import { FarmYieldRes } from '../../types';
import { convertToFloat } from '../../utils';
import { useDepsQuery } from '../use-deps-query';

export const useFarmYield = (userId: string, year: string, date?: string) => {
  const { data, ...rest } = useDepsQuery({
    queryKey: ['farm/yield-v2', userId, year, date],
    queryFn: () => getFarmYieldApi(userId, year, date),
    enabled: !!userId && !!year,
    deps: [userId, year],
  });

  return {
    data: data?.data as FarmYieldRes[] | [],
    ...rest,
  };
};

// helpers
export const getAreaAndYield = (data?: FarmYieldRes[]) => {
  const result = data?.reduce(
    (acc, farmYield: FarmYieldRes) => {
      const totalYield =
        convertToFloat(farmYield.yield) + (acc.totalYield ?? 0);

      const yieldChange14 =
        convertToFloat(farmYield.yield_chg14) + (acc.yieldChange14 ?? 0);

      const yieldChange =
        convertToFloat(farmYield.yield_chg) + (acc.yieldChange ?? 0);

      const totalArea = convertToFloat(farmYield.area) + (acc.totalArea ?? 0);

      return {
        totalArea,
        totalYield,
        yieldChange,
        yieldChange14,
      };
    },
    {} as {
      totalArea: number;
      totalYield: number;
      yieldChange: number;
      yieldChange14: number;
    }
  ) ?? {
    totalArea: 0,
    totalYield: 0,
    yieldChange: 0,
    yieldChange14: 0,
  };

  return result;
};

export const getAreaAndYieldByCrop = (data?: FarmYieldRes[]) => {
  const result =
    data?.reduce(
      (acc, farmYield: FarmYieldRes) => {
        const crop = farmYield.crop;

        const totalYield =
          convertToFloat(farmYield.yield) + (acc[crop]?.totalYield ?? 0);

        const yieldChange14 =
          convertToFloat(farmYield.yield_chg14) +
          (acc[crop]?.yieldChange14 ?? 0);

        const yieldChange =
          convertToFloat(farmYield.yield_chg) + (acc[crop]?.yieldChange ?? 0);

        const totalArea =
          convertToFloat(farmYield.area) + (acc[crop]?.totalArea ?? 0);

        return {
          ...acc,
          [crop]: {
            totalArea,
            totalYield,
            yieldChange,
            yieldChange14,
          },
        };
      },
      {} as Record<
        string,
        {
          totalArea: number;
          totalYield: number;
          yieldChange: number;
          yieldChange14: number;
        }
      >
    ) ?? {};

  return result;
};
