import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Field,
  Label,
} from '@headlessui/react';
import clsx from 'clsx';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import TriangleDownIcon from '../../../assets/icons/TriangleDownIcon';

interface ComboSelectInputProps {
  options: {
    label: string;
    value: string;
  }[];
  name: string;
  className?: string;
  disabled?: boolean;
  label?: string;
  onChange?: (value: any) => void;
  value?: string;
  required?: boolean;
  defaultValue?: string;
  autoSelect?: boolean;
  endAdornment?: any;
}

export default function ComboSelectInput({
  options,
  name,
  className,
  disabled,
  label,
  onChange: onChangeProp,
  required = false,
  defaultValue: defaultValueProp,
  autoSelect = true,
  endAdornment,
  ...props
}: ComboSelectInputProps) {
  const [query, setQuery] = useState('');

  const filteredOptions = useMemo(() => {
    return query === ''
      ? options
      : options?.filter((op) => {
          return (
            typeof op?.label === 'string' &&
            op.label.toLowerCase().includes(query.toLowerCase())
          );
        }) ?? [];
  }, [query, JSON.stringify(options)]);

  const form = useFormContext();
  const error = get(form?.formState.errors, name);

  if (!form) {
    // In case you dont get the values by handleSubmit of react-hook-form
    // you forgot to wrap the form with <FormProvider> in the parent component
  }
  const defaultValue = useMemo(() => {
    const defaultValue =
      form?.getValues(name) ??
      form?.formState.defaultValues?.[name] ??
      defaultValueProp;
    if (defaultValue) {
      return options?.find((op) => op.value === defaultValue);
    }
    if (autoSelect) {
      return options?.[0];
    }
  }, [autoSelect, defaultValueProp, JSON.stringify(options)]);

  const { onChange, ...field } =
    form?.register(name, {
      ...props,
      required: {
        value: required,
        message: `${label} is required`,
      },
    }) || {};

  const handleOnChange = (value: any) => {
    setValue(value);
    onChangeProp?.(value?.value);
    onChange?.({ target: { value: value?.value, name } });
  };

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    handleOnChange(defaultValue);
  }, [defaultValue]);

  return (
    <Field>
      {label && (
        <Label className="mb-1">
          {label} {required && <span className="text-secondary">*</span>}
        </Label>
      )}
      <Combobox
        disabled={disabled}
        as="div"
        value={value}
        onChange={handleOnChange}
        onClose={() => setQuery('')}
        {...field}
        {...props}
      >
        <div className="relative">
          <ComboboxInput
            autoComplete="off"
            displayValue={(item: { label: string; value: string }) =>
              item?.label
            }
            className={clsx(
              error && 'border border-red',
              'px-4 py-2 rounded w-full border border-base-1000 bg-base-1000 inputStyle',
              disabled ? 'text-base-700' : 'text-base-000',
              className
            )}
            style={{
              boxShadow:
                '0px 1px 2px 0px #1018280D,2px 2px 4px 0px #00000040 inset',
            }}
            onChange={(event) => setQuery(event.target.value)}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 top-1/2 -translate-y-1/2 px-4">
            <TriangleDownIcon className="size-5" />
            {endAdornment && (
              <span className="absolute right-10 top-1/2 -translate-y-1/2">
                {endAdornment}
              </span>
            )}
          </ComboboxButton>
        </div>

        <ComboboxOptions
          anchor={{ to: 'bottom start', gap: 4 }}
          className={clsx(
            'selectOptions absolute max-h-60 w-[var(--input-width)]',
            'overflow-auto bg-block-fill border border-base-1000 shadow-lg z-10',
            'custom-scrollbar rounded p-2 shadow-lg empty:invisible'
          )}
        >
          {query.length > 0 && (
            <ComboboxOption
              value={{ label: query, value: query }}
              className={({ focus, selected }) =>
                clsx(
                  focus ? 'bg-base-900' : '',
                  'cursor-default select-none relative py-2 pl-3 pr-4 flex items-center border-b border-base-1000',
                  'last:border-0 selectOption',
                  { focus: focus, selected: selected }
                )
              }
            >
              Add &nbsp;<span className="font-bold">"{query}"</span>
            </ComboboxOption>
          )}
          {filteredOptions?.map((op) => (
            <ComboboxOption
              key={op.value}
              value={op}
              className={({ focus, selected }) => {
                return clsx(
                  focus ? 'bg-base-900' : '',
                  selected && 'bg-base-1000',
                  'cursor-default select-none relative py-2 pl-3 pr-4 flex items-center border-b border-base-1000',
                  'last:border-0 selectOption',
                  { focus: focus, selected: selected }
                );
              }}
            >
              <div>{op.label}</div>
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
      {error && <p className="text-red mt-1">{`${error?.message}`}</p>}
    </Field>
  );
}
