import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useMainUser, useUser } from '../../../../contexts/auth-context';
import {
  usePrescriptionProduct,
  useUpdateFieldPrescription,
} from '../../../../hooks/field/use-field-prescription';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import FlipLayout from '../../commons/FlipLayout';
import { getZone, getZonesWithProducts } from '../helpers';
import SeedingRateForm from './SeedingRateForm';
import SeedingRateInfo from './SeedingRateInfo';
import SeedingRateList from './SeedingRateList';

export default function SetSeedingRateAndVarieties({
  crop,
  presctiptions,
  loading,
}: {
  crop: string;
  presctiptions: FieldPrescriptionRes[];
  loading: boolean;
}) {
  const user = useUser();
  const mainUser = useMainUser();
  const year = useYearFilter();
  const { data: seedingVarieties, isFetching } = usePrescriptionProduct({
    type: 'seed',
    year: year ?? '',
    crop,
    userId: user?.id || '',
  });
  const { mutateAsync: update, isPending } = useUpdateFieldPrescription();

  const [list, setList] = useState<FieldPrescriptionWithProducts[]>([]);
  const [selectedZone, setSelectedZone] = useState(
    () => presctiptions[0]?.agrivar_zone || ''
  );
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProductByZone, setSelectedProductZone] = useState(
    list.reduce(
      (acc, item) => {
        acc[item.agrivar_zone] = {} as PrescriptionProductRes;
        return acc;
      },
      {} as Record<string, PrescriptionProductRes>
    )
  );

  const handleSetSelectedProduct = (
    zone: string,
    product: PrescriptionProductRes
  ) => {
    setSelectedProductZone((prev) => ({ ...prev, [zone]: product }));
  };

  useEffect(() => {
    setList(getZonesWithProducts(presctiptions, seedingVarieties, 'seed'));
  }, [presctiptions, seedingVarieties, isFetching, loading]);

  useEffect(() => {
    setSelectedProductZone((prev) => {
      return {
        ...prev,
        [selectedZone]:
          seedingVarieties?.find(
            (item) => getZone(item.field, item.user_id_agrivar_zone) === selectedZone
          ) || ({} as PrescriptionProductRes),
      };
    });
  }, [list, seedingVarieties, selectedZone]);

  const handleUpdate = async (value: number) => {
    if (!user || !mainUser || !year || !selectZoneObj || !crop) return;

    try {
      await update({
        user: mainUser,
        user_id: user.id,
        year: year,
        user_id_crop: `${user.id}_${year}-${crop}`,
        agrivar_zone: selectZoneObj.agrivar_zone,
        key: 'seeding_rate',
        field: selectZoneObj.field,
        field_index: selectZoneObj.field_index,
        value: value.toString(),
      });
      enqueueSnackbar('Seeding rate updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Failed to update seeding rate', { variant: 'error' });
    }
  };

  const selectZoneObj = useMemo(() => {
    return (
      list?.find((item) => item.agrivar_zone === selectedZone) ??
      ({} as FieldPrescriptionWithProducts)
    );
  }, [list, selectedZone]);

  return (
    <DisclosureContentLayout
      left={
        <>
          {isEditing ? (
            <SeedingRateForm
              title={
                <span>
                  Selected Zone:{' '}
                  <span className="text-primary">{`Zone ${selectZoneObj.agrivar_zone1}`}</span>
                </span>
              }
              edit
              key={JSON.stringify(selectedProductByZone[selectedZone])}
              crop={crop}
              selectedZone={selectZoneObj}
              setIsEditing={setIsEditing}
              zones={list}
              selectedProduct={selectedProductByZone[selectedZone]}
            />
          ) : (
            <FlipLayout disabled>
              {({ side, setSide }) => (
                <div>
                  {side === 'front' && (
                    <SeedingRateInfo
                      key={JSON.stringify(selectedProductByZone[selectedZone])}
                      onFlip={() => setSide('back')}
                      selectedZone={selectZoneObj}
                      onUpdate={handleUpdate}
                      crop={crop}
                      loading={isPending || isFetching}
                      selectedProduct={selectedProductByZone[selectedZone]}
                    />
                  )}
                  {side === 'back' && (
                    <SeedingRateForm
                      key={selectedZone}
                      crop={crop}
                      selectedZone={selectZoneObj}
                      zones={list}
                      onFlip={() => setSide('front')}
                    />
                  )}
                </div>
              )}
            </FlipLayout>
          )}
        </>
      }
      right={
        <SeedingRateList
          crop={crop}
          data={list}
          selectedZone={selectZoneObj}
          onSelect={setSelectedZone}
          loading={loading || isFetching}
          setIsEditing={setIsEditing}
          selectedProductByZone={selectedProductByZone}
          setSelectedProductByZone={handleSetSelectedProduct}
        />
      }
    />
  );
}
