import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DollarLockIcon from '../../../assets/icons/DollarLockIcon';
import DollarUnlockIcon from '../../../assets/icons/DollarUnlockIcon';
import FarmIcon from '../../../assets/icons/FarmIcon';
import FieldIcon from '../../../assets/icons/FieldIcon';
import FileUploadIcon from '../../../assets/icons/FileUploadIcon';
import FormEntryIcon from '../../../assets/icons/FormEntryIcon';
import PhotoUploadIcon from '../../../assets/icons/PhotoUploadIcon';
import SproutIcon from '../../../assets/icons/SproutIcon';
import { SupportedCrops, TextractType } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useCreateExpense } from '../../../hooks/expense/use-expense';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import { useFieldPrescription } from '../../../hooks/field/use-field-prescription';
import { useUploadFile } from '../../../hooks/file/use-file-upload';
import { useTextract } from '../../../hooks/textract/use-textract';
import CardHeader from '../../../components/commons/CardHeader';
import Select, { AwaitSelect } from '../../../components/commons/Select';
import ComboDatePicker from '../../../components/commons/inputs/ComboDatePicker';
import ComboSelectInput from '../../../components/commons/inputs/ComboSelectInput';
import RadioInput from '../../../components/commons/inputs/RadioInput';
import TextInput from '../../../components/commons/inputs/TextInput';
import {
  transformTextractExpenseToOptions,
  getExpenseAmountOptions,
} from '../../../components/data-inputs/farm-expenses/helpers';
import Button from '../../../components/commons/Button';
import {
  budgetItemOptions,
  categoryOptions,
} from '../../../constants/selectCommonConfigs';
import { useCreateBudgetItem } from '../../../hooks/budget/use-budget';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';

const ExpenseTypes = [
  {
    value: 'direct',
    label: 'Direct Expense',
    icon: DollarUnlockIcon,
  },
  {
    value: 'fixed',
    label: 'Fixed Expense',
    icon: DollarLockIcon,
  },
];

const AppliedTo = [
  {
    value: 'whole',
    label: 'Whole Farm',
    icon: FarmIcon,
  },
  {
    value: 'enterprise',
    label: 'Crop Enterprise',
    icon: SproutIcon,
  },
  {
    value: 'field',
    label: 'Individual Field',
    icon: FieldIcon,
  },
];

const getExpenseAppliedToPayload = (values: any) => {
  const field = values?.budgetField?.split('_')[0] ?? '';
  const { expenseAppliedTo, budgetZone, crop } = values;

  switch (expenseAppliedTo) {
    case 'whole':
      return 'whole';
    case 'enterprise':
      return crop;
    case 'field':
      return `${field}-${budgetZone}`;
    default:
      return '';
  }
};

export default function AddBudgetItemForm() {
  const user = useUser();
  const year = useYearFilter();
  const form = useForm({ reValidateMode: 'onChange', mode: 'onChange' });
  const [step, setStep] = useState('expenseType');
  const [currentPage, setCurrentPage] = useState(1);
  const [textTractOptions, setTextTractOptions] = useState<any>();
  const submitOption = form.watch('submitOption');

  const { uploadFile, isPending: isUploading } = useUploadFile();

  const totalPage = useMemo(
    () => textTractOptions?.category?.length || 1,
    [textTractOptions?.category?.length]
  );

  const handleFirstStep = () => {
    if (submitOption === 'formEntry') {
      setStep('expenseType');
      return;
    }
  };

  // Upload file
  const [fileUpload, setFileUpload] = useState({
    fileName: '',
  });

  // Reference to the file input element
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const getResponse = (fileName: string) => {
      if (fileName) {
        setFileUpload({ fileName });
      } else {
        setFileUpload({ fileName: '' });
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };

    const file = e.target.files?.[0];
    if (file) {
      const type = file.type.split('/')[1];
      const timeStamp = Date.now();
      const defaultFileName = `${user?.id}-${nanoid()}-${timeStamp}.${type}`;
      uploadFile(file, defaultFileName, getResponse);
      setFileUpload((prev) => ({ ...prev, loading: true }));
    }
  };

  // Textract
  const { getTextract, data, isLoading: isLoadingTextract } = useTextract();
  const { data: textTractData, status: textractStatus } = data ?? {};

  useEffect(() => {
    (async () => {
      try {
        if (fileUpload.fileName && !isUploading) {
          getTextract({
            documentName: fileUpload.fileName,
            type: TextractType.EXPENSE,
            userId: user?.id ?? '',
          });
        }
      } catch (error) {
        console.error('Error getting Textract data:', error);
      }
    })();
  }, [fileUpload.fileName, isUploading, user?.id]);

  useEffect(() => {
    if (textTractData && textractStatus === 'completed') {
      const options = transformTextractExpenseToOptions(
        JSON.parse(textTractData)
      );
      setTextTractOptions(options);
      setStep('details');
    }
  }, [textTractData, textractStatus]);

  // Field options
  const { data: allFields } = useFieldPolygonsByYear(
    user?.id ?? '',
    year ?? ''
  );
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: `${field.field}_${field.field_index}`,
    }));
  }, [allFields]);
  // Zone options
  const currentPageBudgetField = form.watch(`page${currentPage}.budgetField`);
  const [, fieldIndex] = currentPageBudgetField?.split('_') || [];
  const { data: fieldZones } = useFieldPrescription(
    `${user?.id}_${year}`,
    fieldIndex
  );
  const zoneOptions =
    fieldZones
      ?.sort(
        (fieldA, fiedlB) =>
          Number.parseInt(fieldA.agrivar_zone1) -
          Number.parseInt(fiedlB.agrivar_zone1)
      )
      ?.map((field) => ({
        value: field.agrivar_zone,
        label: field.agrivar_zone,
      })) || [];
  zoneOptions.unshift({ value: 'all zones', label: 'All Zones' });

  // Handle form submission
  const [remainPages, setRemainPages] = useState<number[]>(
    Array.from({ length: totalPage }, (_, i) => i + 1)
  );
  const { mutateAsync: createBudgetItem, isPending } = useCreateBudgetItem();

  //   const handleSubmitOne = async () => {
  //     if (!user || !year) return;

  //     const errors = form.formState.errors;
  //     if (errors[`page${currentPage}`]) {
  //       enqueueSnackbar(
  //         'Please complete required informations for this expense',
  //         {
  //           variant: 'warning',
  //         }
  //       );
  //       return;
  //     }

  //     const values = form.getValues()[`page${currentPage}`];
  //     try {
  //       const expenseAppliedToPayload = getExpenseAppliedToPayload(values);
  //       const fieldIndex = values?.budgetField?.split('_')[1];

  //       await createBudgetItem(
  //         {
  //           ...values,
  //           budgetType,
  //           budgetField: fieldIndex ?? '',
  //           budgetZone: values?.budgetZone ?? '',
  //           cropOther2: values?.cropOther2 ?? '',
  //           expenseAppliedTo: expenseAppliedToPayload,
  //           userId: `${user.id}_${year}`,
  //           description: values.description ?? '',
  //         },
  //       );
  //       enqueueSnackbar('Expense submitted successfully', {
  //         variant: 'success',
  //       });
  //       setRemainPages(remainPages.filter((page) => page !== currentPage));

  //       // Move to next page
  //       if (currentPage < totalPage) {
  //         setCurrentPage(currentPage + 1);
  //       } else if (remainPages.length === 1) {
  //         setCurrentPage(remainPages[0]);
  //       }
  //     } catch (error) {}
  //   };

  // Submit all expenses and single expense (if only one page)
  // Using the handleSubmit of react-hook-form
  // This only called when all pages are filled/form is valid
  const queryClient = useQueryClient();
    
  const handleSubmit = async (values: any) => {
    if (!user || !year) return;

    const payload = remainPages.map((page) => {
      const pageValues = values[`page${page}`];
      const expenseAppliedToPayload = getExpenseAppliedToPayload(pageValues);
      const fieldIndex = pageValues?.budgetField?.split('_')[1];

      return {
        ...pageValues,
        budgetField: fieldIndex ?? '',
        budgetZone: pageValues.budgetZone ?? '',
        cropOther2: pageValues.cropOther2 ?? '',
        budgetAppliedTo: expenseAppliedToPayload,
        userId: `${user.id}_${year}`,
        description: pageValues.description ?? '',
      };
    });

    try {
      await createBudgetItem(payload[0]);
      enqueueSnackbar('Budget item successfully added', {
        variant: 'success',
      });
      setStep('expenseType');
      queryClient.invalidateQueries([
        'budget',
        user?.id ?? '',
        year ?? '2024',
      ] as InvalidateQueryFilters);
      form.reset();
    } catch (error) {
      enqueueSnackbar('Failed to submit budget item', { variant: 'error' });
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (remainPages.length === 0) {
      form.reset();
      setStep('expenseApplied');
      setCurrentPage(1);
      setRemainPages(Array.from({ length: totalPage }, (_, i) => i + 1));
      setFileUpload({ fileName: '' });
    }
  }, [remainPages.length]);

  useEffect(() => {
    setRemainPages(Array.from({ length: totalPage }, (_, i) => i + 1));
  }, [totalPage]);

  const isSubmitted = !remainPages.includes(currentPage);
  const uploading = isUploading || isLoadingTextract;

  // Values for handle normal flow (steps form) page1 by default
  const budgetType = form.watch(`page1.budgetType`);
  const expenseAppliedTo = form.watch(`page1.expenseAppliedTo`);
  const crop = form.watch(`page1.crop`);
  const budgetField = form.watch(`page1.budgetField`);
  const budgetZone = form.watch(`page1.budgetZone`);
  const acres = form.watch(`page1.acres`);
  const budgetAmount = form.watch(`page1.budgetAmount`, '0');

  return (
    <div className="space-y-6">
      <CardHeader
        title="Add New Budget Item"
        subtitle={
          step === 'details' && totalPage > 1
            ? `Page ${currentPage} / ${totalPage}`
            : ''
        }
      />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          {/* expense_type Step */}
          <div className="space-y-5" hidden={step !== 'expenseType'}>
            <p>Please choose expense type.</p>
            {ExpenseTypes.map(({ value, label, icon: Icon }) => (
              <RadioInput
                key={value}
                name={`page${1}.budgetType`}
                value={value}
                label={label}
                icon={Icon}
                labelClassName="text-base-800 hover:text-primary"
              />
            ))}
            <div className="flex gap-4">
              <Button onClick={() => setStep('expenseType')}>Back</Button>
              <Button
                color="primary"
                disabled={!budgetType}
                onClick={() => setStep('expenseAppliedTo')}
              >
                Next
              </Button>
            </div>
          </div>
          {/* expense_applied_to Step */}
          <div className="space-y-5" hidden={step !== 'expenseAppliedTo'}>
            <p>What should the expense apply to?</p>
            {AppliedTo.map(({ value, label, icon: Icon }) => (
              <RadioInput
                key={value}
                name={`page${1}.expenseAppliedTo`}
                value={value}
                label={label}
                icon={Icon}
                labelClassName="text-base-800 hover:text-primary"
              />
            ))}
            <div className="flex gap-4">
              <Button onClick={() => setStep('expenseType')}>Back</Button>
              <Button
                color="primary"
                disabled={!expenseAppliedTo}
                onClick={() => {
                  setStep(
                    expenseAppliedTo === 'whole' ? 'details' : expenseAppliedTo
                  );
                }}
              >
                Next
              </Button>
            </div>
          </div>
          {/* Crop enterprise step */}
          <div className="space-y-5" hidden={step !== 'enterprise'}>
            <p>Which crop should the expense apply to?</p>
            <Select
              label="Crop"
              name={`page${1}.crop`}
              options={[
                ...SupportedCrops.map((crop) => ({
                  label: crop,
                  value: crop.toLowerCase(),
                })),
                { value: 'other', label: 'Other' },
              ]}
            />
            {crop === 'other' && (
              <TextInput
                name={`page${1}.cropOther2`}
                label="Describe Other"
                placeholder="Describe Other"
              />
            )}

            <div className="flex gap-4">
              <Button onClick={() => setStep('expenseType')}>Back</Button>
              <Button
                color="primary"
                disabled={!crop}
                onClick={() => setStep('details')}
              >
                Next
              </Button>
            </div>
          </div>
          {/* Individual field step */}
          <div className="space-y-5" hidden={step !== 'field'}>
            <p>Which field should the expense apply to?</p>
            <AwaitSelect
              label="Field"
              name={`page${1}.budgetField`}
              options={fieldOptions || []}
              isLoading={false}
            />

            {budgetField && (
              <AwaitSelect
                label="Zone"
                name={`page${1}.budgetZone`}
                options={zoneOptions || []}
                isLoading={false}
              />
            )}
            <div className="flex gap-4">
              <Button onClick={() => setStep('expenseType')}>Back</Button>
              <Button
                color="primary"
                disabled={!budgetField && !budgetZone}
                onClick={() => setStep('details')}
              >
                Next
              </Button>
            </div>
          </div>

          {/* Details step */}
          <div
            className={clsx({
              hidden: step !== 'details',
            })}
          >
            {Array.from({ length: totalPage }).map((_, index) => (
              <fieldset
                key={`page${index + 1}`}
                className={clsx('space-y-5', {
                  hidden: currentPage !== index + 1,
                })}
              >
                <p className="text-secondary">* Indicates Required Field</p>
                <TextInput
                  title={'Budget Details'}
                  label="Budget Details"
                  name={'budgetDetails'}
                />

                {textTractData && (
                  <>
                    <Select
                      name={`page${index + 1}.budgetType`}
                      label="Expense Type"
                      options={ExpenseTypes.map((type) => ({
                        label: type.label,
                        value: type.value,
                      }))}
                      required
                    />
                    <Select
                      name={`page${index + 1}.expenseAppliedTo`}
                      label="Applied To"
                      options={AppliedTo.map((type) => ({
                        label: type.label,
                        value: type.value,
                      }))}
                      required
                    />
                    {/* Applied to field */}
                    {form.watch(`page${index + 1}.expenseAppliedTo`) ===
                      'field' && (
                      <AwaitSelect
                        label="Field"
                        name={`page${index + 1}.budgetField`}
                        options={fieldOptions || []}
                        isLoading={false}
                        required
                      />
                    )}
                    {form.watch(`page${index + 1}.expenseAppliedTo`) ===
                      'field' &&
                      form.watch(`page${index + 1}.budgetField`) && (
                        <AwaitSelect
                          label="Zone"
                          name={`page${index + 1}.budgetZone`}
                          options={zoneOptions || []}
                          isLoading={false}
                          required
                        />
                      )}

                    {/* Applied to enterprise */}
                    {form.watch(`page${index + 1}.expenseAppliedTo`) ===
                      'enterprise' && (
                      <Select
                        name={`page${index + 1}.crop`}
                        label="Crop"
                        options={[
                          ...SupportedCrops.map((crop) => ({
                            label: crop,
                            value: crop.toLowerCase(),
                          })),
                          { value: 'other', label: 'Other' },
                        ]}
                        required
                      />
                    )}
                    {form.watch(`page${index + 1}.crop`) === 'other' && (
                      <TextInput
                        name={`page${index + 1}.cropOther2`}
                        label="Describe Other"
                        placeholder="Describe Other"
                        required
                      />
                    )}
                  </>
                )}
                <ComboDatePicker
                  name={`page${index + 1}.entryDate`}
                  label="Entry Date"
                  required
                  options={textTractOptions?.purchaseDate || []}
                  disabled={isSubmitted}
                  defaultValue={new Date().toISOString().split('T')[0]}
                />
                <ComboSelectInput
                  name={`page${index + 1}.budgetCategory`}
                  label="Category"
                  options={categoryOptions}
                  required
                  disabled={isSubmitted}
                />
                <TextInput
                  name={`page${index + 1}.description`}
                  label="Description"
                  disabled={isSubmitted}
                />
                <ComboSelectInput
                  name={`page${index + 1}.budgetItem`}
                  label="Budget Item"
                  required
                  options={budgetItemOptions}
                  disabled={isSubmitted}
                  onChange={(e) => {
                    if (
                      e === 'total' &&
                      !isNaN(parseFloat(budgetAmount) * acres)
                    ) {
                      form.setValue(
                        `page1.budgetAmount`,
                        parseFloat(budgetAmount) * acres
                      );
                    } else if (!isNaN(parseFloat(budgetAmount) / acres)) {
                      form.setValue(
                        `page1.budgetAmount`,
                        (parseFloat(budgetAmount) / acres).toFixed(0)
                      );
                    }
                  }}
                />

                {form.watch(`page1.budgetItem`) === 'acre' && (
                  <div
                    className={`${form.watch(`page${index + 1}.budgetItem`) === 'total' ? 'hidden' : ''}`}
                  >
                    <TextInput
                      name={`page1.acres`}
                      label="Number of Acres"
                      disabled={isSubmitted}
                      required
                    />
                  </div>
                )}

                <TextInput
                  name={`page1.budgetAmount`}
                  label="Budget Amount"
                  disabled={isSubmitted}
                  required
                />
              </fieldset>
            ))}

            <div className="space-y-5 mt-5">
              {totalPage > 1 && (
                <div className="flex space-x-4">
                  <Button
                    type="button"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    Previous
                  </Button>
                  <div className="whitespace-nowrap rounded-lg bg-base-1000 flex justify-center items-center px-4 space-x-1">
                    <p>{currentPage} /</p>
                    <p className="text-secondary">{totalPage}</p>
                  </div>
                  <Button
                    type="button"
                    disabled={currentPage >= totalPage}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    Next
                  </Button>
                </div>
              )}

              <div className="flex space-x-4">
                {totalPage <= 1 && (
                  <Button
                    onClick={() => {
                      form.reset();
                      setStep('expenseType');
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {totalPage <= 1 && (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      isSubmitted || isPending || !form.formState.isValid
                    }
                    loading={isPending}
                  >
                    Submit Expense
                  </Button>
                )}
                {/* 
                {totalPage > 1 && (
                  <Button
                    color="primary"
                    disabled={isSubmitted || isPending}
                    loading={isPending}
                    onClick={handleSubmitOne}
                  >
                    {remainPages.includes(currentPage)
                      ? 'Submit This'
                      : 'Submitted'}
                  </Button>
                )}
                {totalPage > 1 && (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      !remainPages.length ||
                      isPending ||
                      !form.formState.isValid
                    }
                    loading={isPending}
                  >
                    Submit All
                  </Button>
                )} */}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
