import {
  AddFertilizerPayload,
  AddSeedingRateVarietyPayload,
  DeletePrescriptionProductPayload,
  PrescriptionProductRes,
  PrescriptionUpdatePayload,
} from '../types';
import { AddChemicalPayload, ResetFieldPrescriptionPayload } from '../types/prescriptions';
import apiClient from './api-client';

export const getFieldPolygonsApi = (userId: string) => {
  return apiClient.get(`field/polygons?user_id=${userId}`);
};

export const getFieldPolygonsByYearApi = (userId: string, year: string) => {
  return apiClient.get(`field/polygons?user_id=${userId}&crop_year=${year}`);
};

export const getFieldsYieldApi = (userId: string, year: string) => {
  return apiClient.get(`/field/yield/all?user_id=${userId}&year=${year}`);
};

export const getFieldsRevenueApi = (userId: string, year: string) => {
  return apiClient.get(`/field/revenue/all?user_id=${userId}&year=${year}`);
};

export const getFieldMachinesApi = (userId: string) => {
  return apiClient.get(
    `/field/prescription/export/transfer-jd-machine?userId=${userId}`
  );
};

export const getFieldPrescriptionApi = ({
  userIdYear,
  fieldIndex = false,
}: {
  userIdYear: string;
  fieldIndex?: string | boolean;
}) => {
  const query = fieldIndex
    ? `/field/prescription?user_id=${userIdYear}&field_index=${fieldIndex}`
    : `/field/prescription?user_id=${userIdYear}`;
  return apiClient.get(query);
};

export const getPrescriptionProductApi = ({
  type,
  year,
  crop,
  userId,
}: {
  type: string;
  year: string;
  crop: string;
  userId: string;
}) => {
  return apiClient.get(
    `field/prescription/product?userId=${userId}&type=${type}&year=${year}&crop=${crop}`
  );
};

export const getFieldInputYieldApi = ({
  userId,
  year,
  fieldIndex,
  crop,
}: {
  userId: string;
  year: string;
  fieldIndex: string;
  crop: string;
}) => {
  return apiClient.get(
    `field/input-yield?userId=${userId}&year=${year}&fieldIndex=${fieldIndex}&crop=${crop}`
  );
};

// Mutation
export const updateFieldPrescriptionApi = (
  payload: PrescriptionUpdatePayload
) => {
  return apiClient.post(`/field/prescription/update`, payload);
};

export const createPrescriptionProductApi = (
  payload:
    | AddSeedingRateVarietyPayload
    | AddFertilizerPayload
    | AddChemicalPayload
) => {
  return apiClient.post(`/field/prescription/product`, payload);
};

export const updatePrescriptionProductApi = (
  payload: PrescriptionProductRes
) => {
  return apiClient.put(`/field/prescription/product`, payload);
};

export const deletePrescriptionProductApi = ({
  userId,
  uniqueId,
  year,
  zone,
}: DeletePrescriptionProductPayload) => {
  const encodedZone = encodeURIComponent(zone);
  return apiClient.delete(
    `field/prescription/product?uniqueId=${uniqueId}&userId=${userId}&year=${year}&agrivar_zone=${encodedZone}`
  );
};

export const getFieldPrescriptionHistoryApi = ({
  year,
  field,
}: {
  year: string;
  field: string;
}) => {
  return apiClient.get(
    `/field/prescription/history?year=${year}&field=${field}`
  );
};

export const deleteFieldPrescriptionHistoryApi = ({
  year,
  field,
}: {
  year: string;
  field: string;
}) => {
  return apiClient.delete(
    `/field/prescription/history?year=${year}&field=${field}`
  );
};

export const resetFieldPrescriptionApi = (payload: ResetFieldPrescriptionPayload) => {
  return apiClient.post(`/field/prescription/reset`, payload);
}