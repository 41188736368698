import {
  getFieldPolygonsApi,
  getFieldPolygonsByYearApi,
} from '../../api/field-api';
import { FieldPolygonRes } from '../../types';
import { useDepsQuery } from '../use-deps-query';

export const useFieldPolygons = (userId: string) => {
  const { data, ...rest } = useDepsQuery({
    queryKey: ['fields/polygons/all', userId],
    queryFn: () => getFieldPolygonsApi(userId),
    enabled: !!userId,
    refetchOnWindowFocus: false,
    deps: [userId],
  });

  return {
    data: data?.data as FieldPolygonRes[] | [],
    ...rest,
  };
};

export const useFieldPolygonsByYear = (userId: string, year: string) => {
  const { data, ...rest } = useDepsQuery({
    queryKey: ['fields/polygons/year', userId, year],
    queryFn: () => getFieldPolygonsByYearApi(userId, year),
    enabled: !!userId && !!year,
    deps: [userId, year],
  });

  return {
    data: data?.data as FieldPolygonRes[] | [],
    ...rest,
  };
};
