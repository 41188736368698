import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { SignUpSections, USPhoneRegex, USStateRegex } from '../../../constants';
import { useUpdateMyProfile } from '../../../hooks/user/use-users';
import DetailsForm from './DetailsForm';
import Prompts from './Prompts';
import CMEPricesTermsConditions from './CMEPricesTermsConditions';
import SMSMessagingTermsConditions from './SMSMessagingTermsConditions';
import UserTermsConditions from './UserTermsConditions';
import DataSharingPermissions from './DataSharingPermissions';
import { useUser } from '../../../contexts/auth-context';
import PlanSelection from './PlanSelection';

export type CompleteRegisterValueTypes = {
  username: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  phone: string;
  business_phone: string;
  contact: (string | undefined)[];
  optIn: boolean;
  advisor_description?: string;
  countyfips?: string;
  cme1: string[];
  cme2: string[];
  cme3: string[];
  user_terms_conditions: boolean;
  cme_terms_conditions: boolean;
  sms_terms_conditions: boolean;
  is_admin: boolean;
  is_advisor_client: boolean;
  network_partner: string;
  full_access: boolean;
  full_data_aggregation: boolean;
  data_input_full_access: boolean;
  data_input_full_data_aggregation: boolean;
  dashboard_access: boolean;
  maps_access: boolean;
  risk_management_access: boolean;
  weather_access: boolean;
  prescriptions_access: boolean;
  historical_insights_access: boolean;
  markets_access: boolean;
  dashboard_data_aggregation: boolean;
  maps_data_aggregation: boolean;
  risk_management_data_aggregation: boolean;
  weather_data_aggregation: boolean;
  prescriptions_data_aggregation: boolean;
  historical_insights_data_aggregation: boolean;
  markets_data_aggregation: boolean;
  data_input_overview_access: boolean;
  data_input_farm_expenses_access: boolean;
  data_input_crop_insurance_access: boolean;
  data_input_budgets_access: boolean;
  data_input_soil_samples_access: boolean;
  data_input_aph_histories_access: boolean;
  data_input_partner_connections_access: boolean;
  data_input_field_boundaries_access: boolean;
  data_input_overview_data_aggregation: boolean;
  data_input_farm_expenses_data_aggregation: boolean;
  data_input_crop_insurance_data_aggregation: boolean;
  data_input_budgets_data_aggregation: boolean;
  data_input_soil_samples_data_aggregation: boolean;
  data_input_aph_histories_data_aggregation: boolean;
  data_input_partner_connections_data_aggregation: boolean;
  data_input_field_boundaries_data_aggregation: boolean;
  plan_option: string;
};

const validateSchema = yup.object().shape({
  username: yup.string().label('Username').required(),
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  address: yup.string().label('Address').required(),
  phone: yup
    .string()
    .label('Phone')
    .required()
    .matches(USPhoneRegex, 'Please enter a valid phone number'),
  business_phone: yup.string().label('Business Phone').required(),
  city: yup.string().label('City').required(),
  state: yup
    .string()
    .label('State')
    .required()
    .matches(USStateRegex, 'Must be a valid state abbreviation'),
  contact: yup
    .array(yup.string())
    .min(1, 'Please select at least one contact method')
    .label('Contact')
    .required(),
  is_admin: yup.boolean().label('Is Admin').required(),
  optIn: yup.boolean().required('Please accept the terms and conditions'),
  is_advisor_client: yup.boolean().label('Is Advisor Client').required(),
  advisor_description: yup.string().label('Advisor Description'),
  countyfips: yup.string().label('County FIPS'),
  network_partner: yup.string().label('Network Partner').required(),
  cme1: yup.array(yup.string().nullable()).label('CME1') as yup.Schema<
    string[]
  >,
  cme2: yup.array(yup.string().nullable()).label('CME2') as yup.Schema<
    string[]
  >,
  cme3: yup.array(yup.string().nullable()).label('CME3') as yup.Schema<
    string[]
  >,
  user_terms_conditions: yup
    .boolean()
    .label('User Terms Conditions')
    .required(),
  cme_terms_conditions: yup.boolean().label('CME Terms Conditions').required(),
  sms_terms_conditions: yup.boolean().label('SMS Terms Conditions').required(),
  full_access: yup.boolean().default(false),
  full_data_aggregation: yup.boolean().default(false),
  data_input_full_access: yup.boolean().default(false),
  data_input_full_data_aggregation: yup.boolean().default(false),
  dashboard_access: yup.boolean().default(false),
  maps_access: yup.boolean().default(false),
  risk_management_access: yup.boolean().default(false),
  weather_access: yup.boolean().default(false),
  prescriptions_access: yup.boolean().default(false),
  historical_insights_access: yup.boolean().default(false),
  markets_access: yup.boolean().default(false),
  dashboard_data_aggregation: yup.boolean().default(false),
  maps_data_aggregation: yup.boolean().default(false),
  risk_management_data_aggregation: yup.boolean().default(false),
  weather_data_aggregation: yup.boolean().default(false),
  prescriptions_data_aggregation: yup.boolean().default(false),
  historical_insights_data_aggregation: yup.boolean().default(false),
  markets_data_aggregation: yup.boolean().default(false),
  data_input_overview_access: yup.boolean().default(false),
  data_input_farm_expenses_access: yup.boolean().default(false),
  data_input_crop_insurance_access: yup.boolean().default(false),
  data_input_budgets_access: yup.boolean().default(false),
  data_input_soil_samples_access: yup.boolean().default(false),
  data_input_aph_histories_access: yup.boolean().default(false),
  data_input_partner_connections_access: yup.boolean().default(false),
  data_input_field_boundaries_access: yup.boolean().default(false),
  data_input_overview_data_aggregation: yup.boolean().default(false),
  data_input_farm_expenses_data_aggregation: yup.boolean().default(false),
  data_input_crop_insurance_data_aggregation: yup.boolean().default(false),
  data_input_budgets_data_aggregation: yup.boolean().default(false),
  data_input_soil_samples_data_aggregation: yup.boolean().default(false),
  data_input_aph_histories_data_aggregation: yup.boolean().default(false),
  data_input_partner_connections_data_aggregation: yup.boolean().default(false),
  data_input_field_boundaries_data_aggregation: yup.boolean().default(false),
  plan_option: yup.string().default('0'),
});

export default function CompleteRegister() {
  const user = useUser();
  const { mutate: updateMyProfile, isPending } = useUpdateMyProfile();
  const [currentSection, setCurrentSection] = useState(SignUpSections.Details);

  const form = useForm<CompleteRegisterValueTypes>({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      username: '',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      phone: '',
      business_phone: '',
      contact: [],
      optIn: true,
      countyfips: '0000',
      is_advisor_client: false,
      advisor_description: '',
      is_admin: false,
      network_partner: '',
      cme1: [],
      cme2: [],
      cme3: [],
      user_terms_conditions: false,
      cme_terms_conditions: false,
      sms_terms_conditions: false,
      // data_sharing_permissions
      full_access: false,
      full_data_aggregation: false,
      data_input_full_access: false,
      data_input_full_data_aggregation: false,
      dashboard_access: false,
      maps_access: false,
      risk_management_access: false,
      weather_access: false,
      prescriptions_access: false,
      historical_insights_access: false,
      markets_access: false,
      dashboard_data_aggregation: false,
      maps_data_aggregation: false,
      risk_management_data_aggregation: false,
      weather_data_aggregation: false,
      prescriptions_data_aggregation: false,
      historical_insights_data_aggregation: false,
      markets_data_aggregation: false,
      data_input_overview_access: false,
      data_input_farm_expenses_access: false,
      data_input_crop_insurance_access: false,
      data_input_budgets_access: false,
      data_input_soil_samples_access: false,
      data_input_aph_histories_access: false,
      data_input_partner_connections_access: false,
      data_input_field_boundaries_access: false,
      data_input_overview_data_aggregation: false,
      data_input_farm_expenses_data_aggregation: false,
      data_input_crop_insurance_data_aggregation: false,
      data_input_budgets_data_aggregation: false,
      data_input_soil_samples_data_aggregation: false,
      data_input_aph_histories_data_aggregation: false,
      data_input_partner_connections_data_aggregation: false,
      data_input_field_boundaries_data_aggregation: false,
    },
    resolver: yupResolver<CompleteRegisterValueTypes>(validateSchema),
  });

  const handleNext = (nextSection: string) => {
    setCurrentSection(nextSection);
  };

  const handleSubmit = (values: any) => {
    if (!user) return;
    const payload = {
      ...values,
      user_id: user.id,
      contact: values?.contact
        ? values.contact?.join(',')
        : '',
      cme1: values?.cme1
        ? values.cme1?.filter((a: string) => !!a).join(',')
        : '',
      cme2: values?.cme2
        ? values.cme2?.filter((a: string) => !!a).join(',')
        : '',
      cme3: values?.cme3
        ? values.cme3?.filter((a: string) => !!a).join(',')
        : '',
      optIn: values?.optIn ?? 'false',
    };
    updateMyProfile(payload);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        {currentSection === SignUpSections.Details && (
          <DetailsForm form={form} onNext={handleNext} />
        )}
        {currentSection === SignUpSections.Prompts && (
          <Prompts form={form} onNext={handleNext} isPending={isPending} />
        )}
        {currentSection === SignUpSections.UserTermsConditions && (
          <UserTermsConditions form={form} onNext={handleNext} />
        )}
        {currentSection === SignUpSections.CMETermsConditions && (
          <CMEPricesTermsConditions form={form} onNext={handleNext} />
        )}
        {currentSection === SignUpSections.SMSTermsConditions && (
          <SMSMessagingTermsConditions form={form} onNext={handleNext} />
        )}
        {currentSection === SignUpSections.PlanSelection && (
          <PlanSelection form={form} onNext={handleNext} />
        )}
        {currentSection === SignUpSections.DataSharingPermissions && (
          <DataSharingPermissions form={form} />
        )}
      </form>
    </FormProvider>
  );
}
