import { GridCellLayer } from 'deck.gl';
import { useEffect, useState } from 'react';
import {
  convertBigInt64ToInt,
  filterWxData,
  filterWxDataByValue,
} from '../../../utils';
import { MyLayerData } from '../../../types';
import { colorsSelectionToSchemaField } from '../../../constants';
import { useHistoricalInsights } from '../../../hooks/historical-insights/use-historical-insights';
import * as turf from '@turf/turf';

export const useLayers = ({
  wData,
  relationship,
  featureCollection,
  xbin,
}: {
  wData: any;
  relationship: string;
  featureCollection: any;
  xbin: number[];
}) => {
  const [hoverInfo, setHoverInfo] = useState<any>();
  const {
    varietyYieldPerformance,
    creatingStudy,
    setCreatingStudy,
    drawRef,
    selectionGridIndicies,
    selectedStudyIndex,
    trigger,
  } = useHistoricalInsights();
  
  const {layers, setLayers} = useHistoricalInsights();

  useEffect(() => {
    const meanLons: any = [];
    const meanLats: any = [];
    if (wData && relationship) {
      const dataChunks = wData.map((data: any, chunkIndex: number) => {
        const layerid = 'map-layer-' + chunkIndex.toString();
        const lons = data['lon'];
        const lats = data['lat'];
        const field = data['field']; // field_name
        const fieldIndex = data['field_index'];
        const colorkey = colorsSelectionToSchemaField();
        const reds =
          data[colorkey[varietyYieldPerformance as keyof typeof colorkey]['r']];
        const greens =
          data[colorkey[varietyYieldPerformance as keyof typeof colorkey]['g']];
        const blues =
          data[colorkey[varietyYieldPerformance as keyof typeof colorkey]['b']];
        const length = lons.length; // Adjusted to use filtered lons length
        if (length === 0) return {};
        const value = data['x_value']; //data[relationship];
        const wYield = data['yield'];
        const fieldPerformance = data['yield_performance'];

        const meanLon =
          lons.reduce(
            (accumulator: any, currentData: any) => accumulator + currentData,
            0
          ) / lons.length;
        const meanLat =
          lats.reduce(
            (accumulator: any, currentData: any) => accumulator + currentData,
            0
          ) / lats.length;

        meanLons.push(meanLon);
        meanLats.push(meanLat);

        return {
          lons,
          lats,
          wYield,
          fieldPerformance,
          field,
          fieldIndex,
          value,
          reds,
          blues,
          greens,
          length,
        };
      });

      let filteredChunks = dataChunks;

      if (!(xbin[0] === -1 && xbin[1] === -1)) {
        // filter by x_r, x_g, x_b
        filteredChunks = dataChunks.map((dataChunk: any) => {
          const filteredData: any = {
            lons: [],
            lats: [],
            wYield: [],
            fieldPerformance: [],
            field: dataChunk.field,
            fieldIndex: [],
            value: [],
            reds: [],
            blues: [],
            greens: [],
            length: 0,
          };

          dataChunk.lons.forEach((lon: number, index: number) => {
            if ( relationship === 'variety' && dataChunk.value[index] === xbin[0] ||
              dataChunk.value[index] >= xbin[0] &&
              dataChunk.value[index] <= xbin[1]
            ) {
              if (lon !== undefined && lon >= -180 && lon <= 180) {
                filteredData.lons.push(lon);
              }
              if (
                index < dataChunk.lats?.length &&
                dataChunk.lats[index] !== undefined &&
                dataChunk.lats[index] >= -90 &&
                dataChunk.lats[index] <= 90
              ) {
                filteredData.lats.push(dataChunk.lats[index]);
              }
              if (
                index < dataChunk.wYield?.length &&
                dataChunk?.wYield[index] !== undefined
              ) {
                filteredData.wYield.push(dataChunk.wYield[index]);
              }
              if (
                index < dataChunk.fieldPerformance?.length &&
                dataChunk?.fieldPerformance[index] !== undefined
              ) {
                filteredData.fieldPerformance.push(
                  dataChunk.fieldPerformance[index]
                );
              }
              if (
                index < dataChunk.fieldIndex?.length &&
                dataChunk?.fieldIndex[index] !== undefined
              ) {
                filteredData.fieldIndex.push(dataChunk.fieldIndex[index]);
              }
              if (
                index < dataChunk.value?.length &&
                dataChunk?.value[index] !== undefined
              ) {
                filteredData.value.push(dataChunk.value[index]);
              }
              if (
                index < dataChunk.reds?.length &&
                dataChunk?.reds[index] !== undefined &&
                dataChunk.reds[index] >= 0 &&
                dataChunk.reds[index] <= 255
              ) {
                filteredData.reds.push(dataChunk.reds[index]);
              }
              if (
                index < dataChunk.blues?.length &&
                dataChunk?.blues[index] !== undefined &&
                dataChunk.blues[index] >= 0 &&
                dataChunk.blues[index] <= 255
              ) {
                filteredData.blues.push(dataChunk.blues[index]);
              }
              if (
                index < dataChunk.greens?.length &&
                dataChunk?.greens[index] !== undefined &&
                dataChunk.greens[index] >= 0 &&
                dataChunk.greens[index] <= 255
              ) {
                filteredData.greens.push(dataChunk.greens[index]);
              }
              filteredData.length += 1;
            }
          });

          return filteredData;
        });
      }

      if (creatingStudy || selectedStudyIndex !== -1) {
        if (featureCollection.features.length > 0) {
          filteredChunks = dataChunks.map((dataChunk: any) => {
            const filteredData: any = {
              lons: [],
              lats: [],
              wYield: [],
              fieldPerformance: [],
              field: dataChunk.field,
              fieldIndex: [],
              value: [],
              reds: [],
              blues: [],
              greens: [],
              length: 0,
            };

            dataChunk.lons.forEach((lon: number, index: number) => {
              const lat = dataChunk.lats[index];
              const point = turf.point([lon, lat]);
              let flag;
              for (let i = 0; i < featureCollection.features.length; i++) {
                const feature = featureCollection.features[i];
                const polygon = turf.polygon(feature.geometry.coordinates);
                if (turf.booleanPointInPolygon(point, polygon)) {
                  flag = true;
                  break;
                }
              }
              if (flag) {
                filteredData.lons.push(lon);
                filteredData.lats.push(lat);
                filteredData.wYield.push(dataChunk?.wYield[index]);
                filteredData.fieldPerformance.push(
                  dataChunk?.fieldPerformance[index]
                );
                if (dataChunk?.fieldIndex) {
                  filteredData.fieldIndex.push(dataChunk?.fieldIndex[index]);
                }
                filteredData.value.push(dataChunk?.value[index]);
                filteredData.reds.push(dataChunk?.reds[index]);
                filteredData.blues.push(dataChunk?.blues[index]);
                filteredData.greens.push(dataChunk?.greens[index]);
                filteredData.length += 1;
              }
            });

            return filteredData;
          });
        }
      }

      const layers = filteredChunks.map(
        (dataChunk: MyLayerData[], chunkIndex: number) =>
          new GridCellLayer({
            id: `map-layer-${chunkIndex}`,
            data: dataChunk,
            cellSize: 10,
            extruded: false,
            getPosition: (
              object,
              {
                index,
                data,
                target,
              }: { index: number; data: any; target: number[] } // Change Float32Array to number[]
            ) => {
              target[0] = data.lons[index];
              target[1] = data.lats[index];
              return target as [number, number];
            },
            getFillColor: (
              object,
              { index, data }: { index: number; data: any }
            ) => [data.reds[index], data.greens[index], data.blues[index], 255],
            getElevation: (object, { index, data, target }) => {
              return 10;
            },
            pickable: true,
            elevationScale: 1,
            opacity: 1,
            onHover: (info) => {
              const data = info.layer?.props.data as {
                value?: Float64Array;
                lats?: Float64Array;
                lons?: Float32Array;
                fieldIndex?: Uint8Array;
              };

              const hval = data?.value?.[info.index];
              const lon = data?.lons?.[info.index];
              const lat = data?.lats?.[info.index];
              const fld = data?.fieldIndex?.[info.index];
              setHoverInfo({
                x: info.x,
                y: info.y,
                hval,
                lon,
                lat,
                fld,
              });
            },
          })
      );

      const colorData: any = [];
      dataChunks &&
        dataChunks.length > 0 &&
        dataChunks.forEach((chunk: any) => {
          chunk.value &&
            chunk.value.length &&
            chunk.value.forEach((value: any, index: any) => {
              const value1 = convertBigInt64ToInt(value);
              colorData.push({
                value: value1,
                color: `rgb(${chunk.reds[index]}, ${chunk.greens[index]}, ${chunk.blues[index]})`,
              });
            });
        });

      setLayers(layers);
    }
  }, [
    wData,
    selectionGridIndicies,
    relationship,
    varietyYieldPerformance,
    creatingStudy,
    drawRef,
    selectedStudyIndex,
    trigger,
    xbin,
  ]);

  return {
    hoverInfo,
    layers,
    setLayers,
  };
};
