import { useQueryClient } from '@tanstack/react-query';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useUser } from './auth-context';

interface WebsocketContextType {
  roots: string[];
  setRoots: React.Dispatch<React.SetStateAction<string[]>>;
  tables: string[];
  setTables: React.Dispatch<React.SetStateAction<string[]>>;
  productTypes: string[];
  setProductTypes: React.Dispatch<React.SetStateAction<string[]>>;
  year: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
}

const WebsocketContext = createContext<WebsocketContextType | undefined>(
  undefined
);

interface WebsocketProviderProps {
  children: ReactNode;
}

export const WebsocketProvider: React.FC<WebsocketProviderProps> = ({
  children,
}) => {
  const [roots, setRoots] = useState<string[]>(['ZC']);
  const [tables, setTables] = useState<string[]>([]);
  const [productTypes, setProductTypes] = useState<string[]>(['futures']);
  const [year, setYear] = useState<string>('2024');
  const queryClient = useQueryClient();
  const user = useUser();
  const wsRef = useRef<WebSocket | null>(null); // Reference to track the WebSocket instance
  const shouldReconnect = useRef<boolean>(true);
  const connecting = useRef<boolean>(false);

  function extractPropertiesWithS(msgImage: any) {
    if (!msgImage) return null;
    const extractedData: any = {};
    for (const key in msgImage) {
      if (msgImage.hasOwnProperty(key)) {
        extractedData[key] = msgImage[key]?.S;
      }
    }
    return extractedData;
  }

  useEffect(() => {
    if (
      !user ||
      connecting.current ||
      !year ||
      !roots ||
      !productTypes ||
      !tables ||
      tables.length <= 0
    )
      return;

    let pingInterval: NodeJS.Timeout;

    const userIdYear = `${user?.id}_${year}`;

    const connectWebSocket = () => {
      // Prevent reopening if WebSocket is already open or in the process of opening
      if (wsRef.current && wsRef.current.readyState !== WebSocket.CLOSED) {
        console.log(
          'WebSocket v2 already open, readyState:',
          wsRef.current.readyState
        );
        // close websocket and don't allow it to reopen
        shouldReconnect.current = false;
        wsRef.current.close();
      }

      console.log(
        'new websocket with url: ',
        `${process.env.REACT_APP_WEBSOCKET_URL_V2}?userName=${user?.username}&userId=${userIdYear}&marketRoots=${roots.join(',')}&productTypes=${productTypes.join(',')}&tables=${tables.join(',')}`
      );

      connecting.current = true;

      const ws = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL_V2}?userName=${user?.username}&userId=${userIdYear}&marketRoots=${roots.join(',')}&productTypes=${productTypes.join(',')}&tables=${tables.join(',')}`
      );

      console.log('websocket v2 connecting...');

      ws.onmessage = (me: MessageEvent<any>) => {
        const data = JSON.parse(me.data);

        console.log('websocket v2 data:', data);

        if (data?.message?.includes('error')) {
          console.error('WebSocket v2 error:', data.message);
          ws.close();
          return;
        }

        const msgEventType = data.data?.eventSourceARN
          .split(':')[5]
          .split('/')[1];
        const msgImage = extractPropertiesWithS(data.data?.dynamodb?.NewImage);

        const eventTypeMapping: { [key: string]: string } = {
          HLCO: 'HLCO',
          Bid_Ask: 'Bid_Ask',
          Basis2: 'Basis',
          Farm_Revenue2_PROD: 'Farm_Revenue',
          Field_Revenue2_PROD: 'Field_Revenue',
          Field_Yield2_PROD: 'Field_Yield',
          Farm_Yield2_PROD: 'Farm_Yield',
          Hedges_PROD: 'Hedges',
          Leases: 'Leases',
          Insurance_PROD: 'Insurance',
          Prescription_History: 'Prescription',
        };

        if (msgEventType && eventTypeMapping[msgEventType]) {
          queryClient.setQueryData(
            ['websocketv2', eventTypeMapping[msgEventType]],
            msgImage
          );
        }
      };

      ws.onopen = () => {
        console.log('WebSocket v2 connection opened');
        wsRef.current = ws; // Set the current WebSocket instance
        connecting.current = false;
      };

      ws.onclose = () => {
        console.log('WebSocket v2 connection closed');

        // aws has a 10 minute idle timeout - attempt to reconnect if this occurs
        clearInterval(pingInterval);
        wsRef.current = null; // Reset the WebSocket instance reference
        if (shouldReconnect.current) {
          setTimeout(connectWebSocket, 100); // Reconnect after .1 sec
        } else {
          shouldReconnect.current = true;
        }
      };

      ws.onerror = (error) => {
        console.log('WebSocket v2 error:', error);
        ws.close();
      };

      // Optionally, set up a ping mechanism to keep the connection alive
      pingInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ type: 'ping' }));
        }
      }, 30000); // Ping every 30 seconds to keep the connection alive
    };

    connectWebSocket();

    return () => {
      // Cleanup on unmount or dependency change
      if (wsRef.current) {
        wsRef.current.close();
      }
      clearInterval(pingInterval);
    };
  }, [roots, tables, productTypes, queryClient, year, user?.id]);

  return (
    <WebsocketContext.Provider
      value={{
        roots,
        setRoots,
        tables,
        setTables,
        productTypes,
        setProductTypes,
        year,
        setYear,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

// Selectors

export function useWebsocketContext() {
  const context = useContext(WebsocketContext);

  if (context === undefined) {
    throw new Error(
      'useWebsocketContext must be used within a WebsocketProvider'
    );
  }

  return context;
}
