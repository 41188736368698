import { isEmpty } from 'lodash';
import { Outlet } from 'react-router-dom';
import { useControlledUser, useMainUser } from '../../contexts/auth-context';

export default function AccessControl({
  permissions,
  children,
  announcement,
}: {
  children?: React.ReactNode;
  permissions: string[];
  announcement?: string | React.ReactNode | boolean;
}) {
  const controlledUser = useControlledUser();
  const mainUser = useMainUser();

  if (!controlledUser || mainUser?.is_admin) {
    return children ? <>{children}</> : <Outlet />;
  }
  const userPermissions = controlledUser?.permissions;
  if (
    !permissions.length ||
    isEmpty(userPermissions) ||
    permissions.some((permission) => userPermissions[permission])
  ) {
    return children ? <>{children}</> : <Outlet />;
  }
  if (announcement === false) {
    return <></>;
  }
  return announcement ? (
    <>{announcement}</>
  ) : (
    <div className="flex justify-center items-center flex-col my-auto space-y-2">
      <h1 className="text-2xl text-base-bold text-yellow">Access Denied</h1>
      <p>You do not have permission to access this feature</p>
    </div>
  );
}
