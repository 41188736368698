import React, { useEffect, useMemo, useRef, useState } from 'react';
import CardHeader from '../../../components/commons/CardHeader';
import { useTableData } from '../../../hooks/map/use-map';
import { useUser } from '../../../contexts/auth-context';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { Bar } from 'react-chartjs-2';
import tinycolor from 'tinycolor2';
import { useParams } from 'react-router-dom';
import { mapParamsToTitles, tabToUnit } from '../../../constants';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';
import { useMapContext } from '../../../contexts/map-context';
import SpinningIcon from '../../../assets/icons/SpinningIcon';
import { formatXBin } from '../../../utils';
import { ZoneMapItem } from '../../../types';
import useTypeParams from './useTypeParams';

function LikelihoodExpectedRevenue({ crop }: { crop: string }) {
  const user = useUser();
  const year = useYearFilter();
  const cropSet = useRef(new Set<string>(['Corn', 'Soybeans']));

  const { selectedDate: date, selectedZone, setSelectedZone } = useMapContext();
  const typeParam = useTypeParams();

  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;
  const tableData = useTableData(
    user?.id || '',
    crop,
    type!,
    'agrivar_zone2',
    year!,
    date,
    'zone'
  );
  const [selectedCrops, setSelectedCrops] = useState<Set<string>>(
    new Set(['Corn', 'Soybeans'])
  );
  const [hiddenDatasets, setHiddenDatasets] = useState<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;
  const gradientCropColors: { [key: string]: string } = {
    Soybeans: 'rgba(224, 199, 162, 1)',
    Corn: 'rgba(255, 203, 89, 1)',
    Other: 'rgba(255, 102, 102, 1)',
    Wheat: 'rgba(147, 195, 250, 1)',
  };

  const cropColors: { [key: string]: string } = {
    Soybeans: 'rgba(174, 154, 125, 1)',
    Corn: 'rgba(255, 183, 27, 1)',
    Other: 'rgba(244, 39, 39, 1)',
    Wheat: 'rgba(98,162,235,1)',
  };

  const labels: { [key: string]: string[] } = useMemo(
    () =>
      tableData?.data?.reduce(
        (acc: { [key: string]: string[] }, dataItem: ZoneMapItem) => {
          const xBin = dataItem.x_bin === 'nan' ? 'Other' : dataItem.x_bin;
          if (!acc[dataItem.agrivar_zone2]) {
            acc[dataItem.agrivar_zone2] = [];
          }
          if (!acc[dataItem.agrivar_zone2].includes(xBin)) {
            acc[dataItem.agrivar_zone2].push(xBin);
          }
          // Sorting the array for each agrivar_zone2
          acc[dataItem.agrivar_zone2] = acc[dataItem.agrivar_zone2].sort(
            (a, b) => {
              if (a === 'Other') return -1;
              let numA = parseFloat(
                a.split(' - ')[0].replace(/[^0-9.-]+/g, '')
              );
              let numB = parseFloat(
                b.split(' - ')[0].replace(/[^0-9.-]+/g, '')
              );
              return numA - numB;
            }
          );
          return acc;
        },
        {}
      ),
    [tableData?.data]
  );

  const zones = useMemo(() => {
    if (!tableData?.data) return {};

    const zoneData: { [key: string]: { data: number[]; crop: string } } = {};

    tableData.data.forEach((dataItem: ZoneMapItem) => {
      let area = parseFloat(dataItem.area);
      if (isNaN(area)) area = 0;

      if (!zoneData[dataItem.agrivar_zone2]) {
        zoneData[dataItem.agrivar_zone2] = {
          data: Array(labels[selectedZone]?.length ?? 0).fill(0),
          crop: dataItem.crop,
        };
      }

      const index = labels[selectedZone]?.indexOf(dataItem.x_bin);
      if (index !== -1) {
        zoneData[dataItem.agrivar_zone2].data[index] = area;
      }
    });

    return zoneData;
  }, [tableData?.data, labels]);

  // Get unique zone names from the data
  const uniqueZones = useMemo(
    () =>
      Object.keys(zones).sort((a, b) => {
        const zoneA = zones[a];
        const zoneB = zones[b];
        if (!zoneA || !zoneB) return 0;
        const comp = zoneA.crop.localeCompare(zoneB.crop);
        // if the crops are the same, sort by zone name
        if (comp === 0) return a.localeCompare(b);
        return comp;
      }),
    [zones]
  );

  // Set the first zone as the default selected zone
  useEffect(() => {
    if (uniqueZones.length > 0 && !selectedZone) {
      setSelectedZone(uniqueZones[0]);
    }
  }, [uniqueZones]);

  const filteredZones = useMemo(() => {
    return uniqueZones.filter((zoneName) =>
      zoneName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [uniqueZones, searchTerm]);

  const paginatedZones = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredZones.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredZones, currentPage]);

  const datasets = useMemo(() => {
    if (!zones[selectedZone]) return [];

    const zoneCrop = zones[selectedZone]?.crop;

    return [
      {
        label: selectedZone,
        data: zones[selectedZone].data,
        backgroundColor: function (context: any) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          const cropColor = cropColors[zoneCrop];
          const gradientCropColor = gradientCropColors[zoneCrop];

          if (!chartArea) {
            return cropColor;
          }

          const bar = context.chart.getDatasetMeta(context.datasetIndex).data[
            context.index
          ];
          if (!isFinite(bar?.y) || !isFinite(bar.x) || !isFinite(bar.height)) {
            return cropColor;
          }
          const gradient = ctx.createLinearGradient(
            0,
            bar?.y,
            0,
            bar?.y + bar.height - 10
          ); // Vertical gradient for the current bar
          gradient.addColorStop(0, gradientCropColor);
          gradient.addColorStop(0.5, cropColor);
          return gradient;
        },
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 20,
      },
    ];
  }, [zones, selectedZone, cropColors]);

  const options: any = {
    layout: {
      padding: {
        left: 0,
        right: 70,
        top: 0,
        bottom: 0,
      },
    },
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: false,
        grid: { display: false },
        title: {
          display: true,
          text: 'Acres',
          font: { size: 20 },
        },
        ticks: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: { display: false },
        title: {
          display: true,
          text: `${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} Per Acre (${tabToUnit[typeParam as keyof typeof tabToUnit]})`,
          font: { size: 20 },
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value: any) => `${value.toFixed(2)} Acres`,
        color: 'white',
        font: {
          size: 12,
          weight: 'bold',
        },
      },
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            let value =
              context.raw !== null ? `${context.raw.toFixed(2)} Acres` : 'N/A';
            let crop = zones[selectedZone]?.crop || 'Unknown Crop';
            return `${label} (${crop}): ${value}`;
          },
          title: function (tooltipItems: any) {
            console.log('tooltipItems', tooltipItems);
            return `${tooltipItems[0].label} (${tabToUnit[typeParam as keyof typeof tabToUnit]})`;
          },
        },
      },
    },
  };

  return (
    <div className="p-0 col-span-3">
      <div className="w-full flex gap-8 justify-between cursor-pointer">
        <CardHeader
          title={
            typeParam === 'yield_probability'
              ? `Probability of Hitting Set Yield Goal by Zone, ${selectedZone}`
              : `Expected ${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} per Acre by Zone, ${selectedZone}`
          }
        />
        {/* {selectedZone && (
          <div
          className="card flex h-[50px] items-center gap-2 px-4 py-2 rounded-md"
            onClick={() => setSelectedZone('')}
          >
            <ArrowsPointingOutIcon className="w-6 h-6 cursor-pointer" />
            <p className='w-max-w min-w-[100px]'>Show All Zones</p>
          </div>
        )} */}
      </div>

      <div className="h-[400px]">
        <Bar
          data={{ labels: labels ? labels[selectedZone] : [], datasets }}
          options={options}
          plugins={[ChartDataLabels]}
        />
      </div>
    </div>
  );
}

export default LikelihoodExpectedRevenue;
