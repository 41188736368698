import AccessControl from '../../components/routes/AccessControl';
import { PERMISSIONS } from '../../constants';
import DataInputs from '../../pages/data-inputs';
import CropInsurance from '../../pages/data-inputs/crop-insurance';
import FieldInfoBoundary from '../../pages/data-inputs/field-info-boundary';
import ClimateCallbackPage from '../../pages/data-inputs/partner/callback/climate';
import JohnDeereCallbackPage from '../../pages/data-inputs/partner/callback/john-deere';
import SoilSamples from '../../pages/data-inputs/soil-samples';

const dataInputRoutes = [
  {
    path: 'data-inputs',
    children: [
      {
        index: true,
        element: (
          <AccessControl
            permissions={[PERMISSIONS.DATA_INPUT_OVERVIEW_ACCESS]}
          >
            <DataInputs />
          </AccessControl>
        ),
      },
      {
        path: 'crop-insurance',
        element: (
          <AccessControl
            permissions={[PERMISSIONS.DATA_INPUT_CROP_INSURANCE_ACCESS]}
          >
            <CropInsurance />
          </AccessControl>
        ),
      },
      {
        path: 'soil-samples',
        element: (
          <AccessControl
            permissions={[PERMISSIONS.DATA_INPUT_SOIL_SAMPLES_ACCESS]}
          >
            <SoilSamples />
          </AccessControl>
        ),
      },
      {
        path: 'field-info-and-boundary',
        element: (
          <AccessControl
            permissions={[PERMISSIONS.DATA_INPUT_FIELD_BOUNDARIES_ACCESS]}
          />
        ),
        children: [
          {
            index: true,
            element: <FieldInfoBoundary />,
          },
        ],
      },
      {
        path: 'jd/callback',
        element: (
          <AccessControl
            permissions={[PERMISSIONS.DATA_INPUT_PARTNER_CONNECTIONS_ACCESS]}
          >
            <JohnDeereCallbackPage />
          </AccessControl>
        ),
      },
      {
        path: 'climate/callback',
        element: (
          <AccessControl
            permissions={[PERMISSIONS.DATA_INPUT_PARTNER_CONNECTIONS_ACCESS]}
          >
            <ClimateCallbackPage />
          </AccessControl>
        ),
      },
    ],
  },
];

export default dataInputRoutes;
