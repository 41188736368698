// import Button from '../../../components/commons/Button';
// import CardHeader from '../../../components/commons/CardHeader';
import { DisclosureButton } from '@headlessui/react';
import Disclosure from '../../../components/commons/Disclosure';
import AddFarmExpenseForm from '../../../components/data-inputs/farm-expenses/AddFarmExpenseForm';
import FarmExpenseList from '../../../components/data-inputs/farm-expenses/FarmExpenseList';
import { ChevronDownIcon, ChevronUpIcon, ClipboardIcon } from '@heroicons/react/24/solid';
import Table, { TableConfigs } from '../../../components/commons/table/Table';
import { useUser } from '../../../contexts/auth-context';
import { useExpenseLog } from '../../../hooks/expense/use-expense';
import AddBudgetItemForm from './AddBudgetItemForm';
import BudgetList from './BudgetList';

export default function Budget() {


  const user = useUser();


  return (
    <div className="sm:grid sm:grid-cols-12 gap-8">
      <div className="col-span-3 space-y-8">
        <div className="card !rounded-lg p-4">
          <AddBudgetItemForm />
        </div>

        {/* <div className="card !rounded-lg p-4 space-y-6">
          <CardHeader title="View Saved Farm Expense Items" />
          <p>
            Return to previously saved farm expense items that have not yet been
            submitted.
          </p>
          <Button color="primary">View Saved Farm Expense Items</Button>
        </div> */}
      </div>

      <div className="card !rounded-lg col-span-9 overflow-hidden h-fit">
        <BudgetList />
      </div>
    </div>
  );
}
