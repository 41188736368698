import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  approveStaffRequestApi,
  approveUserRegistrationApi,
  getIncomingUsersApi,
  getStaffApi,
  getUserByIdApi,
  getUsersApi,
  requestUserRegistrationApi,
  resetUserPasswordApi,
  sendTempPasswordApi,
  updateMyProfileApi,
  updateUserApi,
} from '../../api/user-api';
import { useSetMainUser } from '../../contexts/auth-context';
import {
  StaffRes,
  UpdateMyProfilePayload,
  UpdateUserPayload,
  UserProfileRes,
} from '../../types';

export const useUsers = ({ is_completed }: { is_completed?: boolean }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['users', is_completed],
    queryFn: () => getUsersApi({ is_completed }),
  });

  return { data: data?.data as UserProfileRes[], ...rest };
};

export const useUserById = (userId: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['users', userId],
    queryFn: () => getUserByIdApi(userId),
    enabled: !!userId,
  });

  return { data: data?.data as UserProfileRes, ...rest };
};

export const useIncomingUsers = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['users/register-request'],
    queryFn: () => getIncomingUsersApi(),
  });

  return { data: data?.data?.users as UserProfileRes[], ...rest };
};

export const useStaffs = (enabled: boolean) => {
  const { data, ...rest } = useQuery({
    queryKey: ['users/advisors'],
    queryFn: () => getStaffApi(),
    enabled,
  });

  return { data: data?.data as StaffRes[], ...rest };
};

export const useSendTempPassword = () => {
  const mutation = useMutation({
    mutationKey: ['users/send-temp-password'],
    mutationFn: ({ userId, email }: { userId: string; email: string }) =>
      sendTempPasswordApi(userId, email),
    onSuccess: () => {
      enqueueSnackbar('Temporary password sent successfully', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to send temporary password', {
        variant: 'error',
      });
    },
  });

  return mutation;
};

export const useUpdateUser = () => {
  const mutation = useMutation({
    mutationKey: ['users/update'],
    mutationFn: (payload: UpdateUserPayload) => updateUserApi(payload),
    onSuccess: () => {
      enqueueSnackbar('Update successfully', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update', {
        variant: 'error',
      });
    },
  });

  return mutation;
};

export const useApproveUserRegistration = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['users/register-approve'],
    mutationFn: ({ userId, email }: { userId: string; email: string }) =>
      approveUserRegistrationApi(userId, email),
    onSuccess: () => {
      enqueueSnackbar('Approved successfully', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['users/register-request'] });
    },
    onError: (error: any) => {
      const errMsg = error?.response?.data?.message || 'Failed to approved';
      enqueueSnackbar(errMsg, {
        variant: 'error',
      });
    },
  });

  return mutation;
};

export const useApproveStaffRequest = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['users/advisors/approve-request'],
    mutationFn: ({ memberId }: { memberId: string }) =>
      approveStaffRequestApi(memberId),
    onSuccess: () => {
      enqueueSnackbar('Approved successfully', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['users/advisors'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to approved', {
        variant: 'error',
      });
    },
  });

  return mutation;
};

export const useRequestUserRegistration = () => {
  const mutation = useMutation({
    mutationKey: ['users/register-request'],
    mutationFn: (payload: {
      email: string;
      requesting_status: string;
      network_partner: string;
    }) => requestUserRegistrationApi(payload),
    onSuccess: () => {
      enqueueSnackbar('Request sent successfully', { variant: 'success' });
    },
    onError: (error: any) => {
      const errMsg = error?.response?.data?.message || 'Failed to send request';
      enqueueSnackbar(errMsg, {
        variant: 'error',
      });
    },
  });

  return mutation;
};

export const useUpdateMyProfile = () => {
  const navigate = useNavigate();
  const setUser = useSetMainUser();
  const mutation = useMutation({
    mutationKey: ['users/me'],
    mutationFn: (payload: UpdateMyProfilePayload) =>
      updateMyProfileApi(payload),
    onSuccess: ({ data }) => {
      setUser?.(data);
      enqueueSnackbar('Updated!', { variant: 'success' });
      navigate('/', { replace: true });
    },
    onError: () => {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    },
  });

  return mutation;
};

export const useResetUserPassword = () => {
  return useMutation({
    mutationKey: ['users/reset-password'],
    mutationFn: ({ email, password }: { email: string; password: string }) =>
      resetUserPasswordApi({ email, password }),
    onSuccess: () => {},
    onError: () => {},
  });
};
