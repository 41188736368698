import { getMarketBidAskApi } from '../../api/market-api';
import { BidAskRes } from '../../types';
import { useDepsQuery } from '../use-deps-query';

export const useMarketBidAsk = ({
  symbol,
  option_type,
  enabled = true,
  root,
}: {
  symbol?: string;
  option_type?: string;
  enabled?: boolean;
  root?: string;
}) => {
  const { data, ...rest } = useDepsQuery({
    queryKey: ['market/bid-ask', symbol, option_type, root],
    queryFn: () => getMarketBidAskApi({ root, symbol, option_type }),
    enabled: (!!symbol || !!root) && enabled,
    deps: [symbol, root, enabled],
  });

  return {
    data: (data?.data?.items?.[0] as BidAskRes) || {},
    allData: data?.data?.items as BidAskRes[],
    ...rest,
  };
};
