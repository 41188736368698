import DeckGL, { WebMercatorViewport } from 'deck.gl';
import { useEffect, useMemo, useRef, useState } from 'react';
import Map, { MapRef } from 'react-map-gl';
import { useParams } from 'react-router-dom';
import SpinningIcon from '../../../assets/icons/SpinningIcon';
import { CropColors, MAP_STYLE, PolygonStyles } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import TimelineSlider from './TimelineSlider';
import { useCenter } from './useCenter';
import { useDateIndex } from './useDateIndex';
import { useDateSet } from './useDateSet';
import { useLayers } from './useLayers';
import { useS3Keys } from './useS3Keys';
import { useViewBounds } from './useViewBounds';
import { useWData } from './useWData';
import { getTooltip } from './helpers';
import { useMapContext } from '../../../contexts/map-context';
import {
  DrawCircleFromCenterMode,
  DrawPolygonByDraggingMode,
  DrawPolygonMode,
  DrawRectangleMode,
  DrawSquareMode,
  EditableGeoJsonLayer,
  FeatureCollection,
  ModifyMode,
  TransformMode,
  ViewMode,
} from '@deck.gl-community/editable-layers';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import * as turf from '@turf/turf';
import {
  useCreateStudy,
  useStudyData,
  useUpdateStudy,
} from '../../../hooks/map/use-map';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { enhanceFieldItem } from '../../../components/data-inputs/field-info-boundary/helpers';
import { useAllFieldPolygon } from '../../../hooks/field-polygon/use-field-polygon';
import { getCropColors } from '../../../utils';

interface MapSectionProps {
  selectedArea: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
}

export default function MapSection({
  selectedArea,
  setSelectedDate,
}: MapSectionProps) {
  const { type: typeParam = '' } = useParams();
  // temporary
  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;

  const [loadedView, setLoadedView] = useState(false);
  //
  const user = useUser();

  const year = useYearFilter();
  const { mutate: createStudyMutation } = useCreateStudy();

  const [probabilityRange, setProbabilityRange] = useState<[number, number]>();

  const [fieldRange, setFieldRange] = useState();
  const [zone, setZone] = useState();
  const [zoneRange, setZoneRange] = useState();

  const { s3Keys, loading: s3KeysLoading } = useS3Keys({
    userId: user?.id || '',
    year: year || '',
    type,
  });

  const { dateSet } = useDateSet(s3Keys);

  const { selectedDateIndex, dateIndex, setSelectedDateIndex, setDateIndex } =
    useDateIndex(dateSet.length - 1);

  useEffect(() => {
    setSelectedDate(dateSet[selectedDateIndex]);
  }, [selectedDateIndex]);

  const { wData, loading: wDataLoading } = useWData({
    date: dateSet[selectedDateIndex],
    userId: user?.id || '',
    year: year || '',
    s3Keys,
    type,
  });

  const { center } = useCenter(wData);

  const { viewBounds } = useViewBounds(wData);

  const {
    myFeatureCollection,
    studyDetails,
    setStudyDetails,
    creatingStudy,
    setMyFeatureCollection,
    viewState,
    setViewState,
    setSelectedFeatureIndexes,
    selectedFeatureIndexes,
    selectedDrawMode,
    selectedField,
    setSelectedField,
    selectedZone,
    createStudy,
    setCreateStudy,
    setCreatingStudy,
    selectedStudyIndex,
    trigger,
    setTrigger,
    setModalMode,
    editMode,
    setEditMode,
    saveEditingStudy,
    setSaveEditingStudy,
    selectedChart,
    changingZoom,
    changingDate,
    centerTrigger,
  } = useMapContext();

  const studies: any = useStudyData(user?.id ?? '', year!);
  const { mutate: updateStudyMutation } = useUpdateStudy();

  const { layers, hoverInfo } = useLayers({
    wData,
    selectedArea,
    probabilityRange,
    fieldRange,
    zone: selectedZone,
    zoneRange,
    type,
    crop: selectedArea === 'all' ? '' : selectedArea,
    featureCollection: myFeatureCollection,
  });


  useEffect(() => {}, [layers]);

  const handleViewStateChange = ({ viewState }: any) => {
    setViewState(viewState);
  };

  const loading = s3KeysLoading || wDataLoading;

  useEffect(() => {
    if (center && !changingDate) {
      const newCenter: any = {
        longitude: center.lon,
        latitude: center.lat,
      };
      if (changingZoom) {
        newCenter['zoom'] =
          selectedChart === 'field' || selectedChart === 'zone' ? 12 : 10;
      }
      if (selectedStudyIndex !== -1) {
        newCenter['zoom'] = 12;
      } 
      setViewState((prev: any) => ({ ...prev, ...newCenter }));
    }
  }, [center, selectedStudyIndex, centerTrigger]);

  console.log('myFeatureCollection:', myFeatureCollection);

  const calculateTotalArea = () => {
    try {
      let total = 0;
      for (let feature of myFeatureCollection.features) {
        const area = turf.area(feature?.geometry);
        total += area;
      }
      return (total * 0.000247105).toFixed(2)
      // let totalLons = 0;
      // for (let i = 0; i < layers?.length; i++) {
      //   const lons = layers[i].props.data.lons;
      //   if (!lons) {
      //     continue;
      //   }
      //   totalLons += lons?.length;
      // }
      // return (totalLons * 0.00247105).toFixed(2);
    } catch (e) {
      console.log('Error calculating total area:', e);
      return 0;
    }
  };

  const calculateAverages = () => {
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;
    let avgLat = 0;
    let avgLon = 0;
    let num = 0;
    let x = 0;

    wData?.map((data, index) => {
      data.lon.forEach((lon: number, lonIndex: number) => {
        const bin = data['value'][lonIndex];
        x += bin;
        min = Math.min(min, bin);
        max = Math.max(max, bin);
        avgLat += data.lat[lonIndex];
        avgLon += lon;
        num += 1;
      });
    });
    return {
      xbin: `${min.toFixed(2)} - ${max.toFixed(2)}`,
      avgLat: (avgLat / num).toFixed(2),
      avgLon: (avgLon / num).toFixed(2),
      x: (x / num).toFixed(2),
    };
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    if (creatingStudy) {
      const area = calculateTotalArea();
      const avgs = calculateAverages();
      const polygons = myFeatureCollection.features.map((feature: any) => {
        return feature?.geometry.coordinates;
      });

      setStudyDetails({
        ...studyDetails,
        area,
        ...avgs,
        userId: user?.id || '',
        year,
        type,
        polygons,
        crop: selectedArea,
      });
      // setCreatingStudy(false)
    }
  }, [creatingStudy, layers]);

  // add an event listener for the delet key to delete selected feature indexes from
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace') {
        let newFeatures = myFeatureCollection.features.filter(
          (feature, index) => {
            return !selectedFeatureIndexes.includes(index);
          }
        );
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: newFeatures,
        });
        setSelectedFeatureIndexes([]);
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        // remove the last feature
        let newFeatures = myFeatureCollection.features.slice(0, -1);
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: newFeatures,
        });
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedFeatureIndexes, myFeatureCollection.features]);

  useEffect(() => {
    if (viewBounds && !loadedView) {
      try {
        let viewportWebMercator = new WebMercatorViewport(viewState);
        const { longitude, latitude, zoom } = viewportWebMercator.fitBounds(
          viewBounds,
          {
            padding: 50,
          }
        );
        setViewState((prev: any) => ({
          ...prev,
          longitude,
          latitude,
          zoom,
          transitionDuration: loading ? 0 : 1500,
        }));
        setLoadedView(true);
      } catch (error) {
        console.log(error);
      }
    }
  }, [loading, viewBounds]);

  useEffect(() => {
    async function createStudyFunc() {
      if (createStudy) {
        if (!studyDetails.name) {
          alert('Please enter a study name');
          setCreateStudy(false);
          return;
        }
        createStudyMutation(
          { ...studyDetails },
          {
            onSuccess: () => {
              setCreatingStudy(false);
              alert('Study added');
              queryClient.invalidateQueries([
                'map/createStudy',
              ] as InvalidateQueryFilters);
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              console.log('Error creating study:', error);
              alert(`Failed to create study. Please try again. ${error}`);
            },
          }
        );
        setCreateStudy(false);
      }
    }
    createStudyFunc();
  }, [createStudy]);

  const drawModes = [
    ViewMode,
    DrawPolygonMode,
    DrawSquareMode,
    DrawRectangleMode,
    DrawCircleFromCenterMode,
    DrawPolygonByDraggingMode,
    ModifyMode,
    TransformMode,
  ];

  useEffect(() => {
    if (!studies.data) return;
    let allFeatures: any = [];
    for (let i = 0; i < studies.data.length; i++) {
      const study = studies.data[i];
      if (selectedStudyIndex === study.user_id_type_crop_year + study.x_index) {
        const polygons = study.polygons;
        const features = polygons.map((polygon: any) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: polygon,
            },
            properties: {},
          };
        });
        allFeatures = allFeatures.concat(features);
      }
    }
    setMyFeatureCollection({
      type: 'FeatureCollection',
      features: allFeatures,
    });
    setTrigger(trigger + 1);
  }, [selectedStudyIndex]);

  const layer = useMemo(
    () =>
      new EditableGeoJsonLayer({
        id: 'geojson-layer',
        mode: drawModes[selectedDrawMode],
        selectedFeatureIndexes: selectedFeatureIndexes,
        data: myFeatureCollection,
        modeConfig: { enableSpapping: true },
        onEdit: ({ updatedData, editType }) => {
          setMyFeatureCollection(updatedData);
          if (editType === 'updateTentativeFeature') {
            setModalMode('drawingShape');
          } else if (editType === 'addFeature') {
            setModalMode('drawingCompleted');
          }
        },
        onClick: (info) => {
          if (
            selectedDrawMode !== 0 &&
            selectedDrawMode !== 6 &&
            selectedDrawMode !== 7
          ) {
            return;
          }
          if (selectedFeatureIndexes.includes(info.index)) {
            setSelectedFeatureIndexes(
              selectedFeatureIndexes.filter((index) => index !== info.index)
            );
            return;
          }
          setSelectedFeatureIndexes([...selectedFeatureIndexes, info.index]);
        },
      }),
    [selectedDrawMode, selectedFeatureIndexes, myFeatureCollection]
  );

  useEffect(() => {
    if (myFeatureCollection.features.length < 1) {
      return;
    }
    setTimeout(() => {
      let index = myFeatureCollection.features.length - 1;
      if (index < 0) {
        return;
      }
      const updatedIndexes = [...selectedFeatureIndexes, index];
      // filter duplicates
      const updatedIndexesSet = new Set(updatedIndexes);
      setSelectedFeatureIndexes(Array.from(updatedIndexesSet));
    }, 10);
  }, [myFeatureCollection.features]);

  // write a use effect for saveEditingStudy
  useEffect(() => {
    async function saveEditingStudyFunc() {
      if (saveEditingStudy != null && editMode === 'save') {
        const area = calculateTotalArea();
        const avgs = calculateAverages();
        const polygons = myFeatureCollection.features.map((feature: any) => {
          return feature?.geometry.coordinates;
        });

        const saveStudyDetails = {
          ...saveEditingStudy,
          area,
          ...avgs,
          polygons,
          userId: user?.id || '',
        };

        updateStudyMutation(
          { ...saveStudyDetails },
          {
            onSuccess: () => {
              setCreatingStudy(false);
              alert('Study saved');
              queryClient.invalidateQueries([
                'map/createStudy',
              ] as InvalidateQueryFilters);
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              console.log('Error saving study:', error);
              alert(`Failed to save study. Please try again. ${error}`);
            },
          }
        );
        setSaveEditingStudy(null);
        setEditMode('');
      }
    }
    saveEditingStudyFunc();
  }, [saveEditingStudy, editMode]);

  const [cachedDate, setCachedDate] = useState<string | null>(null);

  useEffect(() => {
    // find date in dateset and set date index to cached date
    if (cachedDate) {
      const index = dateSet.indexOf(cachedDate);
      if (index !== -1) {
        setDateIndex(index);
      } else {
        setDateIndex(dateSet.length - 1);
      }
      return;
    }
    if (dateSet.length > 0) {
      setDateIndex(dateSet.length - 1);
    }
  }, [dateSet]);

  useEffect(() => {
    if (selectedDateIndex !== -1) {
      setCachedDate(dateSet[selectedDateIndex]);
    }
  }, [selectedDateIndex]);

  const mapRef = useRef<MapRef>(null);
  const drawRef = useRef<MapboxDraw | null>(null);

  const { data: allFields, isFetching } = useAllFieldPolygon(
    user?.id ?? '',
    year || '2024'
  );

  const fpList = useMemo(() => {
    return allFields?.map((field) => enhanceFieldItem(field));
  }, [allFields, isFetching]);

  const allFeatures = useMemo(() => {
    return fpList?.map((field) => {
      const geoJSON = JSON.parse(field.geojson);
      return {
        ...geoJSON,
        features: geoJSON.features.map((feat: any, index: number) => {
          return {
            ...feat,
            id: field.user_id_field_index,
            properties: {
              ...feat.properties,
              field,
              crop_color: getCropColors(field.crop2, user?.network_partner),
            },
          };
        }),
      };
    });
  }, [fpList]);

  // Render features
  useEffect(() => {
    if (mapRef.current && allFeatures?.length > 0) {
      if (drawRef.current) {
        mapRef.current?.removeControl(drawRef.current);
      }
      drawRef.current = new MapboxDraw({
        defaultMode: 'simple_select',
        displayControlsDefault: false,
        styles: PolygonStyles,
        userProperties: true,
      });
      // check
      mapRef.current?.addControl(drawRef.current);

      const validFeatures = allFeatures.filter(
        (feat: any) => !!feat?.features?.[0]?.geometry?.coordinates?.length
      );
      validFeatures.forEach((feat) => drawRef.current?.add(feat));
    }
  }, [allFeatures]);

  return (
    <div className="w-full space-y-9">
      <TimelineSlider
        value={dateIndex}
        cachedDate={cachedDate ?? ''}
        onChange={(value) => {
          setDateIndex(parseInt(value));
        }}
        min={0}
        max={dateSet.length - 1}
        dateSet={dateSet}
      />

      <div className="relative min-h-[550px] rounded-3xl overflow-hidden">
        {loading && (
          <span className="absolute w-full h-full bg-white/30 z-10 flex items-center justify-center"></span>
        )}
        <DeckGL
          layers={[layers, layer]}
          viewState={viewState}
          controller={true}
          onViewStateChange={handleViewStateChange}
          getTooltip={getTooltip}
        >
          <Map
            reuseMaps={false}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapStyle={MAP_STYLE}
            ref={mapRef}
            id='map-mapsection'
          />
        </DeckGL>
      </div>
    </div>
  );
}
