import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { getUserProfileApi, updateUserProfileApi } from '../../api/user-api';
import { UserProfileRes } from '../../types';
import { useShouldFetch } from '../use-deps-query';

export const useUserProfile = (enabled: boolean) => {
  const shouldFetch = useShouldFetch([enabled], enabled);

  const { data, ...rest } = useQuery({
    queryKey: ['users/me'],
    queryFn: getUserProfileApi,
    enabled: shouldFetch,
  });

  return {
    data: data?.data as UserProfileRes | undefined,
    ...rest,
  };
};

export const useUpdateUserProfile = () => {
  return useMutation({
    mutationKey: ['UPDATE', 'users/me'],
    mutationFn: (data: any) => updateUserProfileApi(data),
    onSuccess: () => {
      enqueueSnackbar('Profile updated successfully', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar('Failed to update profile', { variant: 'error' });
    },
  });
};
