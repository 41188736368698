import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { SupportedCrops, defaultsSettings } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useBasis } from '../../../hooks/basis/use-basis';
import { getPriceOptions } from '../../../hooks/contracts/use-futures-contracts';
import {
  useSetUserRealTimeSettings,
  useUserRealTimeSettings,
} from '../../../hooks/user/use-user-real-time-settings';
import { BasisRes, UserSettingsRealTimeSetPayload } from '../../../types';
import { getDefaultSymbols, groupBy } from '../../../utils';

export const usePriceTable = (cropName: string = '') => {
  const [selectedSymbol, setSelectedSymbol] = useState<string>('');
  const [selectedBasisId, setSelectedBasisId] = useState<string>('');

  const user = useUser();

  const year = useYearFilter();

  const defaultSymbols = getDefaultSymbols(year || '');
  const { data: defaultBasis } = useBasis({
    userId: user?.id || '',
    year: year || '',
    symbols: Object.values(defaultSymbols).map((symbol) => symbol),
  });

  const { data: userSettings } = useUserRealTimeSettings(
    user?.id || '',
    year || ''
  );
  const { mutate: setUserRealTimeSettings } = useSetUserRealTimeSettings();

  const queryClient = useQueryClient();

  useEffect(() => {
    const saveDefaultSettings = async () => {
      console.log('userSettings===', userSettings);
      console.log('defaultSymbols===', defaultSymbols);
      // if userSettings is not available then save the default settings
      if (!userSettings || userSettings.length === 0) {
        // await queryClient.invalidateQueries([
        //   'user/settings/real-time',
        // ] as InvalidateQueryFilters);
        if (user?.id && year && defaultBasis) {
          const defaultBasisGroupByCrop = groupBy(
            defaultBasis,
            'crop'
          ) as Record<string, BasisRes[]>;
          const userSettingsPayload: UserSettingsRealTimeSetPayload[] = [];
          SupportedCrops.forEach((crop) => {
            userSettingsPayload.push({
              user_id: user?.id,
              year: year,
              crop: crop,
              basis: defaultBasisGroupByCrop[crop].sort(
                (a, b) => parseFloat(a?.distance) - parseFloat(b?.distance)
              )?.[0].basis_id,
              contract: defaultSymbols?.[crop],
              trading_days: defaultsSettings.tradingDays.toString(),
              var_setting_1: defaultsSettings.vaRSettings[0].toString(),
              var_setting_2: defaultsSettings.vaRSettings[1].toString(),
            });
          });
          setUserRealTimeSettings(userSettingsPayload);
        }
      }

      for (const crop of SupportedCrops) {
        const setting = userSettings.find((setting) => setting.crop === crop);
        const priceOptions = getPriceOptions(crop, year);
        const isValidOption = priceOptions.some(
          (option: any) => option.value === setting?.contract
        );

        if (!isValidOption) {
          await queryClient.invalidateQueries([
            'user/settings/real-time',
          ] as InvalidateQueryFilters);

          if (user?.id && year && defaultBasis) {
            const defaultBasisGroupByCrop = groupBy(
              defaultBasis,
              'crop'
            ) as Record<string, BasisRes[]>;
            const nearestBasis = defaultBasisGroupByCrop[crop]?.sort(
              (a, b) => parseFloat(a.distance) - parseFloat(b.distance)
            )?.[0];

            if (nearestBasis) {
              const userSettingsPayload: UserSettingsRealTimeSetPayload = {
                user_id: user.id,
                year,
                crop,
                basis: nearestBasis.basis_id,
                contract: defaultSymbols?.[crop],
                trading_days: defaultsSettings.tradingDays.toString(),
                var_setting_1: defaultsSettings.vaRSettings[0].toString(),
                var_setting_2: defaultsSettings.vaRSettings[1].toString(),
              };

              setUserRealTimeSettings([userSettingsPayload]);
            }
          }
        }
      }
    };

    saveDefaultSettings();
  }, [user?.id]);

  return {
    selectedSymbol,
    setSelectedSymbol,
    selectedBasisId,
    setSelectedBasisId,
    userSettings,
  };
};
