import { Navigate } from 'react-router-dom';
import InboundGrain from '../../components/dashboard/risk-management/inbound-grain';
import Invoices from '../../pages/dashboard/invoices';
import FarmExpenses from '../../pages/data-inputs/farm-expenses';
import AccessControl from '../../components/routes/AccessControl';
import { PERMISSIONS } from '../../constants';
import BudgetList from '../../pages/data-inputs/budget/BudgetList';
import Budget from '../../pages/data-inputs/budget';

const financialsRoutes = [
  {
    path: 'financials',
    children: [
      {
        index: true,
        element: <Navigate to="farm-expenses" />,
      },
      {
        path: 'farm-expenses',
        element: (
          <AccessControl
            permissions={[PERMISSIONS.DATA_INPUT_FARM_EXPENSES_ACCESS]}
          >
            <FarmExpenses />
          </AccessControl>
        ),
      },
      {
        path: 'invoices',
        element: (
          <AccessControl
            permissions={[PERMISSIONS.INVOICE_ACCESS]}
          >
            <Invoices />
          </AccessControl>
        ),
      },
      {
        path: 'inbound-grain-tickets',
        element: (
          <AccessControl
            permissions={[PERMISSIONS.INBOUND_GRAIN_TICKETS_ACCESS]}
          >
            <InboundGrain />
          </AccessControl>
        ),
      },
      {
        path: 'budget',
        element: (
          <AccessControl
            permissions={[PERMISSIONS.DATA_INPUT_BUDGETS_ACCESS]}
          >
            <Budget />
          </AccessControl>
        ),
      },
    ],
  },
];

export default financialsRoutes;
