import AccessControl from '../../components/routes/AccessControl';
import { PERMISSIONS } from '../../constants';
import HistoricalInsights from '../../pages/dashboard/historical-insights';

const historicalInsightsRoutes = [
  {
    path: 'historical-insights',
    element: <AccessControl permissions={[PERMISSIONS.HISTORICAL_INSIGHTS_ACCESS]} />,
    children: [
      {
        index: true,
        element: <HistoricalInsights />,
      },
    ],
  },
];

export default historicalInsightsRoutes;
