import { Field } from '@headlessui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/commons/Button';
import { useMainUser } from '../../../contexts/auth-context';
import { useUpdateUserProfile } from '../../../hooks/user/use-user-profile';
import { PermissionsTable } from '../complete-register/DataSharingPermissions';

export default function EditUserForm() {
  const user = useMainUser();
  const navigate = useNavigate();
  const { mutate, isPending } = useUpdateUserProfile();

  const form = useForm({
    defaultValues: {
      // email: user?.email,
      // phone: user?.phone,
      // address: user?.address,
      ...user?.permissions,
    },
  });

  const handleSubmit = (values: any) => {
    const {
      email,
      phone,
      address,
      full_access,
      full_data_aggregation,
      data_input_full_access,
      data_input_full_data_aggregation,
      user_id,
      ...rest
    } = values;
    mutate({
      permissions: {
        ...rest,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form
        className="grid grid-cols-2 max-w-screen-md m-auto gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        {/* <TextInput name="email" label="Email" />
        <TextInput name="phone" label="Phone" />
        <span className="col-span-2">
          <TextInput name="address" label="Address" />
        </span> */}

        <Field className="col-span-2">
          <label>Permissions</label>
          <PermissionsTable form={form} />
        </Field>

        <Button onClick={() => navigate('/app/dashboard')} disabled={isPending}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={isPending}
          loading={isPending}
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
}
