import EditUserForm from '../../pages/main/user/UserEditForm';
import ProtectedRoute from '../../components/routes/ProtectedRoute';
import StaffList from '../../pages/main/staff/StaffList';
import IncomingUserList from '../../pages/main/user/IncomingUserList';
import UserList from '../../pages/main/user/UserList';

const mainRoutes = [
  {
    path: 'staff',
    element: (
      <ProtectedRoute roles={['advisor']}>
        <StaffList />
      </ProtectedRoute>
    ),
  },
  {
    path: 'staff-request',
    element: (
      <ProtectedRoute roles={['advisor', 'user']}>
        {/* <StaffRequest /> */}
        <IncomingUserList />
      </ProtectedRoute>
    ),
  },
  {
    path: 'users',
    element: (
      <ProtectedRoute roles={['admin']}>
        <UserList />
      </ProtectedRoute>
    ),
  },
  { path: 'settings/profile', element: <EditUserForm /> },
  {
    path: 'incoming-users',
    element: (
      <ProtectedRoute roles={['admin']}>
        <IncomingUserList />
      </ProtectedRoute>
    ),
  },
];

export default mainRoutes;
