import apiClient from './api-client';

export const getTextractApi = ({
  s3BucketName,
  documentName,
  type,
  userId,
}: {
  s3BucketName: string;
  documentName: string;
  type: string;
  userId?: string;
}) => {
  return apiClient.post('textract', {
    s3BucketName,
    documentName,
    type,
    ...(userId ? { userId: userId } : {}),
  });
};

export const getTextractResultApi = (uniqueid: string) => {
  return apiClient.get(`textract/?uniqueid=${uniqueid}`);
};
