import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  InsuranceCropOptions,
  InsuranceIrrigationPracticeOptions,
  InsuranceOptions,
  InsuranceOrganicPracticeOptions,
  InsurancePlanTypeOptions,
} from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import { useUpdateInsurance } from '../../../hooks/insurance/use-insurance';
import Button from '../../commons/Button';
import TextInput from '../../commons/inputs/TextInput';
import Select, { AwaitSelect } from '../../commons/Select';
import { InsuranceItem } from '../../../types/insurance';

interface EditCropInsuranceFormProps {
  data: InsuranceItem;
  onClose: () => void;
}

type CropInsuranceFormValues = {
  field_name: string;
  legal_description: string;
  crop: string;
  intended_use: string;
  irrigation_practice: string;
  organic_practice: string;
  acres: number;
  approved_yield: number;
  base_price: number;
  percent_coverage: number;
  premium_per_acre: number;
  share_percentage: number;
};

export default function EditCropInsuranceForm({
  data,
  onClose,
}: EditCropInsuranceFormProps) {
  const user = useUser();
  const year = useYearFilter();

  // Field options
  const { data: allFields, isFetching: isFetchingField } =
    useFieldPolygonsByYear(user?.id ?? '', year ?? '');
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: field.field,
    }));
  }, [allFields]);

  const { mutate: updateInsurace, isPending } = useUpdateInsurance();
  const form = useForm({
    defaultValues: {
      ...(data as any),
    },
  });

  const handleSubmit = (values: CropInsuranceFormValues) => {
    if (!user || !year) return;
    const updates = Object.entries(values).map(([key, value]) => ({
      key,
      value: value?.toString() ?? '',
    }));

    updateInsurace(
      {
        uniqueId: data.unique_id,
        legalDescription: data.legal_description,
        updates,
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <div>
      <p className="mb-2">Edit Crop Insurance</p>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="rounded-lg bg-base-900 hlBgBase200 p-4"
        >
          <div className="grid grid-cols-4 gap-4">
            <AwaitSelect
              name={`field_name`}
              label="Field Name"
              options={fieldOptions}
              isLoading={isFetchingField}
            />
            <TextInput name={`legal_description`} label="Field Legal" />
            <Select
              name={`crop`}
              label="Commodity"
              options={InsuranceCropOptions}
            />
            <TextInput name={`intended_use`} label="Intended Use" />
            <Select
              name={`irrigation_practice`}
              label="Irrigation Practice"
              options={InsuranceIrrigationPracticeOptions}
            />
            <Select
              name={`organic_practice`}
              label="Organic Practice"
              options={InsuranceOrganicPracticeOptions}
            />
             <Select
              name={`insurance_plan`}
              label="Insurance Plan Type"
              options={InsurancePlanTypeOptions}
              multiple
            />
            <Select
              name={`insurance_options`}
              label="Insurance Options"
              options={InsuranceOptions}
              multiple
            />
            <TextInput name="acres" type="number" label="Acres" />
            <TextInput
              name="approved_yield"
              type="number"
              label="APH"
              endAdornment={'.Bu'}
            />
            <TextInput name="base_price" type="number" label="Base Price" />
            <TextInput
              name="percent_coverage"
              type="number"
              label="Coverage Percent"
            />
            <TextInput
              name="premium_per_acre"
              type="number"
              label="Premium per Acre"
            />
            <TextInput
              name="share_percentage"
              type="number"
              label="Share Percent"
            />
          </div>

          <div className="mt-6 flex justify-end space-x-4">
            <Button
              type="submit"
              color="primary"
              size="sm"
              className="!w-fit"
              loading={isPending}
              disabled={isPending || !form.formState.isValid}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
