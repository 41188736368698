import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { NETWORK_PARTNER } from '../../constants';
import { useUser } from '../../contexts/auth-context';
import {
  useHistoricalInsights,
  useTableData,
} from '../../hooks/historical-insights/use-historical-insights';
import { capitalizeAll } from '../../utils';
import TableLayout from '../dashboard/commons/TableLayout';
import { mapLabelMap } from '../../constants/selectCommonConfigs';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const getColors = (partner?: string) => {
  switch (partner) {
    case NETWORK_PARTNER.heartland:
      return '#33000E';
    default:
      return 'white';
  }
};

const HorizontalBar = () => {
  const user = useUser();
  const { year, relationship, crop, page, yieldChartRef } =
    useHistoricalInsights();
  const tableData = useTableData(user?.id || '', crop, relationship, year);
  const index = page - 1;

  const data = {
    labels: tableData.data
      ?.sort(
        (a: any, b: any) =>
          a.xbin - b.xbin || parseFloat(b.yield) - parseFloat(a.yield)
      )
      // ?.slice(index * 5, (index + 1) * 5)
      ?.map((data: any) => data.x_bin),
    datasets: [
      {
        label: 'Yield',
        data: tableData.data
          ?.sort(
            (a: any, b: any) =>
              a.xbin - b.xbin || parseFloat(b.yield) - parseFloat(a.yield)
          )
          // ?.slice(index * 5, (index + 1) * 5)
          ?.map((data: any) => data.yield),
        backgroundColor: tableData.data
          // ?.slice(index * 5, (index + 1) * 5)
          ?.map(
            (data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`
          ),
        borderColor: tableData.data
          // ?.slice(index * 5, (index + 1) * 5)
          ?.map(
            (data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`
          ),
        borderWidth: 1,
        borderRadius: 5, // Rounded bars
        barThickness: 25, // Thickness of the bars
      },
    ],
  };

  const options: any = {
    layout: {
      padding: {
        left: 0,
        right: 80,
        top: 0,
        bottom: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        border: {
          color: 'transparent', // Make x-axis line transparent
        },
        beginAtZero: true,
        ticks: {
          display: false,
        },
        grid: {
          display: false, // Hide the grid lines
        },
        title: {
          display: true,
          text: 'Bu/Ac',
          font: { size: 14 },
        },
      },
      y: {
        border: {
          color: 'transparent', // Make x-axis line transparent
        },
        title: {
          display: true,
          text: `${capitalizeAll(relationship.replace('_', ' '))}`,
          font: { size: 14 },
        },
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
            color: '#ffffff',
          },
        },
        grid: {
          display: false, // Hide the grid lines
        },
      },
    },
    plugins: {
      title: {
        display: false,
        font: { size: 20 },
        color: getColors(user?.network_partner),
      },
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value: any) => `${parseFloat(value).toFixed(2)} Bu/Ac`,
        color: user?.network_partner === 'Heartland' ? 'black' : 'white',
        font: {
          size: 12,
          weight: 'bold',
        },
      },
    },
    elements: {
      bar: {
        hoverBackgroundColor: tableData.data
          // ?.slice(index * 5, (index + 1) * 5)
          ?.map(
            (data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`
          ),
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%', margin: '0 auto' }}>
      <TableLayout
        title={`${year} Average Yield by ${mapLabelMap[relationship as keyof typeof mapLabelMap]}`}
      />
      <div
        className="px-4"
        style={{
          height: data.labels?.length * (data.labels?.length > 10 ? 40 : 60),
        }}
      >
        <Bar
          ref={yieldChartRef}
          data={data}
          options={options}
          plugins={[ChartDataLabels]}
        />
      </div>
    </div>
  );
};

export default HorizontalBar;
