import { isObject } from 'lodash';
import { getFarmRevenues14DaysChangeApi } from '../../api/farm-api';
import { convertToFloat } from '../../utils';
import { useDepsQuery } from '../use-deps-query';

export const useFarmRevenues14Change = (userId: string, year: string) => {
  const { data, ...rest } = useDepsQuery({
    queryKey: ['farm/revenues/14days-change', userId, year],
    queryFn: () => getFarmRevenues14DaysChangeApi(userId, year),
    refetchOnWindowFocus: false,
    enabled: !!userId && !!year,
    deps: [userId, year],
  });

  return {
    data: data?.data as [] | [],
    ...rest,
  };
};

// helpers
export const mapCropInto = (data: unknown[]) => {
  if (!data) return [];
  return data.map((item) => {
    if (isObject(item)) {
      const cropKeys = Object.keys(item);
      const crop = cropKeys[0].split('_')[0];
      return { crop, ...item };
    }
    return {};
  });
};

export const getTotalRevenue14DaysChangeChartData = (
  data?: unknown[]
): {
  revenue: number[];
  netIncome: number[];
  tradeDates: string[];
} => {
  if (!data)
    return {
      revenue: [],
      netIncome: [],
      tradeDates: [],
    };
  const revenues = mapCropInto(data);
  const results = revenues?.reduce(
    (acc: any, r: any) => {
      const revenue = r[`${r.crop}_revenue_w_hedges`];
      const netIncome = r[`${r.crop}_hvar`];
      const tradeDates = r[`${r.crop}_trade_date`];

      tradeDates.forEach((date: string, index: number) => {
        acc.revenue[index] = acc.revenue[index]
          ? acc.revenue[index] + convertToFloat(revenue[index])
          : convertToFloat(revenue[index]);
        acc.netIncome[index] = acc.netIncome[index]
          ? acc.netIncome[index] + convertToFloat(netIncome[index])
          : convertToFloat(netIncome[index]);
        acc.tradeDates[index] = date;
      });
      return acc;
    },
    { revenue: [], netIncome: [], tradeDates: [] }
  );

  return results;
};

export const getFarmVAR14DaysChangeChartData = (
  data: unknown[]
): {
  hvar: number[];
  hnvar: number[];
  tradeDates: string[];
} => {
  if (!data)
    return {
      hvar: [],
      hnvar: [],
      tradeDates: [],
    };
  const revenues = mapCropInto(data);
  const results = revenues?.reduce(
    (acc: any, r: any) => {
      const hvar = r[`${r.crop}_hvar`];
      const hnvar = r[`${r.crop}_nhvar`];
      const tradeDates = r[`${r.crop}_trade_date`];

      tradeDates.forEach((date: string, index: number) => {
        acc.hvar[index] = acc.hvar[index]
          ? acc.hvar[index] + convertToFloat(hvar[index])
          : convertToFloat(hvar[index]);

        acc.hnvar[index] = acc.hnvar[index]
          ? acc.hnvar[index] + convertToFloat(hnvar[index])
          : convertToFloat(hnvar[index]);
        acc.tradeDates[index] = date;
      });
      return acc;
    },
    { hvar: [], hnvar: [], tradeDates: [] }
  );

  return results;
};
