import React, { useEffect, useMemo, useState } from 'react';
import SliderInput from '../commons/inputs/SliderInput';
import { useUser } from '../../contexts/auth-context';
import { useWeatherContext } from '../../contexts/weather-context';

type TimeLabel = { time: string; index: number };

export default function TimelineSlider({
  value,
  cachedTime,
  cachedDate,
  onChange,
  min,
  max,
  timeSet,
}: {
  value: number;
  cachedTime: string;
  cachedDate: string;
  onChange: (value: string) => void;
  min: number;
  max: number;
  timeSet: string[];
}) {
  const user = useUser();
  const {
    selectedTime: time,
    setSelectedTime: setTime,
    selectedDate: date,
    setSelectedDate: setDate,
    selectedTab,
  } = useWeatherContext();

  // Create a map of time labels and their corresponding positions
  const timeLabels: TimeLabel[] = timeSet.map((time, index) => ({
    time, // Directly use the time string
    index,
  }));

// Function to evenly distribute labels with exactly 10 labels
const getDistributedLabels = (labels: TimeLabel[], totalLabels: number) => {
  if (labels.length <= totalLabels) return labels;

  const step = (labels.length - 1) / (totalLabels - 1); // Calculate exact step to get 10 labels
  const result = [];

  for (let i = 0; i < totalLabels; i++) {
    const index = Math.floor(i * step); // Calculate index for each label
    result.push(labels[index]);
  }

  return result;
};


  // Get the distributed labels
  const distributedLabels = getDistributedLabels(timeLabels, 10);


  // Ensure value is within bounds
  const [safeValue, setSafeValue] = useState(
    value ? value : timeSet.length <= 0 ? 0 : timeSet.length - 1
  );

  useEffect(() => {
    if (cachedTime) {
      const index = timeSet.indexOf(cachedTime);
      if (index !== -1) {
        setSafeValue(index);
        setTime(cachedTime);
        if (selectedTab === '24hrPrecipitation') {
          setDate(cachedDate);
        }
      }
      return;
    }
    setSafeValue(timeSet.length <= 0 ? 0 : timeSet.length - 1);
    setTime(timeSet[timeSet.length - 1]);
    // setDate(timeSet[timeSet.length - 1]);
  }, [timeSet]);

  const divisionConstant = useMemo(() => {
    return timeSet.length > 150 ? timeSet.length + 6 : timeSet.length;
  }, [timeSet]);

  return (
    <div className="w-full">
      <div className="relative w-full border-0 flex justify-between mb-1.5">
        {timeSet &&
          timeSet.length > 0 &&
          distributedLabels &&
          distributedLabels.length > 0 &&
          distributedLabels.map(({ time, index }) => (
            <span
              key={time}
              className="text-xs-regular overflow-visible text-base-000 uppercase flex flex-col items-center group-data-[theme=Heartland]/root:text-base-900"
              style={{
                marginLeft: 6,
                top: '-25px',
                position: 'absolute',
                left: `${(index / divisionConstant) * 100}%`,
                transform: 'translateX(-50%)',
              }}
            >
              {selectedTab === '24hrPrecipitation' ? (
                <p className="mb-1">
                  {new Date(time).toLocaleDateString([], {
                    month: 'short',
                    day: 'numeric',
                  })}
                </p>
              ) : time?.includes(':') ? (
                <p className="mb-1">
                  {parseInt(time.split(':')[0]) % 12 === 0
                    ? '12'
                    : parseInt(time.split(':')[0]) % 12}{' '}
                  {parseInt(time.split(':')[0]) < 12 ? 'AM' : 'PM'}
                </p>
              ) : (
                <p className="mb-1">{time}</p>
              )}
              <span className="w-[1px] h-1 bg-base-000 rounded-full"></span>
            </span>
          ))}
      </div>
      <div className='w-full border-0'>
      <SliderInput
        value={safeValue}
        onChange={(e) => {
          setSafeValue(parseInt(e.target.value));
          setTime(timeSet[parseInt(e.target.value)]);
          // if (selectedTab === '24hrPrecipitation') {
          //   setDate(timeSet[parseInt(e.target.value)]);
          // }
          onChange(e.target.value);
        }}
        min={min}
        max={max}
        className='w-full'
      />
      </div>
    </div>
  );
}
