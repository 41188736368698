import { useMutation } from "@tanstack/react-query";
import { sendEmailApi } from "./emailApi";


export const useSendEmail = () => {
    const mutation = useMutation({
      mutationKey: ['email', 'CREATE'],
      mutationFn: (payload: any) => sendEmailApi(payload),
    });
  
    return mutation;
  };
