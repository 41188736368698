import { useEffect, useRef, useState } from 'react';
import AllFuturesTable from '../../../components/dashboard/market/AllFuturesTable';
import BasisChart from '../../../components/dashboard/market/BasisChart';
import MarketAlertsSidebar from '../../../components/dashboard/market/MarketAlertsSidebar';
import OptionsTable from '../../../components/dashboard/market/OptionsTable';
import PriceChart from '../../../components/dashboard/market/PriceChart';
import SpreadsTable from '../../../components/dashboard/market/SpreadsTable';
import TabSelector from '../../../components/dashboard/market/TabSelector';
import WatchList from '../../../components/dashboard/market/WatchList';
import { productTypes, tabTables } from '../../../constants';
import { MarketContext } from '../../../contexts/market-context';
import { useWebsocketContext } from '../../../contexts/websocket-context';

function Market() {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const pricesCropRef = useRef<string>('Corn');
  const pricesYearRef = useRef<string>('2024');
  const pricesDateOptions = useRef<{ label: string; value: string }[]>([]);
  const pricesMonthRef = useRef<string>('december');
  const pricesSymbolRef = useRef<string>('ZCZ4');
  const basisYearRef = useRef<string>('2024');
  const basisMonthRef = useRef<string>('december');
  const basisCropRef = useRef<string>('Corn');
  const basisSymbolRef = useRef<string>('ZCZ4');
  const basisCompanyRef = useRef<string>('Agtegra');
  const basisLocationRef = useRef<string>('Mclaughlin');
  const optionsMonthRef = useRef<string>('december');
  const optionsYearRef = useRef<string>('2024');
  const optionsCropRef = useRef<string>('Corn');
  const optionsSymbolRef = useRef<string>('ZCZ4');
  const spreadsMonthRef = useRef<string>('december');
  const spreadsCropRef = useRef<string>('Corn');
  const spreadsSymbolRef = useRef<string>('ZCZ4');
  const [trigger, setTrigger] = useState<number>(0);
  const [isUpdatingEditAlert, setIsUpdatingEditAlert] = useState(false);
  const [updatingAlertIndex, setUpdatingAlertIndex] = useState(-1);

  const { setTables, setProductTypes } = useWebsocketContext();

  useEffect(() => {
    setTables(tabTables[currentTab as keyof typeof tabTables]);
    setProductTypes([productTypes[currentTab]]);
  }, [currentTab]);

  return (
    <MarketContext.Provider
      value={{
        isUpdatingEditAlert,
        setIsUpdatingEditAlert,
        updatingAlertIndex,
        setUpdatingAlertIndex,
        trigger,
        setTrigger,
        pricesDateOptions,
        basisLocationRef,
        basisCompanyRef,
        basisSymbolRef,
        currentTab,
        setCurrentTab,
        pricesSymbolRef,
        pricesCropRef,
        pricesMonthRef,
        pricesYearRef,
        basisCropRef,
        basisMonthRef,
        basisYearRef,
        optionsCropRef,
        optionsMonthRef,
        optionsYearRef,
        optionsSymbolRef,
        spreadsCropRef,
        spreadsMonthRef,
        spreadsSymbolRef,
      }}
    >
      <div className="space-y-4 min-h-screen">
        <WatchList />

        {/* Container for Sidebar and Main Content */}
        <div className="sm:grid sm:grid-cols-12 gap-8">
          {/* Sidebar */}
          <div className="col-span-4">
            <MarketAlertsSidebar />
          </div>

          {/* Main Content */}
          <div className="col-span-8">
            <div className="flex mt-4 justify-center items-center mb-4">
              <TabSelector />
            </div>
            <div className="space-y-6">
              {currentTab === 0 && (
                <>
                  <div>
                    <PriceChart />
                  </div>
                  <div>
                    <AllFuturesTable />
                  </div>
                </>
              )}

              {currentTab === 1 && (
                <div>
                  <OptionsTable />
                </div>
              )}

              {currentTab === 2 && (
                <div>
                  <SpreadsTable />
                </div>
              )}

              {currentTab === 3 && (
                <div>
                  <BasisChart />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MarketContext.Provider>
  );
}

export default Market;
