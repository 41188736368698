import { TextractExpenseData } from '../../../types';
import { convertFormattedCurrency } from '../../../utils';

export const ExpenseTextractKeys = {
  purchaseDate: ['INVOICE_RECEIPT_DATE', 'ORDER_DATE'],
  vendor: 'VENDOR_NAME',
  vendorAddress: 'VENDOR_ADDRESS',
  expenseAmount: ['AMOUNT_DUE', 'TOTAL', 'AMOUNT_PAID'],
};

type Option = {
  value: any;
  label: string;
};

function removeDuplicatedOption(options: Option[]) {
  return options?.filter(
    (item, index, self) =>
      self.findIndex((refItem) => refItem.value === item.value) === index
  );
}

export function transformTextractExpenseToOptions(
  textractData: TextractExpenseData
) {
  const { summary, items } = textractData;

  const textractDataOptions = summary
    ?.sort((item1, item2) => item2.Confidence - item1.Confidence)
    .reduce((obj: Record<string, Option[]>, item) => {
      // transform to option for selection
      const option = { value: item.Value, label: item.Value };
      if (!obj?.[item.Key]) {
        obj[item.Key] = [option];
      }
      obj[item.Key].push(option);
      return obj;
    }, {});

  const categoryOptions = items?.map(
    (
      item // @TODO might need update this
    ) =>
      removeDuplicatedOption(
        item
          ?.sort(
            (subItem1, subItem2) => subItem1.Confidence - subItem2.Confidence
          )
          ?.map((subItem) => ({
            value: subItem.Key,
            label: subItem.Key,
            amount: subItem.Value,
          }))
      )
  );

  // get needed textract data options
  const newOptions = {
    vendor: removeDuplicatedOption(
      textractDataOptions[ExpenseTextractKeys.vendor]
    ),
    vendorAddress: removeDuplicatedOption(
      textractDataOptions[ExpenseTextractKeys.vendorAddress]
    ),
    category: categoryOptions,
  };

  // Check for expense amount keys
  let expenseAmountOptions: Option[] = [];
  const expenseAmountKeys = Object.keys(textractDataOptions).filter((key) =>
    ExpenseTextractKeys.expenseAmount.includes(key)
  );
  // Get expense amount value
  // use the first matched
  if (expenseAmountKeys.length >= 1) {
    expenseAmountKeys.forEach((key) => {
      expenseAmountOptions.push(...textractDataOptions[key]);
    });
  } else {
    // check to use other numerics
    const otherNumericKeys = Object.keys(textractDataOptions).filter(
      (key) => !ExpenseTextractKeys.expenseAmount.includes(key)
    );
    let otherNumerics: Option[] = [];
    otherNumericKeys.forEach((key) => {
      const result = textractDataOptions?.[key]?.filter((item) => {
        return item.value !== '' && !isNaN(item.value);
      });
      otherNumerics = [...otherNumerics, ...result];
    });
  }

  // Check for purchase date
  let purchaseDateOptions: Option[] = [];
  const purchaseDateKeys = Object.keys(textractDataOptions).filter((key) =>
    ExpenseTextractKeys.purchaseDate.includes(key)
  );
  purchaseDateKeys.forEach((key) => {
    purchaseDateOptions.push(...textractDataOptions[key]);
  });

  return {
    ...newOptions,
    expenseAmount: removeDuplicatedOption(expenseAmountOptions),

    purchaseDate: removeDuplicatedOption(purchaseDateOptions),
  };
}

export const getExpenseAmountOptions = (
  textractDataOptions: Record<string, Option[]>,
  index: number
) => {
  const commonExpenseAmounts = textractDataOptions?.expenseAmount ?? [];
  const amountFromCategory =
    // @ts-ignore
    textractDataOptions?.category?.[index]?.[0]?.amount;
  return [
    {
      value: convertFormattedCurrency(amountFromCategory),
      label: convertFormattedCurrency(amountFromCategory),
    },
    ...commonExpenseAmounts.map((item) => ({
      value: convertFormattedCurrency(item.value),
      label: convertFormattedCurrency(item.value),
    })),
  ];
};
