import { TextractInsuranceItem } from '../../../types';

export function removeDuplicatedItem(array: any[]) {
  return array?.filter((item, index, self) => self.indexOf(item) === index);
}

export function cleanUpItem(array: any[]) {
  return removeDuplicatedItem(removeNullItem(array));
}

export function removeNullItem(array: any[]) {
  const newArray = array?.filter((item) => {
    return item?.trim() !== '';
  });
  return newArray;
}

// crop insurance
function expandArray(array: any[], newLength: number) {
  const oldLength = array?.length;
  if (oldLength >= newLength) {
    return array;
  }

  array.length = newLength;
  array.fill([], oldLength);
  return array;
}
export function transformTextractInsuranceToOptions(
  textractData: Record<keyof TextractInsuranceItem, any>[]
) {
  // Merge array of textract
  const mergedObj: Record<string, any> = {};
  textractData?.forEach((obj: Record<string, any>) => {
    Object.keys(obj).forEach((key) => {
      if (mergedObj[key]) {
        mergedObj[key] = [...mergedObj[key], ...obj[key]];
      } else {
        mergedObj[key] = obj[key];
      }
    });
  });

  const numberOfLegals = mergedObj?.field_legal?.length || 1;

  const options = {
    field_legal: expandArray(mergedObj?.field_legal ?? [], numberOfLegals)?.map(
      (item: any[]) =>
        item.map((subItem: any) => ({
          value: subItem,
          label: subItem,
        }))
    ),

    // crops: expandArray(
    //   mergedObj?.crops,
    //   numberOfLegals
    // )
    //   ?.slice(0, numberOfLegals)
    //   .map((item: any[]) =>
    //     cleanUpItem(item).map((subItem: any) => ({
    //       value: subItem,
    //       label: subItem,
    //     }))
    //   ),

    crop_classes: expandArray(mergedObj?.crop_classes, numberOfLegals)
      ?.slice(0, numberOfLegals)
      .map((item: any[]) =>
        cleanUpItem(item).map((subItem: any) => ({
          value: subItem,
          label: subItem,
        }))
      ),

    // insurance_options: expandArray(
    //   mergedObj?.insurance_plans,
    //   numberOfLegals
    // )
    //   ?.slice(0, numberOfLegals)
    //   .map((item: any[]) =>
    //     cleanUpItem(item).map((subItem: any) => ({
    //       value: subItem,
    //       label: subItem,
    //     }))
    //   ),

    // practices: expandArray(
    //   mergedObj?.practices,
    //   numberOfLegals
    // )
    //   ?.slice(0, numberOfLegals)
    //   .map((item: any[]) =>
    //     cleanUpItem(item).map((subItem: any) => ({
    //       value: subItem,
    //       label: subItem,
    //     }))
    //   ),

    // endorsements: expandArray(
    //   mergedObj?.endorsements,
    //   numberOfLegals
    // )
    //   ?.slice(0, numberOfLegals)
    //   .map((item: any[]) =>
    //     cleanUpItem(item).map((subItem: any) => ({
    //       value: subItem,
    //       label: subItem,
    //     }))
    //   ),

    approved_yield: Array(numberOfLegals).fill(
      mergedObj?.approved_yields.map((item: any) => ({
        value: item,
        label: item,
      }))
    ),

    premium: Array(numberOfLegals).fill(
      removeDuplicatedItem(mergedObj?.other_values)
        .sort((a: number, b: number) => a - b)
        .map((item: any) => ({
          value: item,
          label: item,
        }))
    ),

    // irrigation_practice: Array(numberOfLegals).fill(
    //   InsuranceIrrigationPracticeOptions
    // ),
    // organic_practice: Array(numberOfLegals).fill(
    //   InsuranceOrganicPracticeOptions
    // ),
    // insurance_plan_type: Array(numberOfLegals).fill(
    //   InsurancePlanTypeOptions
    // ),
  };
  return options;
}
