import {
  ArrowsPointingOutIcon,
  CubeTransparentIcon,
  EyeIcon,
  EyeSlashIcon,
  PaintBrushIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { theme } from 'twin.macro';
import CircleIcon from '../../../assets/icons/CircleIcon';
import PolygonIcon from '../../../assets/icons/PolygonIcon';
import RectangleIcon from '../../../assets/icons/RectangleIcon';
import SquareIcon from '../../../assets/icons/SquareIcon';
import ZoomInIcon from '../../../assets/icons/ZoomInIcon';
import ZoomOutIcon from '../../../assets/icons/ZoomOutIcon';
import { NETWORK_PARTNER } from '../../../constants';
import { useUser } from '../../../contexts/auth-context';
import { useHistoricalInsights } from '../../../hooks/historical-insights/use-historical-insights';

const fillColors = (networkPartner: string | undefined) => {
  let inactive = theme`colors.base.000`;
  let active = theme`colors.yellow`;

  switch (networkPartner) {
    case NETWORK_PARTNER.heartland:
      inactive = theme`colors.base.800`;
      break;
  }

  return {
    inactive,
    active,
  };
};

function MapToolbar() {
  const {
    viewState,
    setViewState,
    selectedDrawMode,
    setSelectedDrawMode,
    myFeatureCollection,
    setMyFeatureCollection,
    selectedFeatureIndexes,
    setSelectedFeatureIndexes,
    setModalMode,
  } = useHistoricalInsights();
  const user = useUser();

  const mapActions1 = [
    {
      title: 'Move',
      icon: (props: any) => <ArrowsPointingOutIcon {...props} />,
      handler: () => {
        setModalMode('');
        setSelectedDrawMode(0);
      },
      mode: 0,
    },
    {
      title: 'Zoom In',
      icon: (props: any) => <ZoomInIcon {...props} />,
      handler: () => {
        setViewState({ ...viewState, zoom: viewState.zoom + 1 });
      },
    },
    {
      title: 'Zoom Out',
      icon: (props: any) => <ZoomOutIcon {...props} />,
      handler: () => {
        setViewState({ ...viewState, zoom: viewState.zoom - 1 });
      },
    },
    {
      title: 'Draw Polygon',
      icon: (props: any) => <PolygonIcon {...props} />,
      handler: () => {
        setModalMode('drawingModeSelected');
        setSelectedDrawMode(1);
      },
      mode: 1,
    },
    {
      title: 'Draw Square',
      icon: (props: any) => <SquareIcon {...props} />,
      handler: () => {
        setModalMode('drawingModeSelected');
        setSelectedDrawMode(2);
      },
      mode: 2,
    },
    {
      title: 'Draw Rectangle',
      icon: (props: any) => <RectangleIcon {...props} />,
      handler: () => {
        setModalMode('drawingModeSelected');
        setSelectedDrawMode(3);
      },
      mode: 3,
    },
    {
      title: 'Draw Circle',
      icon: (props: any) => <CircleIcon {...props} />,
      handler: () => {
        setModalMode('drawingModeSelected');
        setSelectedDrawMode(4);
      },
      mode: 4,
    },
    {
      title: 'Drag & Draw Polygon-String',
      icon: (props: any) => <PaintBrushIcon {...props} />,
      handler: () => {
        setModalMode('drawingModeSelected');
        setSelectedDrawMode(5);
      },
      mode: 5,
    },
  ];

  const mapAction2 = [
    {
      title: 'Edit Polygons',
      icon: (props: any) => <PencilSquareIcon {...props} />,
      handler: () => {
        setModalMode('editPolygons');
        setSelectedDrawMode(6);
      },
      mode: 6,
    },
    {
      title: 'Transform Polygons',
      icon: (props: any) => <CubeTransparentIcon {...props} />,
      handler: () => {
        setModalMode('transformPolygons');
        setSelectedDrawMode(7);
      },
      mode: 7,
    },
    {
      title: 'Unselect All',
      icon: (props: any) => <EyeSlashIcon {...props} />,
      handler: () => {
        setSelectedDrawMode(0);
        setModalMode('');
        setSelectedFeatureIndexes([]);
      },
    },
    {
      title: 'Select All',
      icon: (props: any) => <EyeIcon {...props} />,
      handler: () => {
        let indexes = Array.from(
          Array(myFeatureCollection.features.length).keys()
        );
        setSelectedFeatureIndexes(indexes);
      },
    },
    {
      title: 'Delete Selection',
      icon: (props: any) => <TrashIcon {...props} />,
      handler: () => {
        let newFeatures = myFeatureCollection.features.filter(
          (feature, index) => {
            return !selectedFeatureIndexes.includes(index);
          }
        );
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: newFeatures,
        });
        setSelectedDrawMode(0);
        setModalMode('');
        setSelectedFeatureIndexes([]);
      },
    },
  ];

  return (
    <div className="w-full sm-h-full">
      <div className="h-[120px] mb-4 h-full w-full flex flex-col gap-4 items-center justify-center">
        <div className="flex flex-wrap sm:flex-nowrap gap-4 h-full items-center justify-between w-full card px-6 py-2">
          {mapActions1.map((action) => (
            <div
              key={action.title}
              onClick={() => action.handler()}
              className="flex cursor-pointer gap-2 items-center"
            >
              {action.icon({
                fill:
                  selectedDrawMode === action.mode
                    ? theme`colors.yellow`
                    : fillColors?.(user?.network_partner)?.inactive,
                className: 'size-6',
              })}
              <p
                className={clsx(
                  selectedDrawMode === action.mode
                    ? 'text-yellow'
                    : 'text.base.800'
                )}
              >
                {action.title}
              </p>
            </div>
          ))}
        </div>
        <div className="flex flex-wrap gap-4 w-full items-center justify-between card px-6 py-2">
          {mapAction2.map((action) => (
            <div
              key={action.title}
              onClick={() => action.handler()}
              className="flex cursor-pointer gap-2 items-center"
            >
              {action.icon({
                fill:
                  selectedDrawMode === action.mode
                    ? theme`colors.yellow`
                    : fillColors?.(user?.network_partner)?.inactive,
                className: 'size-6',
              })}
              <p
                className={clsx(
                  selectedDrawMode === action.mode
                    ? 'text-yellow'
                    : 'text.base.800'
                )}
              >
                {action.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MapToolbar;
