import AccessControl from '../../components/routes/AccessControl';
import { PERMISSIONS } from '../../constants';
import Prescription from '../../pages/dashboard/prescription';

const prescriptionsRoutes = [
  {
    path: 'prescriptions',
    element: (
      <AccessControl permissions={[PERMISSIONS.PRESCRIPTIONS_ACCESS]} />
    ),
    children: [
      {
        index: true,
        element: <Prescription />,
      },
    ],
  },
];

export default prescriptionsRoutes;
