import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { NETWORK_PARTNER } from '../../../../../constants';
import { useSetIncomeViewFilter } from '../../../../../contexts/app-filter-context';
import { useUser } from '../../../../../contexts/auth-context';
import SwitchInput from '../../../../commons/inputs/SwitchInput';

interface IncomeViewSwitchProps {
  onChange?: (enabled: boolean) => void;
}

function IncomeViewSwitch(props: IncomeViewSwitchProps) {
  const user = useUser();
  const setIncomeView = useSetIncomeViewFilter();
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    setIncomeView?.(enabled ? 'revenue' : 'net-income');
  }, [enabled, setIncomeView]);

  

  return (
    <div className="flex justify-between items-center space-x-3 whitespace-nowrap">
      <p
        className={clsx(
          !enabled
            ? 'text-base-300 group-data-[theme=Heartland]/root:text-base-500'
            : 'text-base-900 group-data-[theme=Heartland]/root:text-base-500'
        )}
      >
        Net income
      </p>
      <SwitchInput
        size="lg"
        enabled={enabled}
        onChange={setEnabled}
        defaultColor={
          user?.network_partner === 'Heartland'
            ? enabled
              ? 'bg-base-900'
              : 'bg-tertiary'
            : enabled
              ? 'bg-secondary'
              : 'bg-tertiary'
        }
      />
      <p
        className={clsx(
          enabled
            ? 'text-base-300 group-data-[theme=Heartland]/root:text-base-500'
            : 'text-base-900 group-data-[theme=Heartland]/root:text-base-500'
        )}
      >
        Revenue
      </p>
    </div>
  );
}

export default IncomeViewSwitch;
