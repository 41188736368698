import { mapTooltipStyles } from '../../../constants';
import { useHistoricalInsights } from '../../../hooks/historical-insights/use-historical-insights';

export const getTooltip = (info: any, relationship: string, tableData: any[]) => {
  if (info.index === -1) return null;
  const fieldArray = new Uint8Array(info.layer.props.data.field?.[info.index]);
  const field = new TextDecoder().decode(fieldArray);
  // let zone = ''
  // if (relationship === 'agrivar_zone') {
  //   const zoneArray = new Uint8Array(info.layer.props.data.value?.[info.index]);
  //   zone = new TextDecoder().decode(zoneArray);
  //   console.log('computing agriar zone', zone, zoneArray)
  // }
  // relationship === 'agrivar_zone' ? zone : x
  const cropArray = new Uint8Array(info.layer.props.data.crop?.[info.index]);
  const crop = new TextDecoder().decode(cropArray);
  let x = parseFloat(info.layer.props.data.value?.[info.index]).toFixed(2);
  let char_fields = ['variety', 'agrivar_zone', 'field_name', 'plant_date', 'harvest_date', 'previous_crop'];

  if(char_fields.includes(relationship)) {
      x = tableData?.filter((data: any) => {
          console.log('x comparing data', data.x_index, x)
          return parseFloat(data.x_index) === parseFloat(x)
      })[0]?.x_bin;
      console.log('x val', x);
  }
  const wYield = parseFloat(info.layer.props.data.wYield?.[info.index]).toFixed(
    2
  );
  const fieldPerformance = parseFloat(
    info.layer.props.data.fieldPerformance?.[info.index]
  ).toFixed(2);
  const type = info.layer.props.data.type;
  
  return {
    html: `
      <div>
      <p>
      <b>Field:</b> ${field}
    </p>
        <p>
          <b>${relationship.slice(0, 1).toUpperCase()}${relationship.slice(1, relationship.length)}:</b> ${x} 
        </p>
        <p>
          <b>Yield:</b> ${wYield}
        </p>
        <p>
          <b>Yield Performance:</b> ${fieldPerformance}
        </p>
      </div>
    `,
    style: mapTooltipStyles,
  };
};
