import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ListboxSelectedOption,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { theme } from 'twin.macro';
import { TriangleDownIcon } from '../../assets/icons';
import Skeleton from './Skeleton';

interface Options {
  value: string;
  label: string;
  icon?: React.ReactElement;
}
interface SelectProps {
  options: Options[];
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  size?: 'sm' | 'md' | 'lg';
  name: string;
  defaultValue?: string;
  className?: string;
  required?: boolean;
  key?: string;
  horizontal?: boolean;
  placeholder?: string;
  disabled?: boolean;
  hiddenLabel?: boolean;
  fieldClassName?: string;
  textClassName?: string;
  iconColor?: string;
  autoSelect?: boolean;
  endAdornment?: any;
  multiple?: boolean;
}

function Select(props: SelectProps) {
  const {
    options,
    value,
    className,
    onChange,
    defaultValue: defaultValueProp,
    label,
    size = 'md',
    name,
    required,
    horizontal,
    placeholder = 'Select an option',
    disabled,
    hiddenLabel = false,
    fieldClassName,
    textClassName,
    iconColor,
    autoSelect = true,
    endAdornment,
    multiple,
  } = props;

  const padding =
    size === 'sm' ? 'py-1.5 pl-4' : size === 'md' ? 'py-2 pl-4' : 'py-3';
  const fontStyle =
    size === 'sm'
      ? 'text-xs-bold'
      : size === 'md'
        ? 'text-md-regular'
        : 'text-lg';

  const form = useFormContext();
  const field = form?.register(name, {
    required: {
      value: props.required || false,
      message: `${label} is required`,
    },
  });
  const error = form?.formState.errors[name];

  const defaultValue = useMemo(() => {
    const defaultValue =
      form?.getValues(name) ??
      form?.formState.defaultValues?.[name] ??
      defaultValueProp;
    if (defaultValue) {
      if (options.find((option) => option.value === defaultValue)) {
        return defaultValue;
      }
    }
    if (autoSelect) {
      return options?.[0]?.value;
    }
  }, [autoSelect, defaultValueProp]);

  useEffect(() => {
    handleChange(defaultValue);
  }, [defaultValue]);

  // const [selectedOption, setSelectedOption] = useState<Options>({
  //   value: '',
  //   label: '',
  // });

  // useEffect(() => {
  //   if (value !== undefined || defaultValueProp !== undefined) {
  //     const selected = options?.find(
  //       (option) => option.value === value || option.value === defaultValueProp
  //     );
  //     if (selected) setSelectedOption(selected);
  //   }
  // }, [value, defaultValueProp]);

  const [selectedValues, setSelectedValues] = useState<string[]>(
    defaultValue ? [defaultValue] : []
  );

  const handleChangeMultiple = (values: string[]) => {
    setSelectedValues(values);

    if (onChange) {
      const event = {
        target: { value: values.join(','), name },
      } as React.ChangeEvent<HTMLSelectElement>;
      onChange(event);
    }

    if (field) {
      field.onChange({
        target: { value: values.join(','), name },
      });
    }
  };

  const handleChange = (value: string) => {
    if (onChange) {
      const event = {
        target: { value: value, name },
      } as React.ChangeEvent<HTMLSelectElement>;
      onChange(event);
    }

    if (field) {
      field.onChange({
        target: { value: value, name },
      });
    }
  };

  return (
    <Field className={clsx('space-y-2 w-full', fieldClassName)}>
      <div
        className={clsx(
          horizontal ? 'flex items-center space-x-2' : 'space-y-1'
        )}
      >
        {label && (
          <Label className={clsx({ 'opacity-0': hiddenLabel })}>
            {label} {required && <span className="text-secondary">*</span>}
          </Label>
        )}
        <Listbox
          // value={selectedOption?.value}
          onChange={multiple ? handleChangeMultiple : handleChange}
          {...(!multiple && { defaultValue: defaultValue })}
          {...(multiple && { value: selectedValues })}
          multiple={multiple}
        >
          <div className="relative">
            <ListboxButton
              disabled={disabled || options?.length === 0}
              className={clsx(
                'relative w-full bg-base-1000 border border-base-1000 pl-4 pr-10 text-left rounded',
                'min-w-[150px] select inputStyle',
                padding,
                fontStyle,
                className,
                options?.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'
              )}
              style={{
                minWidth: '100px',
                boxShadow:
                  '0px 1px 2px 0px #1018280D,2px 2px 4px 0px #00000040 inset',
              }}
            >
              <ListboxSelectedOption
                placeholder={
                  !options.length ? 'No options available' : placeholder
                }
                options={options?.map((option, index) => (
                  <ListboxOption
                    key={`${option.value}-${index}`}
                    value={option.value}
                    className={() =>
                      clsx(
                        'selectOption cursor-default select-none relative pr-4 flex items-center',
                        'last:border-0'
                      )
                    }
                  >
                    <>
                      <span
                        className={clsx(
                          'bg-transparent truncate flex items-center justify-between'
                        )}
                      >
                        {option.icon && (
                          <span className="inline-flex items-center mr-2 min-w-9">
                            {option.icon}
                          </span>
                        )}
                        <span className={clsx(textClassName)}>
                          {option.label}
                        </span>
                      </span>
                    </>
                  </ListboxOption>
                ))}
              />
              {/* <span className="flex items-center justify-between w-full overflow-x-hidden whitespace-nowrap">
                {options?.length <= 0 && (
                  <p className="opacity-50">No options available</p>
                )}
                {selectedOption?.icon && (
                  <span className="inline-flex items-center mr-2 min-w-9">
                    {selectedOption.icon}
                  </span>
                )}
                {selectedOption?.label ? (
                  <p className={`${textClassName}`}>{selectedOption.label}</p>
                ) : (
                  <p className="opacity-0">{placeholder}</p>
                )}
              </span> */}
              <span className="pointer-events-none absolute inset-y-0 right-4 flex items-center">
                <TriangleDownIcon fill={iconColor ?? theme`colors.base.500`} />
                {endAdornment && (
                  <span className="absolute right-6 top-1/2 -translate-y-1/2">
                    {endAdornment}
                  </span>
                )}
              </span>
            </ListboxButton>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions
                anchor={{ to: 'bottom start', gap: 4 }}
                className={clsx(
                  'selectOptions absolute max-h-60 w-[var(--button-width)]',
                  'overflow-auto bg-block-fill border border-base-1000 shadow-lg z-10',
                  'custom-scrollbar rounded p-2 shadow-lg'
                )}
              >
                {options?.map((option, index) => (
                  <ListboxOption
                    key={`${option.value}-${index}`}
                    value={option.value}
                    className={({ focus, selected }) =>
                      clsx(
                        focus ? 'bg-base-900' : '',
                        selected && 'bg-base-1000',
                        'selectOption cursor-default select-none relative py-2 pl-3 pr-4 flex items-center border-b border-base-1000',
                        'last:border-0',
                        { focus: focus },
                        { selected: selected }
                      )
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={clsx(
                            'truncate bg-transparent flex items-center justify-between'
                          )}
                        >
                          {option.icon && (
                            <span className="inline-flex items-center mr-2 min-w-9">
                              {option.icon}
                            </span>
                          )}
                          {option.label}
                        </span>
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </Listbox>
      </div>
      {error && <p className="text-red">{`${error?.message}`}</p>}
    </Field>
  );
}

export default Select;

interface AwaitSelectProps extends SelectProps {
  isLoading: boolean;
}
export const AwaitSelect = ({ isLoading, ...rest }: AwaitSelectProps) => {
  return (
    <Field className="space-y-2">
      {isLoading && rest?.label && <Label>{rest.label}</Label>}
      {isLoading ? <Skeleton className="!h-[31px]" /> : <Select {...rest} />}
    </Field>
  );
};
