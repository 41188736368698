import AccessControl from '../../components/routes/AccessControl';
import { PERMISSIONS } from '../../constants';
import Market from '../../pages/dashboard/market';

const marketsRoutes = [
  {
    element: (
      <AccessControl permissions={[PERMISSIONS.MARKETS_ACCESS]} />
    ),
    path: 'markets',
    children: [
      {
        index: true,
        element: <Market />,
      },
    ],
  },
];

export default marketsRoutes;
